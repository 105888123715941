.primary-button {
    // background-color: black !important;
    border-radius: 5px !important;
    // border-color: black !important;
    // height: 35px !important;
    // width: 300px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 0.88em !important;
    line-height: 24px !important;
    letter-spacing: 0.4px !important;
    text-transform: uppercase !important;
    min-width: fit-content;
}
.big-button {
    height: 50px !important;
    color: black !important;
    background-color: white !important;
}
.auth-buttons {
    width: 300px !important;
    height: 35px !important;
    font-size: 14px !important;
}
.signup-button {
    background-color: #a080e1 !important;
    border: none !important;
}
.resend {
    color: #a080e1;
    cursor: pointer;
}
.verify-button {
    width: 150px !important;
    height: 50px !important;
    border: none !important;
}
.tertiary-button {
    background-color: #e0e0e0 !important;
    border-radius: 5px !important;
    border-color: #e0e0e0 !important;
    height: 35px;
    width: 300px;
    text-shadow: none !important;
    color: black !important;
}

.secondary-button {
    background-color: transparent !important;
    border-radius: 5px !important;
    border-color: #bfbfc1 !important;
    color: black !important;
    line-height: 24px !important;
    height: fit-content !important;
    // padding: 12px 0px !important;
    text-shadow: none !important;
}

.action-button {
    background-color: #75facf !important;
    border-width: 0 !important;
    border-radius: 5px !important;
    color: black !important;
    height: 35px;
    width: 300px;
    text-shadow: none !important;
}

.primary-button-small {
    // background-color: black !important;
    margin-top: 10px !important;
    border-radius: 5px !important;
    height: 50% !important;
    width: fit-content !important;
    padding: 6px 30px !important;
}
.primary-button-small-altern {
    // background-color: black !important;

    border-radius: 5px !important;
    height: 50px !important;
    width: fit-content !important;
    padding: 6px 40px !important;
}
.primary-button-small:disabled {
    color: grey !important;
}
.primary-button-icon {
    flex: 1;
    max-width: 100%;
    min-width: 100%;
    min-height: 50px;
    height: 100%;
}
.secondary-button-icon {
    flex: 1;
    max-width: 70%;
    min-width: 70%;
    min-height: 40px;
    margin-left: 15% !important;
    margin-bottom: 15px !important;
    margin-top: 5px !important;
    // height: 100%;
}

.swich-button-container {
    align-self: flex-end !important;
    // float: right !important;
    // min-width: 100px !important;
    // margin-left: 20px !important;
    // width: 90% !important;
    // height: 102% !important;
}

.selected {
    // background-color: black !important;
    color: white !important;
}
.unselected {
    background-color: white !important;
    color: rgba(20, 20, 20, 0.38);
}

.switch-button {
    font-size: larger;
    font-weight: 500 !important;
    max-width: 45px !important;
    max-height: 50px !important;
    min-height: 50px !important;
    flex: auto;
    float: right !important;
    cursor: pointer;
    padding: 9px 0px !important;
    text-align: center !important;
}

.left {
    border-top-left-radius: 13px !important;
    border-bottom-left-radius: 13px !important;
}
.right {
    border-top-right-radius: 13px !important;
    border-bottom-right-radius: 13px !important;
}
// .switch-button-left:hover {
//   transform: scale(1.05) !important;
// }
// .switch-button-right:hover {
//   transform: scale(1.05) !important;
// }

.menu-item-list {
    margin-top: 0;
    margin-bottom: 0;
}

.delete-text {
    color: red;
}
.rounded-button {
    // background-color: black !important;
    color: white !important;
    border-radius: 100% !important;
    height: 35px !important;
    width: 35px !important;
}

.not-found-button {
    justify-content: center !important;
    align-items: center !important;
    /* Graphite */
    // background-color: #141414 !important;
    border-radius: 80px !important;
    color: #f5f9fc !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 17px !important;
    line-height: 25px !important;
    height: 7vh !important;
    width: 20vh !important;
}
