.navbar {
  // z-index: 5000000 !important;
  position: sticky !important;
  width: 100% !important;
  background-color: white !important;
  box-shadow: 0 2px 2px 0 rgba(20, 20, 20, 0.14),
    0 3px 1px -2px rgba(20, 20, 20, 0.12), 0 1px 5px 0 rgba(20, 20, 20, 0.2);
}

.navbar-styles {
  .main-block {
    position: absolute;
    background-color: white;
    width: 95%;
    height: 100%;
    float: right;
  }

  .blocks {
    position: relative;
    right: 0;
    height: 100%;
    float: right;
    align-items: center;
    vertical-align: middle;
    display: flex;
  }

  .profile-block {
    text-align: center;
    justify-content: left;
    width: 30vh;
    margin-right: 20px;
  }
  .small-block {
    background-color: white;
    width: 8vh;
    text-align: right;
    justify-content: right;
  }
  @media only screen and (max-width: 400px) {
    .small-block {
      background-color: white;
      width: 7vh;
      text-align: right;
      justify-content: right;
    }
  }

  .buttons {
    cursor: pointer;
    border: 2px solid rgba(0, 0, 0, 0.04);
    box-sizing: border-box;
    border-radius: 160px;
    align-items: center;
    vertical-align: middle;
    display: flex;
  }

  .small-rounded-button {
    width: 48px;
    height: 48px;
    text-align: center;
    justify-content: center;
    .ant-badge-dot {
      background-color: #da8be3 !important;
    }
  }

  .profile-rounded-button {
    width: fit-content;
    max-width: 200px;
    height: 48px;
    text-align: left;
    justify-content: left;
    padding-left: 3px;
    align-self: center;
  }
  .profile-name {
    padding-left: 5px;
    padding-right: 5px;
    font-style: normal;
    font-weight: bolder;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height, or 150% */
    color: #141414;
    letter-spacing: 0.1px;
  }

  .lists {
    z-index: 1;
    background: #ffffff;
    /* Elevation / 8 */
    box-shadow: 0px 3px 24px rgba(0, 0, 0, 0.18);
    border-radius: 4px;
    position: absolute;
    margin-right: 20;
    margin-top: 58px;
  }

  .profile-info-list {
    right: 45px;
    width: 200px;
    // height: 20vh;
  }
  .feedback-info-list {
    right: 250px;
    width: 195px;
    // height: 14vh;
  }

  .profile-options {
    border-radius: 4px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 40px;
    font-family: "IBMPlex-Reg";
    /* identical to box height, or 150% */

    font-weight: normal;
    letter-spacing: 0.15px;

    /* text / primary | 87p */

    color: #141414;
    .ant-menu-item-selected,
    .ant-menu-item {
      background-color: white;
      color: black;
    }
    .ant-menu-item:hover {
      color: black;
      background-color: #e5e5e5;
    }
    li[role="menuitem"] {
      color: black;
      background-color: white;
    }
    .ant-menu:not(.ant-menu-horizontal) {
      background-color: white;
      color: black;
    }
    .ant-menu-item-selected {
      background-color: white !important;
    }
    .ant-menu-item-selected:hover {
      background-color: #e5e5e5 !important;
    }
  }
}
