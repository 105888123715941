.events-container {
  display: flex;
  flex-direction: column;
  .events-date-picker {
    .ant-drawer-content-wrapper {
      height: unset !important;
      height: 60% !important;
    }
  }
}
