.empty-event-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;
  text-align: center;
  flex-direction: column;
  .message {
    font-size: 18px !important;
    font-weight: 600 !important;
    color: #A4A4A4 !important;
    align-self: center;
    margin-top: 56px;
  }
}