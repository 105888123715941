.notification-detail {
  padding: 29px 19px;
  padding-bottom: unset;
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  .notification-detail-header {
    display: flex;
    text-align: center !important;
    align-items: center;
    width: 100%;
    .close-notification-modal {
      flex-basis: 5%;
      .anticon {
        color: #141414 !important;
        opacity: 1;
      }
    }
    .notification-detail-title {
      flex-basis: 95%;
      display: flex;
      justify-content: center;

      .ant-typography {
        text-align: center !important;
        font-style: normal !important;
        font-weight: normal !important;
        font-size: 16px !important;
        line-height: 24px !important;
        color: #141414 !important;
        margin-bottom: unset !important;
        margin-right: auto;
        flex-grow: 1;
      }
    }
  }

  .client-notification-detail-content {
    display: flex;
    flex-direction: column;
    margin-top: 37px;
    width:90%;
    .notification-breakdown {
      margin-bottom: 25px;
      display: flex;
      flex-direction: column;
      .aligned-centered {
        display: flex;
        align-items: center;
      }
      .text-title {
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 14px !important;
        line-height: 18px !important;
        color: #141414 !important;
        margin-bottom: 5px !important;
      }
      .with-icon {
        margin-left: 16px;
      }
      .text-description {
        font-style: normal !important;
        font-weight: normal !important;
        font-size: 14px !important;
        line-height: 20px !important;
        letter-spacing: 0.15px !important;
        color: #5c5c5c !important;
        margin-bottom: unset !important;
      }
      .notification-map {
        margin-top: 15px;
      }
      .location-address {
        width: 70%;
      }
      .location-marker {
        margin-left: auto;
      }
    }
    .event-button {
      margin-top: 70px;
      .primary-button {
        height: unset !important;
        width: 100% !important;
        padding-top: 12px;
        padding-bottom: 12px;
        font-style: normal !important;
        font-weight: normal !important;
        font-size: 14px !important;
        line-height: 31px !important;
        letter-spacing: 1px !important;
        text-transform: uppercase !important;
      }
    }
  }

  .bottom-divider {
    margin-bottom: 8px !important;
    border: 5px solid #141414;
    margin-top: auto;
    width: 50%;
    min-width: 50% !important;
    justify-content: center;
    display: flex;
    border-radius: 100px;
  }
  .add-report-notification {
    padding: 58px 20px;
    padding-bottom: unset;
    display: flex;
    flex-direction: column;
    align-items: center;
    .report-title {
      margin-top: 30px;
      font-style: normal !important;
      font-weight: 600 !important;
      font-size: 18px !important;
      line-height: 23px !important;
      text-align: center !important;
      color: #141414 !important;
      margin-bottom: 15px !important;
    }
    .report-text {
      font-style: normal !important;
      font-weight: normal !important;
      font-size: 16px !important;
      line-height: 28px !important;
      text-align: justify !important;
      letter-spacing: 0.15px !important;
      color: #141414 !important;
    }
    .feedback-button {
      margin-top: 40px !important;
      .primary-button {
        height: unset !important;
        padding: 57px;
        padding-top: 12px;
        padding-bottom: 12px;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 14px !important;
        line-height: 31px !important;
        letter-spacing: 1px !important;
      }
    }
    .report-cancel {
      margin-top: 37px;
      .cancel-text {
        text-transform: uppercase;
        font-style: normal !important;
        font-weight: normal !important;
        font-size: 14px !important;
        line-height: 31px !important;
        letter-spacing: 1px !important;
        color: #141414 !important;
      }
    }
  }
}
