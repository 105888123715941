.organism-container {
  background-color: white !important;
  height: 100% !important;

  .ant-divider-horizontal {
    margin: 0 !important;
  }
}

.activity-title {
  width: 100%;
  text-align: start !important;
  padding: 2% 24px 0 24px;
}
