.select-item {
  margin-bottom: unset !important;
  width: 100% !important;
  height: 100% !important;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  .ant-select-selector {
    height: 100% !important;
    min-height: 50px !important;
    border: none !important;
  }
  .ant-select-selection-item {
    display: flex;
    flex-direction: row;
    justify-content: flex;
    align-items: center;
  }
  .ant-select-selection-placeholder {
    padding-top: 8px !important;
  }
}
.select-text {
  font-size: 16px !important;
}
.ant-select-dropdown {
  z-index: 1500 !important;
}
.select-input {
  .float-label {
    height: 60px;
    background-color: #fff !important;
    position: relative;
    margin-bottom: -12px;
  }
  .label-float {
    position: absolute;
    top: -10px !important;
    left: 1% !important;
    font-size: 10px !important;
    opacity: 1 !important;
    padding-right: 2px !important;
    padding-left: 2px !important;
    background-color: white !important;
    z-index: 1000;
  }
}
