.flag-reject-modal {
  .ant-modal-body {
    padding: 8px 24px;
    font-size: 16px;
  }

  .float-label {
    //height: 60px !important;
  }

  .reject-reason-input, .reject-reason-input:focus {
    border: none;
    box-shadow: none;
    border-bottom: 1px black solid;
    padding-left: 0 !important;
  }
}
