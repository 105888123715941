$primaryColor: #4cceb8;
$disabledColor: #9d9d9e;

.expanded-tabs .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  .ant-tabs-tab,
  .ant-tabs-tab-active {
    flex: 1;
    flex-direction: row;
    justify-content: center;
  }
}

.tab-ops-hidden {
  .ant-tabs-nav-operations {
    display: none !important;
    visibility: hidden !important;
  }
}

.ant-tabs-tab-btn {
  padding: 0 15px;
  color: inherit !important;
  text-transform: uppercase;
}

.ant-tabs-tab-active,
.ant-tabs-tab:hover {
  color: $primaryColor !important;
}

.ant-tabs-tab {
  color: $disabledColor;
  margin: 0 !important;
  padding: 6px 0 !important;
  font-family: IBMPlex-Bold;
  font-size: 12px !important;
}

.ant-tabs-ink-bar {
  background: $primaryColor !important;
}

.ant-tabs-nav-wrap {
  box-shadow: inset 0 -1px 0 #dddde0;
}

.ant-tabs-top {
  flex: 1;
}

.ant-tabs-content {
  height: 100%;
}
