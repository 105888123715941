.toast {
    min-width: 20%;
    background-color: #fff;
    display: flex;
    height: 40px;
    padding: 10px;
    font-size: 14px;

    &-text {
        margin: 0 10px;
        font-weight: 590;
        width: 100%;
    }
}

.toast-success {
    color: #1E4720;
}

.close-text{
    margin-left: 20px;
    font-size: 13px;
    cursor: pointer;
}