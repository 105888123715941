.option-items {
  margin-left: 16px;
  margin-top: 4px;
  margin-bottom: 4px;
  cursor: pointer;
}

.option-menu {
  font-family: "IBMPlex-Reg";
  font-weight: 400;
}

.popover-items {
  max-height: 500px;
  overflow: scroll;
  scrollbar-width: none;
}

.option-menu:hover {
  background: rgba(0, 0, 0, 0.04);
  font-family: "IBMPlex-Reg";
  font-weight: 400;
  color: #000000;
}

.selected-container {
  padding-right: 20px;
  display: flex;
  flex-wrap: wrap;
  max-height: 95px;
  overflow: hidden;
}

.selected-items {
  display: flex;
  height: 24px;
  background: rgba(0, 0, 0, 0.07);
  border: 1px solid #5c5c5c;
  margin-right: 8px;
  padding-right: 8px;
  border-radius: 4px;
  margin-bottom: 8px;
  width: fit-content;
  overflow: hidden;
  transition: all 0.3s ease;
}

.selected-item-text {
  font-family: "IBMPlex-Reg";
  font-size: 12px;
  font-weight: 400;
}
.selected-items-wrapper {
  margin-left: 8px;
}

.select-when-empty-filler {
  display: flex;
}
.select-when-empty-filler-items {
  margin-right: 5px;
  cursor: pointer;
}

.popup-avatar {
  font-family: "IBMPlex-Reg";
  font-weight: 400;
  font-size: 16px;
}

.department-list-table {
  thead > tr > th:first-child {
    border-radius: 10px 0 0 10px !important;
    border-top: 1px solid rgba(185, 185, 185, 0.3);
    border-left: 1px solid rgba(185, 185, 185, 0.3);
    border-bottom: 1px solid rgba(185, 185, 185, 0.3);
  }
  thead > tr > th:last-child {
    border-bottom: 1px solid rgba(185, 185, 185, 0.3);
    border-top: 1px solid rgba(185, 185, 185, 0.3);
    border-right: 1px solid rgba(185, 185, 185, 0.3);
    border-radius: 0 10px 10px 0 !important;
  }
  thead > tr > th {
    border-bottom: 1px solid rgba(185, 185, 185, 0.3);
    border-top: 1px solid rgba(185, 185, 185, 0.3);
    font-style: bold !important;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #5c5c5c !important;
  }
  tbody > tr {
    cursor: pointer !important;
  }
}
.department-list-searchbar {
  min-height: 40px !important;
  height: 40px;
  color: rgba(20, 20, 20, 0.54);
}

.icon-style {
  font-size: 18px !important;
}

@media only screen and (max-width: 1200px) {
  .department-container {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    .department-top-menu-container {
      flex-direction: column;
      .department-top-right-container {
        display: flex;
        align-items: center;
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
      .department-left-top-container {
        flex-direction: column;
        .department-left-top-items {
          padding-bottom: 20px !important;
          width: 100%;
        }
      }
    }
  }
}

.department-container {
  background-color: #fff;
}

.department-table-container {
  margin-left: 30px;
  margin-right: 30px;
  @media only screen {
    overflow: auto;
    scroll-behavior: auto;
    scrollbar-width: 20px;
  }
}

.department-top-menu-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 38px;
}

.department-left-top-container {
  display: flex;
  align-items: center;
  padding-top: 38px;
  padding-bottom: 32px;
}

.department-left-top-items {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 24px;
  .add-department-btn {
    width: 100%;
    background: #141414;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.08),
      0px 6px 10px rgba(0, 0, 0, 0.05), 0px 1px 18px rgba(0, 0, 0, 0.03);
    border-radius: 4px;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    height: 40px !important;
    line-height: 0 !important;
    span {
      font-style: normal;
      font-weight: 600;
      font-size: 0.95em;
      line-height: 24px;
      letter-spacing: 0.4px;
      text-transform: uppercase;
      color: #ffffff;
      flex: none;
      order: 1;
      flex-grow: 0;
      margin: 8px 0px;
      padding-right: 26px;
      padding-left: 26px;
    }
  }
}

.department-top-right-container {
  padding-top: 42px;
  display: flex;
  align-items: center;
  padding-left: 30px;
}
