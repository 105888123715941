.client-survey-card {
  background: #e6f0ef;
  margin: 18px 0;
  border-radius: 7px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  width:100%;
}

.client-survey-title {
  font-family: "IBMPlex-Reg";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #141414;
}

.client-survey-card-title {
  text-align: left;
  text-align: left;
  float: left;
  margin-bottom: 30px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #141414;
  font-family: "IBMPlex-Reg";
  font-weight: 600;
}

.client-survey-card-left-text {
  float: left;
  font-family: "IBMPlex-Reg";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #141414;
}

.client-survey-card-right-text {
  float: right;
  font-family: "IBMPlex-Reg";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #141414;
}
