.sping-page {
  // background-color: #04fbcb;
  color: white;
  height: 100vh;
  text-align: center;
  display: flex;
  justify-content: center;
  position: sticky;
  align-items: center;
  opacity: 1;
  margin: auto;
}
.icon {
  margin-top: 6px;
  height: 25px !important;
}

.sping-page-skeleton {
  // background-color: #04fbcb;
  color: white;
  height: 100vh;
  text-align: center;
  display: flex;
  justify-content: top;
  position: sticky;
  align-items: baseline;
  opacity: 1;
  margin: auto;
}
.icon {
  margin-top: 6px;
  height: 25px !important;
}
.icon-sidebar {
  // max-width: fit-content !important;
  // max-height: fit-content !important;
  // width: 18px !important;
  // // margin-top: 20px !important;
  // // height: 14px !important;
}
.icon-user {
  max-width: fit-content !important;
  max-height: fit-content !important;
  width: 17px !important;
  margin-right: 3px !important;
  // margin-top: 20px !important;
  // height: 14px !important;
}
.icon-flag {
  top: 2px !important;
}
