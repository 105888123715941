.user-list-switch {
  display: flex;
  flex-direction: row;
}
.select-space {
  margin-bottom: 22px !important;
}
.user-form {
  div[role="alert"] {
    width: fit-content !important;
    margin-top: 0px !important;
    margin-left: 25%;
    z-index: 3;
    position: relative;
    text-align: start;
    margin-bottom: 10px !important;
  }
}
