.flag-details-organism-container {
  background-color: white;
  padding: 24px 16px;
  border-radius: 8px;

  .info-container {
    margin-bottom: 16px;

    .flag-details-icon-text {
      display: flex;
      flex-direction: row;
      align-items: center;
      > * {
        margin-right: 8px;
      }
    }
  }

  .flag-details-image-container {
    display: flex;
    flex-direction: column;

    .ant-image {
      height: 100%;
    }

    .flag-details-main-image {
      flex: 1;
      object-fit: cover;
      border-radius: 16px;
      height: 100%;
    }
  }

  .flag-details-info-item {
    font-family: IBMPlex-Reg;
    font-style: normal;
    font-size: 16px;
    display: flex;
    align-items: flex-start;
    padding: 8px 0;

    > b {
      width: 160px;
      text-transform: capitalize;
    }
  }

  .details-info-item-row {
    flex-direction: row;
  }

  .details-info-item-column {
    flex-direction: column;
    > *:not(:last-child) {
      margin-bottom: 4px;
    }

    .classified {
      color: #00b999;
    }
  }
}
