.view-events-container {
  display: flex;
  padding-left: 19px;
  padding-right: 31px;
  flex-direction: column;
  .about-container {
    display: flex;
    flex-direction: column;
    .desc {
      font-size: 14px !important;
      font-weight: 600 !important;
      color: #5C5C5C !important;
      text-transform: capitalize;
      display: flex;
      flex-wrap: wrap;
    }
  }
  .topic-container {
    display: flex;
    margin-top: 25px;
    flex-direction: column;
    .topic-desc{
      display: flex;
      align-items: center;
    }
  }
  .date-container{
    display: flex;
    margin-top: 25px;
    flex-direction: column;
    .date-desc{
      display: flex;
      align-items: center;
    }
  }
  .time-container{
    display: flex;
    margin-top: 25px;
    flex-direction: column;
    
    .time-desc{
      display: flex;
      align-items: center;
    }
  }
  .icon {
    display: flex;
    align-items: center;
    font-size: 13.33px !important;
    color: rgba(20, 20, 20, 0.54) !important;
    margin: 0 15px 0 0 !important;
  }
  .title {
    font-size: 14px !important;
    font-weight: 600 !important;
    color: #141414 !important;
    text-transform: capitalize;
  }
  .text {
    font-size: 14px !important;
    font-weight: 400 !important;
    color: #5C5C5C !important;
    margin-bottom: 0 !important;
  }
  .location-container {
    display: flex;
    margin-top: 25px;
    flex-direction: column;
    .location-desc{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
    .notification-map {
      margin-top: 17px;
      height: 90px;
    }
    .location-marker {
      margin-left: auto;
    }
    .primary-button {
      margin: 70px 16px 50px 17px;
      height: 50px;
      span {
        font-size: 12px;
        font-weight: 400;
      }
    }
  }
  .success-modal {
    width: 270px !important;
    height: 314px;
    .ant-modal-content{
      .ant-modal-footer {
        display: none !important;
      }
      .ant-modal-body {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        text-align: center;
        .ant-image {
          height: 76px;
          width: 76px;
          margin-bottom: 30px;
        }
        .title-message {
          margin-bottom: 15px !important;
          font-weight: 600 !important;
          font-size: 18px !important;
          color: #141414 !important;
        }
        .message {
          font-weight: normal !important;
          font-size: 16px !important;
          color: #141414 !important;
          margin-bottom: 35px !important;
          align-self: center;
        }
      }
    }
  }
}


.modal-container {
  display: inline-block;
  width: 100%;
  height: 100%;
}

.modal-container .ant-modal-mask,
.modal-container .ant-modal-wrap {
  position: absolute;
}
