.ant-progress-text{
    display: none;
}

.ant-progress-success-bg, .ant-progress-bg
{
    background-image: none  !important;
    background: #E5FFFA  !important;
}

.ant-progress-success-bg{
    background-color: #F38B00  !important;
}

.ant-progress-inner {
    border-radius: 0 !important;
}

.ant-progress-inner div{
    height: 10px  !important;
    border-radius: 0 !important;
}
