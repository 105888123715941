.login-form {
  width: fit-content !important;
  height: fit-content !important;
  position: relative;
  padding: 0 50px 0 50px;
  background-color: #ffffff !important;
  -webkit-box-shadow: 4px 5px 15px -4px rgba(0, 0, 0, 0.51);
  box-shadow: 4px 5px 15px -4px rgba(0, 0, 0, 0.51);
  border-radius: 4px;
}
.auth-container {
  // position: absolute !important
  min-width: 100%;
  min-height: 100%;
  height: 100%;
  overflow: hidden;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  padding: 0;
  background-image: url("/assets/img/login_launch.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: 100% 100%;
  background-position: center; /* Center the image */
}
.empty-component {
  margin-top: 50px !important;
  max-width: 500px !important;
  height: fit-content !important;
  position: relative;
  padding: 0 20px 0 20px;
  background-color: #ffffff !important;
  -webkit-box-shadow: 0px 2px 23px rgba(233, 233, 233, 0.4);
  box-shadow: 0px 2px 23px rgba(233, 233, 233, 0.4);
  border-radius: 4px;
}
.body-form {
  width: 100% !important;
  height: fit-content !important;
  position: relative;
  padding: 20px 50px 0 50px;
  background-color: #ffffff !important;
  -webkit-box-shadow: 0px 2px 23px rgba(233, 233, 233, 0.4);
  box-shadow: 0px 2px 23px rgba(233, 233, 233, 0.4);
  border-radius: 4px;
}

.widgets {
  .reports {
    .main-container {
      margin-top: 40px !important;
      align-items: center !important;
      width: 90% !important;
      padding-bottom: 8% !important;
      margin-left: 5% !important;
      background-color: #f87e48;
    }
    .main-container-no-color {
      margin-top: 40px !important;
      // align-items: center !important;
      width: 90% !important;
      padding-bottom: 8% !important;
      margin-left: 5% !important;
      background-color: white;
      // height: 52vh !important;

      .content {
        margin-left: 40vh !important;
        margin-top: 20px !important;
        width: 70%;
      }
      .full-content {
        // margin-left: 40vh !important;
        margin-top: 20px !important;
        width: 100%;
      }
    }
    .main-container-full {
      align-items: center !important;
      width: 100% !important;
      height: 100% !important;
      // padding-bottom: 8% !important;
      // margin-left: 5% !important;
      // background-color: #f87e48;
    }
    .title {
      text-align: center !important;
    }
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .login-form {
    top: 60% !important;
    background-color: transparent !important;
    border-style: none !important;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px rgba(0, 0, 0, 0),
      0px 0px 0px rgba(0, 0, 0, 0);
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) and (max-width: 900px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
