.badge-container {
  display: flex;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #303030;
  margin-bottom: 10px;

  .badge-image {
    width: 40px;
    height: 40px;
    border-radius: 100%;
  }

  .badge-image-box {
    margin-right: 15px;
  }
}

.badge-details {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: rgba(20, 20, 20, 0.54);
  .name {
    color: #141414;
    font-weight: bold;
    font-size: 16px !important;
  }
  .email {
    color: rgba(20, 20, 20, 0.54);
  }
}
