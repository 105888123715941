$lightText: #A6A6A6;
$lightText: #5C5C5C;

.no-flags-placeholder-container {
  font-family: IBMPlex-Bold;
  font-size: 16px;
  color: $lightText;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px 0;
  cursor: pointer;
  user-select: none;
  background: #FFFFFF;
  box-shadow: 0px 2px 23px rgba(233, 233, 233, 0.4);
  border-radius: 4px;

  .placeholder-image {
    margin-bottom: 15%;
    width: 30%;
  }

  .create-flag-btn {
    width: auto;
    padding: 0 24px;
    text-transform: uppercase;
  }

  .no-flag-desc {
    text-align: center;
    padding: 0 64px;
    margin-bottom: 32px;
    color: $lightText;
    font-family: IBMPlex-Reg;
  }
}
