.swal2-container .swal2-popup.swal2-toast {
  flex-direction: column;
  align-items: start;
  position: relative;
  width: 1000px;
  // max-width: fit-content !important;
}

.swal2-container .swal2-popup.swal2-toast .swal2-title,
.swal2-container .swal2-popup.swal2-toast .swal2-content {
  text-align: left;
  // max-width: fit-content !important;
  width: 100%;
}

.alert-min {
  max-width: max-content !important;
}
.top-index {
  z-index: 50000000 !important;
}
.not-found-title {
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 50px;
  padding-top: 4vh;
  /* identical to box height, or 111% */

  text-align: center;

  /* Graphite */

  color: #141414;
}

.not-found-button-container {
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;
  text-align: center !important;
}

.not-found-button {
  justify-content: center !important;
  align-items: center !important;
  /* Graphite */
  // background-color: #141414 !important;
  border-radius: 80px !important;
  color: #f5f9fc !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 17px !important;
  line-height: 25px !important;
  height: 7vh !important;
  width: 20vh !important;
}

.not-found-image {
  height: 50vh !important;
  width: 60vw !important;
}

@media only screen and (max-width: 500px) {
  .not-found-image {
    height: 80vw !important;
    width: 85vw !important;
  }
}
