.submission-user-info-form-root {
  padding: 24px 16px;
  background: #fff;
  box-shadow: 0px 2px 23px rgba(233, 233, 233, 0.4);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  .header-text {
    font-style: normal;
    font-weight: 600 !important;
    font-size: 20px !important;
    line-height: 32px !important;
    display: flex !important;
    align-items: center !important;
    letter-spacing: 0.15px !important;
    color: #141414 !important;
    text-transform: capitalize;
  }
  .header-input{
    margin-bottom: 18px;
    .ant-input {
      padding: 4px 8px !important;
    }
  }
}
