.response-notification-wrapper {
  display: flex;
  flex-direction: column;
  padding: 0 30px;
  .response-notification-text-container {
    display: flex;
    flex-direction: column;
  }
  .respondent-notification-text-title {
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    letter-spacing: 0.15px !important;
    color: #141414 !important;
  }
  .respondent-notification-text-desc {
    font-style: normal;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 24px !important;
    letter-spacing: 0.15px !important;
    color: #140808 !important;
  }
  .input-box {
      display:flex;
      flex-direction:column;
    margin-bottom: 24px;
    .ant-input{
        padding:16px 12px;
        border-radius: 4px;
    }
  }

  .response-notification-row {
    .ant-divider {
      margin: 24px 0 !important;
      border: 1px solid #e0e0e0;
    }
  }
  .respondent-toggle-icon {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .ant-switch-checked {
      background-color: #00b999;
    }
  }
  .notification-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    
  }
}
