.project-modal {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 380px;
    .ant-drawer-content-wrapper {
        height: fit-content !important;
        padding: 0;
    }
    .ant-drawer-content {
        background-color: transparent;
    }
    .ant-drawer-top.ant-drawer-open .ant-drawer-content-wrapper {
        box-shadow: none;
    }
    .ant-input-affix-wrapper {
        border-radius: 10px !important;
    }
    .modal-view {
        height: 100%;
        background-color: white;
        display: flex;
        flex-direction: column;
        margin-top: -18px;
        margin-left: 16px;
        margin-right: 16px;
        padding-top: 30px;
        padding-bottom: 40px;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
    }
    .search-container {
        margin-left: 16px;
        margin-right: 16px;
        margin-top: 16px;
        border-radius: 4px;
    }
}
.onClose {
    top: 0;
}
@media screen and (max-width: 992px) {
    .project-modal {
        width: 380px;
    }
}
@media screen and (max-width: 767px) {
    .project-modal {
        width: 100%;
    }
}
@media screen and (max-width: 600px) {
    .project-modal {
        width: 100vw;
    }
}
