.post-editor-header {
  margin-bottom: 35px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .editor-text {
    flex: 1;
    form {
      flex: 1;
      .title-edit {
        width: 100%;
        padding: 5px 10px;
        border: none;
        border-bottom: 1px solid #141414;
        outline: none;
      }
    }
  }
}
.editor-text {
  display: flex;
  align-items: center;
}
.editor-text > h3 {
  margin: 0 !important;
}
.edit-button {
  margin-left: 10px;
  cursor: pointer;
}
.editor-select-container {
  display: flex;
  align-items: center;
}
.editor-select-container > span {
  margin-bottom: 0 !important;
}
.editor-text {
  display: flex;
}
.dropdown-selected {
  margin-left: 10px;
  margin-bottom: 0;
  cursor: pointer;
}
