.template-container {
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.08), 0px 6px 10px rgba(0, 0, 0, 0.05),
    0px 1px 18px rgba(0, 0, 0, 0.03);
  height: 320px;
  width: 100%;
  .survey-icon-container {
    height: 223px;
    border-bottom: 1px solid #e0e0e0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    cursor: pointer;
  }

  .ant-card-body {
    padding: 0;
  }
  .survey-card-text {
    display: flex;
    align-items: center;
    height: 97px;
    padding: 0 23px;
    border-radius: 4px;
    justify-content: space-between;
    .ant-typography {
      font-weight: bold !important;
      color: #141414 !important;
      margin-bottom: 0 !important;
    }
  }
}
.ant-card {
  border-radius: 4px;
}
.menu-icon {
  background: #f5f5f5;
  border-radius: 4px;
  cursor: pointer;
  font-size: 20px;
}
