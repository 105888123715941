.event-card {
  display: flex;
  min-height: 108px;
  margin: 25px 25px 0 25px;
  border-radius: 10px;
  cursor: pointer;
  overflow: hidden;
  .events-card-details-conatiner{
    display: flex;
    padding: 0 0 0 15px;
    flex-direction: column;
    width: 100%;
    .detailer{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .details-container{
        display: flex;
        flex-direction: column;
        width: 100%;
        padding-top: 15px;
        .meeting-type {
          font-size: 16px !important;
          font-weight: 500 !important;
          color: #141414 !important; 
        }
        .topic{
          font-size: 13px !important;
          font-weight: normal !important;
          color: #5C5C5C !important; 
        }
      }
      .vectors {
        display: flex;
        justify-content: flex-end;
        flex-direction: row;
        width: 50%;
        padding: 0 !important;
        .ant-image {
          .ant-image-img {
            display: flex;
            width: 100%;
            opacity: 0.5
          }
        }
      }

    }
    
    .timings-container {
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      padding-right: 15px;
      .timing-text {
        margin-bottom: 0 !important;
        font-size: 12px !important;
        font-weight: 500 !important;
        color: #141414 !important;
      }
    }
  }
  .ant-image {
    position: absolute;
  }
}