$darkText: #141414;
$lightText: #808080;

.follower-item-container {
  display: flex;
  flex-direction: row;
  padding: 8px 32px;
  .name {
    margin: 0 !important;
    font-size: 14px !important;
    color: $darkText !important;
    font-family: IBMPlex-Bold !important;
  }
  .subtitle {
    margin: 0 !important;
    font-size: 12px !important;
    color: $lightText !important;
    font-family: IBMPlex-Bold !important;
  }
  .avatar {
    margin-right: 16px;
  }
  .follower-item-right {
    display: flex;
    flex-direction: column;
  }
}
