.popular-news-container {
    display: flex;
    flex-direction: row;
    overflow-y: hidden;
    scrollbar-width: none;
    height: 196px;
    padding-top: 16px;
    margin-bottom: 16px;
}
.popular-news-container::-webkit-scrollbar {
    display: none;
  }