.report-container {
    width: 100%;
    height: 220px;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    .report-image {
        width: 100%;
        height: 153px;
    }
    .report-image-placeholder {
        height: 153px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        border-radius: 10px;
        object-fit: cover;
    }
    .report-info {
        width: 100%;
        height: 50px;
    }
    .report-row {
        display: flex;
        flex-direction: row;
        padding-top: 8px;
    }
    .report-row-title-text {
        width: 70%;
        color: #141414 !important;
        font-weight: 600 !important;
        text-align: left;
        margin-bottom: 0 !important;
    }
    .report-date-text {
        width: 30%;
        color: #14141455 !important;
        text-align: right !important;
        font-size: 12px !important;
        line-height: 14px !important;
        margin-bottom: 0 !important;
    }
    .address {
        width: 100%;
        color: #5C5C5C !important;
        text-align: left;
        font-size: 12px !important;
    }
}
