.option-container-launch {
  display: flex;
  flex: 0 0 8em;
  height: 122px;
  border-radius: 5px;
  flex-direction: column;
  justify-content: center;
  margin-right: 8px;
  .icon {
    padding-left: 8px;
    padding-top: 0;
  }
  .title {
    font-family: "IBMPlex-Reg" !important;
    padding-left: 8px;
    padding-top: 10px;
    font-size: 13px !important;
    line-height: 30px !important;
    color: #ffffff !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 24px;
  }
}
.Questionnaires {
  background-color: #003f4f;
}

.Resources {
  background-color: #067979;
}

.Communication {
  background-color: #0cafa0;
}

.About {
  background-color: #62d2c3;
  margin-right: 0 !important;
}
