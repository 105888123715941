.edit-profile-container {
  display: flex;
  flex-direction: column;
  overflow: scroll;

  .edit-profile-input {
    font-size: 16px !important;
    margin-bottom: 12px;
    .float-label {
      margin-bottom: unset !important;
      .ant-input {
        &:hover {
          border: 2px solid #141414 !important;
        }
        &:focus {
          border: 2px solid #141414 !important;
          border-right-width: 1px !important;
          outline: 0;
          -webkit-box-shadow: unset !important;
          box-shadow: unset !important;
        }
      }
      .ant-input-affix-wrapper {
        .ant-input {
          &:hover {
            border: unset !important;
          }
          &:focus {
            border: unset !important;
          }
        }
        &:hover {
          border: 2px solid #141414;
        }
        &:focus {
          border: 2px solid #141414;
          border-right-width: 1px !important;
          outline: 0;
          -webkit-box-shadow: unset !important;
          box-shadow: unset !important;
        }
      }
      .ant-input-affix-wrapper-focused {
        border: 2px solid #141414;
        border-right-width: 1px !important;
        outline: 0;
        -webkit-box-shadow: unset !important;
        box-shadow: unset !important;
      }
      .form-item {
        border-radius: 4px;
        height: 100% !important;
        padding: 14px;
      }
      .label-float {
        font-style: normal;
        font-weight: normal;
        font-size: 12px !important;
        line-height: 16px;
        display: flex;
        align-items: center;
        letter-spacing: 0.4px;
        color: rgba(20, 20, 20, 0.5);
        margin-left: 6px;
        margin-top: -5px;
      }
    }
    .ant-input {
      &::placeholder {
        font-style: normal;
        font-weight: normal !important;
        font-size: 16px !important;
        line-height: 28px !important;
        letter-spacing: 0.15px !important;
      }
    }
  }

  .ant-tabs-tab-btn {
    color: inherit !important;
    text-transform: uppercase;
    font-weight: 500 !important;
  }
  .ant-tabs-vertical.ant-tabs-card {
    .ant-tabs-nav-container {
      height: 100% !important;
    }
  }
  .ant-tabs-tab-active,
  .ant-tabs-tab:hover,
  .ant-tabs-nav {
    color: #141414 !important;
    border-bottom-color: #141414 !important;
  }

  .ant-tabs-ink-bar {
    background: #141414 !important;
  }
  .ant-tabs-ink-bar-animated {
    background: #141414 !important;
  }

  .ant-tabs-tab {
    margin: 0 !important;
    padding: 6px 0 !important;
    font-family: IBMPlex-Bold;
    font-size: 12px !important;
  }

  .layout-wrapper {
    padding-top: 10px;
    padding-left: 16px;
    padding-right: 16px;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 15px;
    overflow: scroll;
    height: 100%;
  }

  .profile-input {
    font-size: 16px !important;
    margin-bottom: 20px;
  }

  @mixin input-style() {
    font-size: 16px !important;
    margin-bottom: 12px;
    .float-label {
      margin-top: 15px;
      margin-bottom: unset !important;
      .ant-input,
      .ant-select {
        &:hover {
          border: 2px solid #141414 !important;
        }
        &:focus {
          border: 2px solid #141414 !important;
          border-right-width: 1px !important;
          outline: 0;
          -webkit-box-shadow: unset !important;
          box-shadow: unset !important;
        }
      }
      .ant-input-affix-wrapper {
        .ant-input {
          &:hover {
            border: unset !important;
          }
          &:focus {
            border: unset !important;
          }
        }
        &:hover {
          border: 2px solid #141414;
        }
        &:focus {
          border: 2px solid #141414;
          border-right-width: 1px !important;
          outline: 0;
          -webkit-box-shadow: unset !important;
          box-shadow: unset !important;
        }
      }
      .ant-input-affix-wrapper-focused {
        border: 2px solid #141414;
        border-right-width: 1px !important;
        outline: 0;
        -webkit-box-shadow: unset !important;
        box-shadow: unset !important;
      }


      .form-item,
      .ant-select {
        border-radius: 4px;
        height: 100% !important;
        align-items: center;
      }
      .label-float {
        font-style: normal;
        font-weight: normal;
        font-size: 12px !important;
        line-height: 16px;
        display: flex;
        align-items: center;
        letter-spacing: 0.4px;
        color: rgba(20, 20, 20, 0.5);
        margin-left: 6px;
      }
    }
    .ant-input,
    .ant-select {
      &::placeholder {
        font-style: normal;
        font-weight: normal !important;
        font-size: 16px !important;
        line-height: 28px !important;
        letter-spacing: 0.15px !important;
      }
    }
  }

  .client-select {
    @include input-style();
  }

  .section-subtitle {
    font-style: normal;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 24px !important;
    color: #141414 !important;
    cursor: pointer;
    margin-bottom: 0 !important;
    margin-top: 10px !important;
  }
  .section-title {
    font-style: normal;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 24px !important;
    color: #141414 !important;
    cursor: pointer;
    margin-bottom: 0 !important;
  }
  .profile-image-container {
    align-self: center;
    justify-content: center;
    flex-direction: column;
    display: flex;
    align-items: center;
    padding-bottom: 20px;
  }
  .profile-image {
    border-radius: 20px;
    width: 100px;
  }
  .checkbox-container {
    padding: 10px;
  }
  .calender {
    padding-left: 30px;
  }
  .calendar-filter-wrapper {
    margin-top: 40px;
  }
  .back-icon-container {
    padding: 15px;
  }
  .back-icon {
    width: 100%;
    display: flex;
  }
  .client-select-margin {
    margin-top: -10px;
  }
}
