.open-toggle-root {
  border-radius: 50%;
  font-size: 16px;
  padding: 4px;
  border-width: 1px;
  border-style: solid;
  height: 28px;
  width: 28px;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
