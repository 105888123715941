.kanban-lane-container {
  > *:not(:last-child) {
    margin-bottom: 4%;
  }
}

.kanban-add-button {
  margin: 12px 0;
  cursor: pointer;
  color: #7D7D7E;
  text-transform: uppercase;
  word-spacing: 2px;
  font-size: 14px;
  font-family: IBMPlex-Reg;
  cursor: pointer;
  user-select: none;
}
