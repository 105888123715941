.client-sidebar {
  position: absolute;
  display: flex;
  flex-direction: column;
  height: 100%;
  .ant-drawer-close {
    right: unset !important;
  }
  .ant-drawer-body {
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: white;
    .bottom-divider {
      margin-bottom: 8px !important;
      border: 5px solid #141414;
      margin-top: auto;
      width: 50%;
      min-width: 50% !important;
      justify-content: center;
      display: flex;
      border-radius: 100px;
      margin-left: 15%;
    }
    padding: unset !important;
    .client-sidebar-wrapper {
      padding: 34px 27px !important;

      .client-sidebar-header {
        margin-top: 32px;
        display: flex;
        align-items: center;
        .sidebar-down-arrow {
          margin-left: auto;
        }
        .client-sidebar-avatar {
          border-radius: 10px;
        }
        .project-name {
          margin-left: 14px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          .select-title {
            font-style: normal !important;
            font-weight: 600 !important;
            font-size: 14px !important;
            line-height: 32px !important;
            text-transform: uppercase !important;
            color: #141414 !important;
            margin-bottom: unset !important;
          }

          .client-sidebar-header-title {
            font-style: normal;
            font-weight: bold !important;
            font-size: 20px !important;
            color: #141414 !important;
            margin-bottom: unset !important;
          }
          .client-sidebar-header-location {
            font-style: normal;
            font-weight: normal !important;
            font-size: 18px !important;
            line-height: 23px !important;
            color: #141414 !important;
            opacity: 0.5 !important;
            margin-bottom: unset !important;
          }
        }
      }
    }

    .client-sidebar-content {
      .sidebar-divider {
        margin-top: 6px !important;
      }
      display: flex;
      flex-direction: column;
      .segment {
        .info-icon {
          fill: rgba(20, 20, 20, 0.24);
        }
        display: flex;
        box-sizing: border-box;
        padding-right: 27px;
        padding-left: 27px;
        margin: 12px;
        align-items: center;
        .segment-text {
          margin-bottom: unset !important;
          font-style: normal !important;
          font-weight: normal !important;
          font-size: 16px !important;
          line-height: 21px !important;
          color: #141414 !important;
          margin-left: 19px;
        }
      }
    }
  }
}
