.ant-menu-custom-item {
  display: flex;
  align-items: center;
  z-index: 5;
  .anticon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.ant-menu-custom {
  z-index: 2;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.option-title {
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  letter-spacing: 0.15px;
}


