.nav-container {
  display: flex;
  padding-bottom: -90px;

  div {
    width: 50%;
  }

  .right-bar {
    text-align: right;

    span {
      cursor: pointer;
      display: inline-block;
    }
  }

  .left-bar {
    span {
      cursor: pointer;
      max-width: 20px !important;
    }

    span.outline {
      margin: 0px 10px;
    }
  }
  .mail-subject {
    font-weight: 500;
    font-size: 17px;
    line-height: 34px;
    color: #141414;
    font-weight: bold;
  }
}

.pagination-arrow-circle {
  display: inline-block;
  background-color: #fff;
  height: 20x;
  width: 20px;
  padding: 0px;
  border-radius: 100%;
  text-align: center;
  margin-left: 15px;
  font-size: 12px !important;
  cursor: pointer;
}

.divider {
  margin-top: 15px !important;
}
