.survey-list-item {
  cursor: pointer !important;
  background: #fff;
  padding: 16px;
  border-bottom: 1px solid #e0e0e0;
  .survey-card-dropdown {
    z-index: 30;
    width: 222px;
    position: absolute;
    box-shadow: 0px 3px 24px rgba(0, 0, 0, 0.18);
    border-radius: 4px;
    right: 1px;
    margin-top: 12px;
  }
}
.list-icon {
  width: 10px;
  background: #f5f5f5;
  border-radius: 4px;
  cursor: pointer;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.list-item-text {
  font-style: normal;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: #141414 !important;
}
.list-item-wrapper {
  text-align: center;
}
.list-item-title {
  font-style: normal;
  font-weight: bold !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: #141414 !important;
}
