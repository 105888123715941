.submission-details-container {
  .actions-container {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .back-action {
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;

      .caption {
        margin: 0 16px !important;
      }
    }

    .buttons-container {
      > * {
        margin-left: 8px;
      }
    }

    .action-buttons {
      width: 100px;
      text-transform: uppercase;
      font-size: 12px;
    }
  }

  .ant-divider-horizontal {
    margin: 16px 0;
  }
}
