.calendar-container {
  display: flex;
  flex-direction: column;
  .items-text {
    font-weight: normal !important;
    font-size: 16px !important;
    color: #141414 !important;
    padding-bottom: 29px;
    cursor: pointer;
    padding-left: 45px;
  }
  .header-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 26px;
  }
  .calendar-header-text {
    margin-bottom: 0 !important;
    padding-left: 20.44px;
    padding-right: 20.44px;
  }
}

.rdrDateRangePickerWrapper {
  display: flex;
  flex-direction: column;
  .rdrStaticRanges {
    display: none;
  }
  .rdrInputRanges {
    display: none;
  }
}
.rdrDateDisplayWrapper {
  display: none;
}
// .rdrMonthAndYearWrapper {
//   display: none !important;
// }
.rdrDays {
  .rdrDayToday {
    .rdrDayNumber {
      span:after {
        width: 8px;
      }
    }
  }
  .rdrDayPassive {
    span {
      display: none;
    }
  }
  
  .rdrInRange, .rdrStartEdge, .rdrEndEdge {
    color: #00b999 !important;
    opacity: 40%;
  }
  .rdrInRange + .rdrDayNumber {
    span {
      color: #1d2429 !important;
    }
  }
  
  .rdrStartEdge + .rdrDayNumber, .rdrEndEdge + .rdrDayNumber {
    border-radius: 50px;
    background-color: #00b999;
    width: 26px !important;
    height: 26px !important;
    margin-left: 8px;
  }

  .rdrStartEdge {
    left: 50%;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .rdrEndEdge {
    right: 50%;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.calendar-header {
  .anticon{
    cursor: pointer;
  }
}

