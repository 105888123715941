.get-assistance-button {
  background-color: #f38b00;
  padding: 0.7em;
  position: fixed;
  bottom: 75px;
  right: 39%;
  border-radius: 20px;
  box-shadow: 5px 10px 10px rgb(207, 207, 207);
  cursor: pointer;
}
.get-assistance-button text {
  margin-left: 0.5em;
  line-height: 1px;
  color: white;
}
.get-assistance-button svg {
  fill: white;
  padding-top: 0.1em;
}

@media only screen and (max-width: 1300px) {
  .get-assistance-button {
    right: 35%;
  }
}

@media only screen and (max-width: 600px) {
  .get-assistance-button {
    right: 8%;
  }
}
