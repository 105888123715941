.survey-responses-list-item-wrapper {
  padding: 16px 0;
  border-bottom: 1px solid #eaebf0;
  cursor: pointer;
  .ant-typography {
    font-style: normal;
    font-weight: normal !important;
    font-size: 14px !important;
    line-height: 20px !important;
    letter-spacing: 0.15px !important;
    color: #141414 !important;
  }
  .response-email-text {
    padding-left: 60px;
  }
  .text-center {
    text-align: center;
  }
  .survey-responses-icon {
    display: flex;
    justify-content: center;
    z-index: 5;
    .anticon {
      background: #f5f5f5;
      border-radius: 4px;
      padding: 5px;
      rotate: 90deg;
      height: 22px;
      display: flex;
      align-items: center;
    }
  }
}
@media only screen and (max-width: 720px) {
  .survey-responses-list-item-wrapper .response-email-text {
    padding-left: 10px;
  }
}
