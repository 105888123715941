.department-bottom-drawer {
  .ant-drawer-mask {
    .ant-drawer-content-wrapper {
      height: 50% !important;
    }
  }
}
.category-form {
  div[role="alert"] {
    width: fit-content !important;
    margin-top: 0px !important;
    margin-left: 25%;
    z-index: 3;
    position: relative;
    text-align: start;
    margin-bottom: 10px !important;
  }
}

.irggZc {
  height: 50px !important;
  width: 98% !important;
  padding-left: 10px !important;
}
