$lightText: #808080;

.flag-comments-organism-container {
  background-color: white;
  padding: 24px 22px;
  border-radius: 8px;

  .heading {
    font-family: IBMPlex-Bold;
    font-size: 24px;
  }

  .info-container {
    margin-bottom: 16px;
  }

  .flag-details-main-image {
    object-fit: cover;
    border-radius: 16px;
  }

  .flag-details-info-item {
    font-family: IBMPlex-Reg;
    font-style: normal;
    font-size: 16px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 8px 0;

    > b {
      width: 160px;
    }
  }

  > .comment-item {
    margin: 8px 0;
  }
}

.flag-comments-placeholder-root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: $lightText;
  padding-bottom: 18px;
}
