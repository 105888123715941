.previous-page-wrapper {
  .previous-page-navigator {
      display: flex;
      align-items: center;
    .anticon {
      margin-right: 12px;
      color: rgba(20, 20, 20, 0.54);
      cursor: pointer;
    }
    .ant-typography {
      font-style: normal !important;
      font-weight: 500 !important;
      font-size: 12px !important;
      line-height: 31px !important;
      letter-spacing: 0.5px !important;
      text-transform: uppercase !important;
      color: rgba(20, 20, 20, 0.54) !important;
      cursor: pointer;
      margin-bottom: unset !important;
    }
  }
  .ant-divider-horizontal {
    margin-top: 12px !important;
  }
}
