.view-event-topbar-container {
  display: flex;
  flex-direction: row;
  padding-left: 25px;
  align-items: center;
  padding-bottom: 23px;
  padding-top: 32px;
  justify-content: space-around;
  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .head {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    .view-event-text {
      margin-bottom: 0 !important;
    }
  }
}