.search-button {
  width: 90%;
  background: #141414;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.08), 0px 6px 10px rgba(0, 0, 0, 0.05),
    0px 1px 18px rgba(0, 0, 0, 0.03);
  border-radius: 4px;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: 40px !important;
  line-height: 0 !important;
  span {
    font-style: normal;
    font-weight: 600;
    font-size: 0.95em;
    line-height: 24px;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    color: #ffffff;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 8px 0px;
  }
}
.user-list-search-bar-container{
  display: flex !important;
  .filter-icon{
    width: 18px;
    margin-right: 27px;
  }
}
.user-list-header {
  margin-bottom: 20px;
}
.user-list-search-bar {
  min-height: 40px !important;
  height: 40px;
}
