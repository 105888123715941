.zipcode-table-container {
  height: 100%;
  > *:last-child {
    margin-top: 20px;
    // overflow: scroll;
  }

  .ant-table-thead {
    background-color: #eeeeee;
    th:first-child {
      border-top-left-radius: 8px !important;
      border-bottom-left-radius: 8px;
    }
    th:last-child {
      border-top-right-radius: 8px !important;
      border-bottom-right-radius: 8px;
    }

    .ant-table-cell {
      text-align: center;
      padding: 4px 16px;
      background-color: transparent !important;
      color: black;
    }
  }

  .ant-table-tbody {
    background-color: transparent !important;

    .ant-table-cell {
      padding: 12px 16px;
      background-color: transparent !important;
      text-align: center;
      font-size: 0.8em;
      font-weight: bold;
    }
  }
}
