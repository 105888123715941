.notification-item {
  display: flex;
  flex-direction: column;
  cursor: pointer;

  .notification-item-content {
    padding: 0px 20px !important;
    display: flex;
    flex-direction: column;
    .notification-title {
      display: flex;
      width: 100%;
      .ant-typography {
        margin-bottom: unset !important;
        margin-left: 13px;
        font-style: normal !important;
        font-weight: 500 !important;
        font-size: 14px !important;
        line-height: 16px !important;
        color: #676767 !important;
      }
      .notification-read {
        width: 16px;
        height: 16px;
        background: #f44336;
        border-radius: 50%;
        margin-left: auto;
        align-self: center;
      }
    }
    .notification-details {
      margin-top: 12px;
      .event-notification {
        display: flex;
        flex-direction: column;
        .event-subject {
          font-style: normal;
          font-weight: 600 !important;
          font-size: 14px !important;
          line-height: 16px !important;
          color: #141414 !important;
        }
        .event-details {
          font-style: normal;
          font-weight: normal !important;
          font-size: 14px !important;
          line-height: 16px !important;
          color: #141414 !important;
        }
      }
      .report-notification {
        .report-body {
          font-style: normal;
          font-weight: normal !important;
          font-size: 14px !important;
          line-height: 160% !important;
          color: #141414 !important;
        }
      }
    }
    .notification-time {
      display: flex;
      justify-content: flex-end;
      margin-top: 15px;
      .ant-typography {
        font-style: normal;
        font-weight: normal !important;
        font-size: 13px !important;
        line-height: 14px !important;
        display: flex !important;
        align-items: center !important;
        text-align: right !important;
        color: #676767 !important;
      }
    }
  }
  .notification-divider {
    margin-top: 10px !important;
  }
}
