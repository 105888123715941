.communication-container {
    width: 100%;
    height: 100px;


    .communication-info {
        width: calc(100% - 60px);
        height: 100%;
        padding-left: 15px
    }
    .communication-horizontal {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;

    }
    .communication-row {
        display: flex;
        flex-direction: row;

    }
    .communication-source-info {
        width: 90%;
        height: 100%;
        display: flex;
        flex-direction: row;
    }
    .communication-title {
        font-size: 14px !important;
        color: #141414 !important;
        font-weight: bold  !important;
          margin-top:22px;
    }
    .communication-date-text {
        color: #141414 !important;
        font-size: 12px !important;
        line-height: 16px !important;
        text-align: right;
        position: absolute;
        right: 8px;
        margin-top:5px;


    }
    .communication-text {
        color: #14141455 !important;
        font-size: 12px !important;
        line-height: 16px !important;


    }
    .communication-image {
        width: 45px;
        height: 45px;

    }
    .communication-actions {
        width: 100%;
        height: 1px;
        background-color:#E0E0E0;
    }
    .communication-image-placeholder {
        height: 45px;
        width: 45px;
        margin-left: auto;
        margin-right: auto;
        border-radius: 22px;
        object-fit: cover;
        margin-top:22px;
    }
}
