
.header-resources {
  height:261px;
  width:100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: center;
  background-position-y: center;
}

.left-all-child span {
  text-align: left !important;
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  width: 80%;
  margin: 14px auto;
}
.resource-content {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  letter-spacing: 0.15px;

  /* Primary / Iris Graphite */
  color: #141414;
}
.resource-title {
font-family: IBM Plex Sans;
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 26px;
display: flex;
letter-spacing: 0.15px;
color: #141414;
}
.resource-url {
font-family: IBM Plex Sans;
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;
display: flex;
letter-spacing: 0.15px;
color: #141414;
text-decoration: underline;
}
