.knowledge-card-title {
  text-align: left !important;
  margin: 0;
  font-style: normal;
  font-weight: bolder;
  font-size: 36px !important;
  line-height: 32px;
  // text-transform: capitalize;
  /* identical to box height, or 160% */

  letter-spacing: 0.15px;

  /* primary / main */

  color: #141414;
}
.knowledge-sub-heading {
  margin-top: 0px !important;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #5c5c5c;
  /* or 150% */

  letter-spacing: 0.15px;
}
.updated-time {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  /* or 143% */

  letter-spacing: 0.15px;

  /* text / secondary | 54p */

  color: rgba(20, 20, 20, 0.54) !important;
  margin-top: 10px !important;
}
