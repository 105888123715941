$darkText: #141414;
$lightText: #5c5c5c;

.kanban-card-container {
  font-family: IBMPlex-Reg;
  font-size: 16px;
  color: $darkText;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 8px !important;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.12);
  box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.08), 0px 1px 1px rgba(0, 0, 0, 0.05),
    0px 1px 3px rgba(0, 0, 0, 0.03);
  border-radius: 4px;
  height: auto;
  transition: height 2s;
  cursor: pointer;
  user-select: none;

  > *:first-child {
    margin-bottom: 10%;
  }

  .typename {
    font-size: 14px;
    color: $lightText;
  }

  .title {
    font-size: 17px;
    line-height: 24px;
    color: $darkText;
  }
  .description {
    font-size: 14px;
    line-height: 20px;
    color: $darkText;
  }

  .footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 14px;
    color: $lightText;

    > * {
      margin: 8px 0;
    }
  }

  .actions-container {
    > * {
      padding: 2px 8px;
    }
  }
}
