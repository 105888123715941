.survey-sidebar-tag {
  padding: 8px;
  .survey-sidebar-text {
    font-size: 14px !important;
    font-weight: normal !important;
    font-style: normal !important;
    line-height: 20px !important;
    color: #141414 !important;
    margin-left: 24px !important;
    margin-bottom: 0 !important;
  }
  .title {
    margin-left: 0 !important;
  }
}
.survey-sidebar-collapsable {
  width: 100%;
  padding: 0;
}
.ant-collapse-content {
  padding: unset;
  .ant-collapse-content-box {
    padding: unset !important;
  }
}
