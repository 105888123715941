.survey-sider-header {
  margin-top: 23px;
  margin-bottom: 23px;
}
.sider-search-box {
  display: flex;
  align-items: center;
  padding: 0 8px;
}
.survey-sider-header-text {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  line-height: 20px;
  color: #141414;
  margin-left: 24px;
}
.survey-sider-icon {
  color: rgba(20, 20, 20, 0.54) !important;
}
.survey-close-icon {
  color: rgba(20, 20, 20, 0.54) !important;
  margin-left: 8px;
  cursor: pointer;
}
.survey-popup-input {
  padding: 16px !important;
  border-radius: 4px !important;
}
.modal-button {
  margin-top: 32px;
  display: flex;
  justify-content: flex-end;
  .ant-btn {
    width: unset;
    height: unset;
    padding: 8px 32px;
    text-transform: uppercase;
  }
  .primary-button {
    height: unset;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
  }
}
