.compose-new-mail-footer {
  display: flex;
  height: 60px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  position: sticky !important;
  padding: 10px;
  width: 100%;

  & div.compose-reply {
    text-align: left;
    width: 90%;

    & .compose-input {
      position: relative;
      top: -25px;

      & input,
      .ant-input-affix-wrapper {
        border: none;
        border-width: 0;
        box-shadow: none;
      }
    }

    @media screen and (max-width: 1200px) {
      width: 100%;
    }

    @media only screen and (min-width: 600px) and (max-width: 900px) {
      width: 100% !important;
    }

    @media only screen and (max-width: 600px) {
      width: 90%;
    }
  }

  & div.compose-action-menu {
    text-align: right;
    width: 25%;
    position: relative;

    top: 5px;

    & > * {
      margin-left: 13px !important;
    }

    @media screen and (max-width: 1200px) {
      width: 55%;
    }

    @media only screen and (min-width: 600px) and (max-width: 900px) {
      width: 60% !important;
    }

    @media only screen and (max-width: 600px) {
      width: 70%;
    }
  }
}

.size-75 {
  width: 85% !important;
  left: 15% !important;

  & div.compose-action-menu {
    width: 80%;
  }

  @media screen and (max-width: 1200px) {
    width: 100% !important;
    left: 0% !important;
  }

  @media only screen and (max-width: 600px) {
    width: 100% !important;
    left: 0% !important;

    & div.compose-action-menu {
      text-align: right;
      width: 100%;

      & > * {
        margin-left: 20px !important;
      }
    }
  }

  @media only screen and (min-width: 600px) and (max-width: 900px) {
    width: 100% !important;
    left: 0% !important;

    & div.compose-action-menu {
      width: 100% !important;

      & > * {
        margin-left: 10px !important;
      }
    }
  }
}

.size-75-max {
  width: 75% !important;
  left: 25% !important;

  @media screen and (max-width: 1200px) {
    width: 100% !important;
    left: 0% !important;
  }

  @media only screen and (max-width: 600px) {
    width: 100% !important;
    left: 0% !important;

    & div.compose-action-menu {
      text-align: right;
      width: 100%;

      & > * {
        margin-left: 20px !important;
      }
    }
  }

  @media only screen and (min-width: 600px) and (max-width: 900px) {
    width: 100% !important;
    left: 0% !important;

    & div.compose-action-menu {
      width: 100% !important;

      & > * {
        margin-left: 10px !important;
      }
    }
  }
}

.size-100 {
  width: 100% !important;
}

.bg-white {
  background-color: #fff;
}
