.country-picker-wrapper {
  background: url("../../../assets/img/countries/icon-background.svg");
  width: 48px;
  height: 48px;
  padding: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  img {
    height: 32px;
  }
}
