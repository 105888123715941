.tabs-item-title {
  font-style: normal;
  font-weight: 550;
  font-size: 12px !important;
  line-height: 31px !important;
  text-align: center;
  color: rgba(20, 20, 20, 0.54) !important;

  .ant-tabs-nav-wrap {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }
  .ant-tabs-tab {
    padding-bottom: 6px;
    padding-left: 35px;
    padding-right: 35px;
    margin: 0 20px 0 0;
    text-transform: uppercase;
    letter-spacing: 0.5px !important;
    &:hover {
      color: #00b999 !important;
    }
  }
}
.ant-tabs-tab-active {
  border-bottom: 2px solid #00b999 !important;
  z-index: 2;
  .ant-tabs-tab-btn {
    color: #00b999 !important;
    font-weight: 600 !important;
  }
}
