$green: #00b999;
$purple: #a080e1;
$red: #c43a5a;

.irys-basic-table {
  .ant-table-thead > tr > th {
    background-color: #f0f2f5 !important;
    color: #5c5c5c !important;
    font-style: normal;
    font-weight: bold !important;
    font-size: 12px;
    line-height: 31px;
    width: 100% !important;
  }
  .ant-table-container {
    cursor: pointer !important;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    width: 105% !important;
    font-style: normal;
    font-size: 14px;
    line-height: 21px;
    align-items: center;
    letter-spacing: 0.1px;
    color: #141414;
    font-weight: 500 !important;
  }
  .ant-table-row {
    background-color: white !important ;
  }
}

.badge-table-root {
  .table-translucent-header {
    background-color: transparent;
    * {
      background-color: transparent !important;
    }

    td {
      background-color: white !important;
    }

    td:not(:first-child),
    th:not(:first-child) {
      text-align: center;
    }

    .ant-table-cell {
      user-select: none;
    }

    .ant-table-thead {
      .ant-table-cell {
        padding: 4px 0;
        text-transform: uppercase;
        font-size: 13px;

        .ant-table-column-sorters {
          padding: unset;
        }
      }

      .ant-table-cell:first-child {
        display: inline-block;
        padding-right: 32px;
        padding-left: 32px;
        color: white;
        font-size: 16px;
      }
    }

    .ant-table-tbody {
      box-shadow: 0px 5px 10px rgba(167, 167, 167, 0.09);
      transition: opacity 0.2s ease-in-out;
    }
  }

  .table-translucent-badge {
    &-green {
      .ant-table-thead {
        .ant-table-cell:first-child {
          background-color: $green !important;
        }
      }
    }
    &-purple {
      .ant-table-thead {
        .ant-table-cell:first-child {
          background-color: $purple !important;
        }
      }
    }
    &-red {
      .ant-table-thead {
        .ant-table-cell:first-child {
          background-color: $red !important;
        }
      }
    }
  }

  .table-translucent-header-opened {
    .ant-table-tbody {
      opacity: 1;
    }
    .ant-table-thead {
      .ant-table-cell:not(:first-child) {
      }
      .ant-table-cell:first-child {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }
    }
  }

  .table-translucent-header-closed {
    .ant-table-tbody {
      opacity: 0;
      display: none;
    }
    .ant-table-thead {
      .ant-table-cell:not(:first-child) {
        display: none;
      }
      .ant-table-cell:first-child {
        border-radius: 4px;
      }
    }
  }

  .table-toggle {
    position: absolute;
    left: -35px;
  }

  .table-normal-header {
  }

  .table-add-button {
    margin: 12px 0;
    cursor: pointer;
    color: #7d7d7e;
    text-transform: uppercase;
    word-spacing: 2px;
    font-size: 14px;
    font-family: IBMPlex-Reg;
    cursor: pointer;
    user-select: none;
  }
}
