.events-sa-container {
    width: 100%;
    height: 108px;
    display: flex;
    flex-direction: row;
    border-radius: 10px !important;
    border: solid #E3E3E3;
    border-width: 0.5px;


    .events-sa-info {
        width: 70%;
        height: 100%;

    }
    .news-row {
        display: flex;
        flex-direction: row;
        padding-top: 8px;
    }
    .events-source-sa-info {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
    }
    .events-sa-title{
      font-weight: 500 !important;
      font-size: 14px !important;
      line-height: 21px !important;
      color: #141414 !important;
      margin-top: 11px !important;

    }
    .events-sa-desc{
      font-style: normal !important;
      font-weight: normal !important;
      font-size: 13px !important;
      line-height: 17px !important;
    }
    .events-date-sa-text {
        color: #14141455 !important;
        font-size: 12px !important;
        flex:3

    }
    .news-actions {
        color: #F38B00 !important;
        font-size: 12px !important;
        line-height: 24px !important;
    }

    .events-sa-image {
        width: 25%;
        height: 100%;
    }
    .news-actions {
          flex:2;
            line-height: 1 !important;
            text-decoration-line: underline;

    }
    .events-sa-image-placeholder {
        height: 94px;
        width: 72px;
        margin:7px;
        border-radius: 10px;
        object-fit: cover;
    }
}
