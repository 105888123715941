$primaryColor: #4CCEB8;
$subtitle: #5C5C5C;

.profile-form {
  padding: 4% 24px 12% 24px;
  background-color: white !important;

  .ant-form {
    flex: 1;
  }

  .submit-button {
    background-color: $primaryColor !important;
    border: unset !important;
  }
}
