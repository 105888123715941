.client-hollow-button {
  // background-color: black !important;
  border-radius: 5px !important;
  // border-color: black !important;
  // height: 35px !important;
  // width: 300px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 0.88em !important;
  line-height: 24px !important;
  letter-spacing: 0.4px !important;
  text-transform: uppercase !important;
  min-width: fit-content;
  background-color: white;
  color: #141414;
  border-color: #141414;
}
