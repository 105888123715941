.countries-dropdown-wrapper {
  background: #ffffff;
  box-shadow: 0px 3px 24px rgba(0, 0, 0, 0.18);
  border-radius: 4px;
  width: 266px;
  display: flex;
  flex-direction: column;
  margin-top: -18px;
  margin-left: 22px;
  .countries-search {
    background: rgba(20, 20, 20, 0.04);
    padding: 8px;
    border-radius: 4px;
    width: 100%;
    margin-bottom: 19px;
    .ant-input-affix-wrapper {
      border-radius: 4px;
      padding: 10px 19px;
      border: 1px solid #dddddd;
      .ant-input-prefix {
        margin-right: 11px;
        .anticon {
          color: rgba(20, 20, 20, 0.54);
        }
      }
      .ant-input {
        &::placeholder {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.15px;
          color: rgba(20, 20, 20, 0.54);
        }
      }
    }
  }
  .countries-list {
    display: flex;
    flex-direction: column;

    .ant-row {
      padding-left: 24px;
      padding-top: 5px;
      padding-bottom: 5px;
      margin-bottom: 6px;
      align-items: center;
      cursor: pointer;
      &:hover {
        background-color: #dddddd;
      }
      img {
        margin-right: 8px;
      }
      .ant-typography {
        margin-bottom: unset !important;
        font-style: normal !important;
        font-weight: normal !important;
        font-size: 16px !important;
        line-height: 24px !important;
        letter-spacing: 0.15px !important;
        color: #141414 !important;
      }
    }
  }
}
