.links {
    margin-top: 16px;
    color: #14141455 !important;
    cursor: pointer;
    margin-bottom: 16px !important;
}
.selected-link {
    text-align: center;
    color: #141414 !important;
    margin-bottom: 0 !important;
}