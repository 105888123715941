.validateform {
  display: flex;
  margin: 40px 0;
}

.validateform input {
  height: 55px;
  width: 55px;
  margin: auto !important;
  text-align: center;
  font-size: 24px;
  border: 1px solid silver;
  border-radius: 7px;

  &:focus {
    outline: none;
  }

 
}
.focus{
    border: 2px solid #151515 !important;
  }