.followers-pane {
  background-color: white !important;
  height: 100%;
  padding-top: 12px;


  .ant-divider-horizontal {
    margin: 0 !important;
  }
}
