.knowledge-category-form {
  .ant-input {
    border-radius: 4px !important;
  }

  div[role="alert"] {
    width: fit-content !important;
    margin-top: 0px !important;
    margin-left: 25%;
    z-index: 3;
    position: relative;
    text-align: start;
    margin-bottom: 10px !important;
  }

  .ant-typography {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 24px !important;
    letter-spacing: 0.15px !important;
    color: #141414 !important;
    margin-bottom: unset !important;
  }
  .ant-row-middle {
    align-items: unset !important;
  }
}
.ant-drawer-content-wrapper {
  height: 497px !important;
  .ant-drawer-content {
    .ant-drawer-footer {
      padding: 24px 24px !important;
    }
  }
}
@media only screen and (max-width: 600px) {
  .ant-drawer-content-wrapper {
    height: 80% !important;
    .ant-drawer-content {
      .ant-drawer-footer {
        padding: 10px 10px !important;
      }
    }
  }
}
