.client-faq-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 1em;
}
.faq-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 1.7em;
}
.header {
  text-align: center;
  position: relative;
  padding-top: 0.5em;
  padding-bottom: 1em;
}
.collapse-container {
  margin-top: 0.3em;
}
.header svg {
  position: absolute;
  left: 1em;
  top: 0.6em;
}

.faq-heading {
  padding: 1em;
}

.faq-title {
  font-family: IBMPlex-Reg !important;
  color: rgb(92, 92, 92) !important;
  font-size: 16px !important;
  font-weight: 300 !important;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  padding: 1em;
}
