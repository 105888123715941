.tab-row {
    display: flex;
    flex-direction: row;
    align-content: flex-start;

    .tab-container {
        width: 50%;
        display: flex;
        justify-content: center;
        align-content: center;

    }
}
.tab-separator {
    height: 1px;
    color: rgba(20, 20, 20, 0.08);
    text-align: center;
}
