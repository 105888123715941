.avatar-text {
  font-family: 'IBMPlex-Bold';
  text-transform: uppercase;
}

.avatar-stack-container {
  display: flex;
  align-items: center;
  .ant-avatar-group{
    .ant-avatar.ant-avatar-circle{
      background-color: grey;
      border-width: 1px solid;
      border-color: white;
    }
  }
  .ant-avatar.ant-avatar-circle {
    display: flex;
    align-items: center;
    width: 40px !important;
    height: 40px !important;
    font-size: 16px;
    font-weight: bold;
  }
  .ant-popover-inner { 
    border-radius: 4px !important;
  }  
}

.add-user{
  margin-left: 17px;
}

.avatar-single-container{
  display: flex;
  align-items: center;
  width: 250px;
  height: 36px;
  text-align: center;
  .ant-col{
    .ant-avatar.ant-avatar-circle {
      width: 40px !important;
      height: 40px !important;
    }
  }
}

.avatar-single-text{
  color: #141414;
  font-family: 'IBMPlex-Reg';
  font-weight: 400;
  text-transform: capitalize;
  font-size: 16px;
}



