.post-editor-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  * {
    margin: 0 5px;
  }
}
.post-editor-check {
  color: #7BC67E !important;
  font-size: 1.2em;
}
.post-editor-fail {
  color: #F44336 !important;
  font-size: 1.2em;
}

.editor-card {
  ::-webkit-scrollbar-thumb {
    background-color: unset;
    box-shadow: unset;
  }
}
