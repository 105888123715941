.option-container {
    display: flex;
    width: 80px;
    height: 69px;
    border-radius: 5px;
    flex-direction: column;
    justify-content: center;
    margin-right: 8px;
    .icon {
        padding-left: 8px;
        padding-top: 10px;
    }
    .title {
        font-family: "IBMPlex-Reg" !important;
        padding-left: 8px;
        padding-top: 10px;
        font-size: 13px !important;
        line-height: 30px !important;
        color: #141414 !important;
    }
}
.Events {
    background-color: #75facf;
}

.News {
    background-color: #e7fd6c;
}

.Projects {
    background-color: #b696f7;
}

.Survey {
    background-color: #F79BB3;
    margin-right: 0 !important;
}
