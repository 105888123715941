.cityflag-graph-container {
  background-color: #FAFAFA;
  border-radius: 4px;
  padding: 18px 18px;
  display: flex;
  flex-direction: column;
  .graph-container-header {
    padding-right: 16px;
    font-family: IBMPlex-Bold;

    .subtitle {
      font-size: 14px;
      font-family: IBMPlex-Reg;
      color: #5C5C5C;
    }
  }
}
