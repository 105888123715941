.flag-main-title {
  font-family: IBMPlex-Bold !important;
  font-size: 34px !important;
  font-weight: bold !important;
  color: #141414 !important;
}

.flag-section-title {
  font-family: IBMPlex-Bold !important;
  font-size: 24px !important;
  font-weight: bold !important;
  color: #141414 !important;
  margin-bottom: 24px !important;
  display: flex;
}

.flag-header-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media only screen and (max-width: 900px) {
    flex-direction: column;
    justify-content: start;
    align-items: stretch;

    > *:not(:last-child) {
      margin-bottom: 8px;
    }
  }
}

.flags-content-container {
  .ant-divider-horizontal {
    margin: 16px 0;
  }
}

.flag-action-container {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
}
