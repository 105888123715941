.component-header {
  border-bottom: solid 0.1mm !important;
}

// .rounded-button {
//   // background-color: black !important;
//   color: white !important;
//   border-radius: 100% !important;
//   height: 35px !important;
//   width: 35px !important;
// }

.date-picker {
  .ant-picker {
    position: static;
    width: 235px;
    height: 56px;
    left: 0px;
    top: 0px;

    /* grey / grey-300 */

    border: 1px solid #e0e0e0;
    box-sizing: border-box;
    border-radius: 4px;

    /* Inside Auto Layout */

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    margin: 0px 3px;
  }
  .time-picker {
    width: 100px !important;
  }
  .arrow {
    font-size: 20px !important;
    font-weight: bold !important;
    color: rgba(20, 20, 20, 0.38);
    padding: 0 3vh 0 3vh;
  }
}
