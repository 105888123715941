.text-color-container {
  margin-top: 30px;
}
.text-color-text {
  font-weight: bold !important;
}
.text-color-item {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 0 10px;
}
.text-color-border {
  border: 1px solid rgba(0, 0, 0, 0.16);
  padding: 10px 20px;
  border-radius: 5px;
}
.color-pallate {
  position: relative;
}
.color-palatte-button {
  bottom: 30%;
  cursor: pointer;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  border: 1px solid rgba(0, 0, 0, 0.16);
  background: white;
  z-index: 2;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 500px) {
  .color-palatte-button {
    bottom: 18%;
    width: 25px;
    height: 25px;
  }
}
