.submissions-table-root {
  .submission-actions-root {
    > * {
      margin-right: 8px;
      cursor: pointer;
    }
  }
}

.category-table-dropdown {
  .category-table-menu {
    min-width: 250px;
    box-shadow: 0px 3px 24px rgba(0, 0, 0, 0.18) !important;
    border-radius: 4px;
  }

  .title {
    margin: 0 16px;
  }
}
