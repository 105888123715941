.upload-dragger {
  padding: 10px !important;
}
.ant-upload-btn,
.ant-upload {
  height: fit-content !important;
}
.logo-upload-title {
  font-weight: bold !important;
  margin-top: 20px;
}
.logo-upload-button {
  padding: 30px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: 100%;
  border-radius: 5px !important;
}
.logo-upload-button-underlined {
  text-decoration: underline !important;
  margin-left: 5px;
}
.file-upload-image {
  margin-top: 20px;
  border-radius: 10px !important;
}
.drag-container {
  width: 100%;
}