@import "~antd/dist/antd.css";
/* irys-platform-styles */
@font-face {
  font-family: "IBMPlex-Reg";
  font-style: normal;
  font-display: swap;
  src: local("IBMPlex-Reg"),
    url("./assets/fonts/IBMPlexSans-Regular.ttf") format("opentype");
}
@font-face {
  font-family: "IBMPlex-Bold";
  font-style: normal;
  font-display: swap;
  src: local("IBMPlex-Bold"),
    url("./assets/fonts/IBMPlexSans-Bold.ttf") format("opentype");
}
@font-face {
  font-family: "IBMPlex-SemiBold";
  font-style: normal;
  font-display: swap;
  src: local("IBMPlex-SemiBold"),
    url("./assets/fonts/IBMPlexSans-SemiBold.ttf") format("opentype");
}
@font-face {
  font-family: "IBMPlex-Thin";
  font-style: normal;
  font-display: swap;
  src: local("IBMPlex-Thin"),
    url("./assets/fonts/IBMPlexSans-Thin.ttf") format("opentype");
}
@font-face {
  font-family: "IBMPlex-Light";
  font-style: normal;
  font-display: swap;
  src: local("IBMPlex-Light"),
    url("./assets/fonts/IBMPlexSans-Light.ttf") format("opentype");
}

body {
  font-family: "IBMPlex-Reg";
  font-size: 16px;
  color: #141414;
  background-color: #f0f2f5 !important;
  // background-image: url("./assets/img/login_launch.png");
  // z-index: 10000;
}
h1 {
  font-size: 36px;
}

input,
input::placeholder,
textarea,
textarea::placeholder,
select,
select::placeholder {
  font-family: "IBMPlex-Reg";
}

.login-layout-link {
  text-decoration: none;
  color: black;
  text-align: center;
  margin-left: 10px !important;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) and (max-width: 900px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  #compose-form-drawer {
    .ant-col-xl-6 {
      display: block;
      -ms-flex: 0 0 25%;
      flex: 0 0 10%;
      max-width: 10%;
    }

    .ant-col-xl-18 {
      display: block;
      -ms-flex: 0 0 100%;
      flex: 0 0 85%;
      max-width: 90%;
    }
  }
}

.emoji-picker-react {
  z-index: 1;
}

.ant-pagination-item-active {
  border-radius: 100%;
  background-color: #141414 !important;
  border-color: #141414 !important;
}
.ant-pagination-item-active a {
  color: white !important;
}
.ant-pagination-item:not(.ant-pagination-item-active) a {
  color: #141414 !important;
}
.ant-pagination-item-link,
.ant-pagination-item-link:hover {
  color: #141414 !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: black;
  border-color: black;
}
p {
  margin-bottom: 0 !important;
}

// .ant-layout {
//   background-color: transparent !important;
// }
