.department-action-icon {
  display: flex;
  justify-content: center;
  z-index: 5;
  .anticon {
    background: #f5f5f5;
    border-radius: 4px;
    padding: 5px;
    rotate: 90deg;
    height: 22px;
    display: flex;
    align-items: center;
  }
}
