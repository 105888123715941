.self-notification-container {
  padding: 0 30px;

  .notification-label-text {
    font-style: normal;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 24px !important;

    letter-spacing: 0.15px !important;
    color: #141414 !important;
  }
  .notification-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    .ant-select-selector {
      border-radius: 4px !important;
    }
  }
  .send-notification {
    .ant-select-selector {
      height: unset !important;
      padding: 12px 16px;
      border: 1px solid #e0e0e0;
      border-radius: 4px !important;
    }
    .ant-select-multiple {
      .ant-select-selection-item {
        border: 1px solid #5c5c5c;
        padding-top: 4px;
        padding-bottom: 4px;
        height: unset !important;
        border-radius: 4px;
      }
      .ant-select-selection-item-content {
        margin-right: 24px;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.1px;
        color: #5c5c5c;
      }
    }
  }
  .reply-to {
    .ant-select-selection-search {
      .ant-select-selection-search-input {
        color: #141414 !important;
        font-style: normal !important;
        font-weight: normal !important;
        font-size: 16px !important;
        line-height: 24px !important;
        letter-spacing: 0.15px !important;
      }
    }
    .ant-select-selection-placeholder,
    .ant-select-selection-item {
      color: #141414 !important;
      font-style: normal !important;
      font-weight: normal !important;
      font-size: 16px !important;
      line-height: 24px !important;
      letter-spacing: 0.15px !important;
      display: flex;
      align-items: center;
    }
  }
}
