.communications-root {
  .communication-main-title {
    font-family: IBMPlex-Bold !important;
    font-size: 34px !important;
    font-weight: bold !important;
    color: #141414 !important;
    display: block;
    margin: 15px 0px 20px 0px !important;
  }

  .ant-tabs {
    overflow: unset;
  }
}
