.event-sidebar {
  background-color: white !important;
  height: fit-content !important;
  border-radius: 5px !important;
  margin-right: 15px !important;

  .menus {
    background-color: white !important;
    color: black !important;
  }

  .header {
    padding-top: 1px !important;
  }

  .deleted {
    bottom: 0 !important;
  }

  .count {
    text-align: end !important;
  }
  .ant-menu-item {
    width: 95% !important;
    margin-left: 2.5% !important;
  }
  .ant-menu-item-selected {
    background-color: #f0f0f0 !important;
    color: black !important;
    border-radius: 5px !important;
  }
  .ant-menu-item-active {
    background-color: #f0f0f0 !important;
    color: black !important;
  }
  .ant-layout-sider-children .ant-menu .ant-menu-item:hover {
    background-color: #f0f0f0 !important;
    color: black !important;
    border-radius: 5px !important;
  }
  .ant-menu-submenu-title,
  .ant-menu-submenu-arrow {
    color: black !important;
  }
  .ant-menu-item::after,
  .ant-menu > .ant-menu-item-selected {
    border-right: 0px !important;
  }
}
