$siderColor: black;
$siderFontColor: white;
::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}
.sider {
  position: relative !important;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 100 !important;

  .ant-menu-dark sider-menu {
    width: 173px !important;
    max-width: 173px !important;
  }

  @media only screen and (max-width: 600px) {
    position: absolute !important;
  }
}
.sider-menu {
  // background-color: $siderColor !important;
  height: 100%;
}
.sider-menu-item {
  z-index: 100 !important;
  margin-top: -8px !important;
  // background-color: $siderColor !important;
}
.image-container {
  width: 100%;
  text-align: center;
  margin-bottom: 20%;
  .ant-image {
    display: flex;
    justify-content: center;
  }
}
.select-container {
  width: 100% !important;
  height: 30px !important;
  text-align: left;
  margin-bottom: 25%;
  margin-top: 10px !important;
  .ant-image {
    display: flex;
    justify-content: center;
  }
}
// .image {
//   width: 60% !important;
//   margin-top: 10%;
//   filter: invert(100%);
// }
.sider-menu-logout {
  position: absolute !important;
  bottom: 0 !important;
}

.sider-menu-logout:hover {
  animation: none;
}
@media only screen and (max-width: 769px) {
  .sider-menu-logout {
    position: absolute !important;
    bottom: 0 !important;
  }
  .sider {
    width: 75px !important;
  }
  .image-container {
    width: 100%;
    text-align: center;
    margin-bottom: 40%;
  }
  .image {
    width: 70%;
    margin-top: 30%;
  }
}
//ant design styles
.sider .ant-menu-item-selected {
  background-color: rgba(134, 131, 131, 0.2) !important;
  color: white !important;
  border-radius: 10px !important;
}
.sider .ant-layout-sider-children .ant-menu .ant-menu-item:hover {
  background-color: rgba(134, 131, 131, 0.4) !important;
  color: white !important;
  border-radius: 10px !important;
}
.profileImage {
  margin-left: 10%;
  float: left;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #512da8;
  font-size: 35px;
  color: #fff;
  text-align: center;
  line-height: 50px;
  // margin: 10px 0;
}
