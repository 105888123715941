.autocomplete-dropdown-container-box {
  display: flex !important;
  // justify-content: center !important;
  align-items: center !important;
  height: 50px !important;
  border-bottom: 1px solid #e0e0e0 !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  z-index: 3000 !important;
}
