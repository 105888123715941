.add-question-action {
  display: flex;
  align-items: center;
  height: 40px;
  .add-question-icon {
    cursor: pointer;
    font-size: 34px;
    // color: #141414;
    margin-right: 16px;
  }
  .ant-typography {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-bottom: unset !important;
    font-style: normal;
    font-weight: normal !important;
    font-size: 20px !important;
    line-height: 32px !important;
    letter-spacing: 0.15px !important;
    color: #141414 !important;
  }
  .tabs-item-title {
    text-transform: unset;
  }
  .add-question-input-wrapper {
    .ant-layout {
      background: transparent;
    }
    .ant-input {
      &:focus {
        border-bottom: 2px solid #00b999 !important;
        caret-color: #2196f3;
      }
      padding: 4px unset !important;
      font-size: 20px !important;
      &::placeholder {
        font-style: normal;
        font-weight: normal;
        line-height: 32px;
        letter-spacing: 0.15px;
        margin-bottom: unset !important;
        display: flex;
      }
      color: #2196f3 !important;
    }
    .add-question-input {
      border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
      border-radius: unset;
    }
  }
}
