.ant-modal-header {
  background: #fafafa !important;
  border-radius: 10px 10px 0 0 !important;
}
.ant-modal-content {
  border-radius: 10px !important;
}
.event-group-modal {
  border-radius: 10px;
}
.modal {
  .ant-modal-title {
    font-weight: 600 !important;
    text-transform: uppercase;
  }
  .ant-typography {
    font-style: normal;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 24px !important;
    color: #141414 !important;
  }
  .ant-input {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #141414 !important;
  }
}
