$darkText: #141414;
$lightText: #808080;

.activity-item-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 32px;
  .name {
    margin: 0 !important;
    font-size: 14px !important;
    color: $darkText !important;
    font-family: IBMPlex-Reg !important;
  }
  .subtitle {
    margin: 0 !important;
    font-size: 12px !important;
    color: $lightText !important;
    font-family: IBMPlex-Reg !important;
  }
  .avatar {
    margin-right: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 14px;
  }
  .activity-item-right {
    display: flex;
    flex-direction: column;
  }
}
