.question-settings-wrapper {
  padding: 42px 51px;
  width: 100%;

  .question-settings-title {
    text-align: left !important;
    .ant-typography {
      font-style: normal;
      font-weight: 600 !important;
      font-size: 16px !important;
      line-height: 24px !important;
      letter-spacing: 0.15px !important;
      color: #141414 !important;
      text-transform: uppercase;
      .question-type-choice {
        color: #00b999 !important;
      }
    }
    .close-settings {
      display: flex;
      justify-content: flex-end;
      padding-right: 4px;
      svg {
        width: 14px;
        height: 14px;
        cursor: pointer;
      }
    }
  }
  .question-action-toggle{
      margin-top: 36px;
  }
  .question-action-description{
      margin-top: 16px;
  }
}
