
.department-list-modal {
  .ant-modal-content {
    border-radius: 5px !important;
    width: 600px;
  }
  .ant-modal-header {
    background: white !important;
    .ant-modal-title > h2 {
      font-weight: bold !important;
    }
  }
  .ant-modal-footer {
    button {
      font-weight: bold !important;
    }
  }
  .danger-text {
    color: #f44336 !important;
  }
  .modal-title {
    text-align: left !important;
  }

  .ant-modal-header {
    color: rgba(0, 0, 0, 0.85);
    padding-left: 24px !important;
    padding-top: 16px !important;
  }

  .ant-modal-body {
    padding: 0px !important;
    padding-left: 24px !important;
  }
}
