$success: #4CAF50;
$danger: #F44336;

.modal-main {
  font-family: IBMPlex-Reg !important;
  .ant-modal-header {
    border-bottom: none;
  }

  .ant-modal-footer {
    border-top: none;

    .ant-btn {
      border: none;
      box-shadow: none;
      -webkit-box-shadow: none;
      text-transform: uppercase;
    }

    .ant-btn-primary {
      background-color: transparent;
    }
  }
}

.modal-main-no-close-icon {
  .ant-modal-close-x {
    display: none;
  }
}

.modal-main-success {
  .ant-modal-title {
    color: $success;
  }
  .ant-btn-primary, .ant-btn-primary:focus, .ant-btn-primary:hover {
    color: $success;
  }
}

.modal-main-danger {
  .ant-modal-title {
    color: $danger;
  }
  .ant-btn-primary, .ant-btn-primary:focus, .ant-btn-primary:hover {
    color: $danger;
  }
}

.modal-main-default {
  .ant-btn-primary, .ant-btn-primary:focus, .ant-btn-primary:hover {
    color: white;
    background-color: black !important;
  }
}
