.square-image {
  //   box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.2);
  // inset 1px 1px 1px 1px rgba(0, 0, 0, 0.1);
}
.memoized-avatar {
  background: transparent !important;
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
}
