.message-creation-container {

  .message-creation-launchSa{
    width: 62px;
    height: 30px;
    flex: 4;
    border: 1px solid rgba(0, 0, 0, 0.23);
    box-sizing: border-box;
    border-radius: 16px;
  }
  .message-creation-title{
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 13px !important;
    line-height: 24px !important;
  }
  .message-creation-logo{
    height: 18px;
    margin-left: 8px;
    margin-right: 8px;
  }

  .ant-select-selection-search {
    .ant-select-selection-search-input {
      color: #141414 !important;
      font-style: normal !important;
      font-weight: normal !important;
      font-size: 16px !important;
      line-height: 24px !important;
      letter-spacing: 0.15px !important;
    }
  }

  .ant-select-selector {
    height: 100% !important;
    min-height: 30px !important;
    border: none !important;
  }
  .ant-select-selection-placeholder,
  .ant-select-selection-item {
    color: #141414 !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 24px !important;
    letter-spacing: 0.15px !important;
    display: flex;
    align-items: center;
  }

  .ant-select-selector {
    height: unset !important;
   
    
    border-radius: 4px !important;
  }
  .ant-select-multiple {
    .ant-select-selection-item {
      border: 1px solid #5c5c5c;
      padding-top: 4px;
      padding-bottom: 4px;
      border-radius: 20px;
      background-color: white;
    }
    .ant-select-selection-item-content {
      
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.1px;
      color: #5c5c5c;
     
    }
  }

  .client-message-title {
    font-size: 14px !important;
    color: #141414 !important;
    font-weight: bold !important;
    margin-top: 22px;
  }

  .communication-actions {
      width: 100%;
      height: 1px;
      background-color:#E0E0E0;
  }
  .communication-image {
      width: 45px;
      height: 45px;

  }
  .message-creation-horizontal {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      margin-top:22px;

  }
  .communication-input{
    font-size: 14px !important;
    color: #141414 !important;
    border:0!important;
    flex:4;
  }
  .communication-title {
      font-size: 14px !important;
      color: #141414 !important;
      font-weight: bold  !important;
      padding-left: 16px;
      flex:1
  }
  .communication-image-placeholder {
      height: 45px;
      width: 45px;
      margin-left: auto;
      margin-right: auto;
      border-radius: 10px;
      object-fit: cover;

  }
  .communication-body{
    font-size: 14px !important;
    line-height: 18px !important;
    color:rgba(20, 20, 20, 0.54) !important;
  }
  .comunication-child span {
    text-align: left !important;
    
    margin: 4px auto;
  }
  .communication-text-area{
    height: 200 !important;
    font-size: 14px !important;
    color: #141414 !important;
    border:0!important;
  }
}
