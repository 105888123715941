.question-result-info-wrapper {
  margin-bottom: 36px;
}
.result-info-header-text {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 24px !important;
  line-height: 32px !important;
  color: #141414 !important;
  margin-bottom: 15px !important;
}
.questions-collapsable {
  background-color: #fff;
  padding: 30px;
  box-shadow: 0px 2px 23px rgba(233, 233, 233, 0.4);
  border-radius: 4px;
  .question-collapsable-header {
    width: 100%;
    padding: 6px 0;
    background: #fafafa;
    border: 1px solid rgba(185, 185, 185, 0.3);
    border-radius: 10px;
    position: relative;
    z-index: 3;
    .ant-typography {
      text-transform: uppercase;
      font-style: normal;
      font-weight: 600 !important;
      font-size: 12px !important;
      line-height: 31px !important;
      text-align: center;
      letter-spacing: 1px !important;
      color: #5c5c5c !important;
    }
    .question {
      text-align: left !important;
      align-self: flex-start !important;
      padding-left: 60px;
    }
    .text-center {
      text-align: center !important;
    }
  }
  .question-collapsable-content {
    margin-top: -8px;
    .question {
      text-align: left !important;
      padding-left: 60px;
      display: flex;
      align-items: center;
      .cityflag-graph-container {
        background: transparent;
      }
      .ant-typography {
        margin-bottom: unset !important;
        font-style: normal;
        font-weight: 500 !important;
        font-size: 14px !important;
        line-height: 20px !important;
        letter-spacing: 0.15px !important;
        color: #141414 !important;
      }
    }
    .ant-collapse {
      border-left: unset !important;
      border-right: unset !important;
      .ant-collapse-item {
        border-bottom: 1px solid #eaebf0;
      }
      .ant-collapse-item-active {
        border-bottom: 1px solid #eaebf0;
        .ant-collapse-header {
          border-bottom: unset !important;
          background: #fcfcfc;
        }
        .ant-collapse-content {
          border-top: unset !important;
          background: #fcfcfc;
        }
      }
    }
    .ant-collapse-header {
      padding-right: unset !important;
      padding-left: unset !important;
      padding-top: 17px !important;
      padding-bottom: 17px !important;
      background: #fff;
      display: flex;
      align-items: center;
      .ant-collapse-arrow {
        display: flex !important;
        height: 100%;
        align-items: center;
        top: unset !important;
      }
      .ant-row {
        align-items: center;
        width: 100%;
        .ant-col {
          text-align: center;
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .questions-collapsable {
    .question-collapsable-header {
      .question {
        padding-left: 26px !important;
      }
    }
  }
  .questions-collapsable {
    .question-collapsable-content {
      .question {
        padding-left: 10px !important;
        .icon {
          width: 50px;
        }
      }
    }
  }
}

@media only screen and (max-width: 720px) {
  .questions-collapsable {
    .question-collapsable-content {
      .question {
        .icon {
          width: 77px;
        }
      }
    }
  }
}
