.events-tab-wrapper {
  .ant-tabs{
    .ant-tabs-nav {
      margin-top: 23px;
      .ant-tabs-nav-wrap{
        .ant-tabs-nav-list {
          .ant-tabs-tab-active {
            border-bottom: 2px solid #141414 !important;
            .ant-tabs-tab-btn {
              color: #141414 !important;
            }
          }
          .ant-tabs-tab {
            :hover{
              color: #141414 !important;
            }
            .ant-tabs-tab-btn {
              padding-bottom: 13.5px;
            }
          }
          .ant-tabs-ink-bar {
            background: #141414 !important;
          }
          .ant-tabs-ink-bar-animated{
            background: #141414 !important;
          }
        }
      }
    }
  }
}