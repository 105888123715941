.notifications-pane-drawer {
  height: 100% !important;
  .ant-drawer-body {
    overflow-y: hidden;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    padding-bottom: 0;
    .items-container {
      margin-top: 5vh !important;
      // overflow: scroll;
      // flex: 1;

      position: absolute;
      top: 8vh;
      left: 0;
      width: 100% !important;
    }

    .empty-item-container {
      text-align: center !important;
      font-weight: bolder;
      font-size: 24px;
    }

    .item-container {
      // background-color: #cecece25;
      border: 1px solid #e0e0e0;
      cursor: pointer;

      width: 100%;
      height: 10vh;
      .item {
        align-items: center;
        vertical-align: middle;
        display: flex;
        height: 8vh;
        width: 100% !important;
        .title {
          font-family: IBM Plex Sans;
          font-weight: 500;
          font-style: normal;
          font-size: 14px;
          line-height: 20px;
          /* identical to box height, or 143% */

          letter-spacing: 0.15px;

          /* primary / main */

          color: #141414;
        }
        .subtitle {
          font-family: IBM Plex Sans;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 16px;
          /* identical to box height */

          letter-spacing: 0.4px;

          /* text / secondary | 54p */

          color: rgba(20, 20, 20, 0.54);
        }
        // background-color: red;
      }
    }
  }

  .notification-icon-container {
    box-sizing: border-box;
    align-items: center;
    vertical-align: middle;
    display: flex;
    text-align: center;
    justify-content: center;
    border-radius: 150px !important;
    background-color: #acacac48;
    width: 7vh;
    height: 7vh;
    color: #5c5c5c;
  }

  .notification-dot {
    box-sizing: border-box;
    align-items: center;
    vertical-align: middle;
    display: flex;
    text-align: center;
    justify-content: center;
    border-radius: 150px !important;
    background-color: #da8be3 !important;
    width: 1vh;
    height: 1vh;
    background-color: #da8be3 !important;
  }

  .notifications-pane-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .notifications-header-left {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      .title {
        font-style: normal;
        font-weight: 500;
        font-size: 26px;
        line-height: 42px;
        /* identical to box height, or 161% */

        display: flex;
        align-items: center;
        letter-spacing: 0.25px;
        font-family: IBMPlex-Bold;
      }

      .subtitle {
        color: #5c5c5c;
        font-size: 16px;
      }
    }
    .notifications-header-right {
      .back-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        border: 1px solid #e0e0e0;
        padding: 8px 10px;
        height: 40px;
        width: 40px;
      }
    }
  }
}
