.project-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    .project-info {
        display: flex;
        flex-direction: row;
        width: 100%;
    }
    .project-logo {
        height: 75px;
        width: 75px;
        align-self: center;
        object-fit: cover;
    }
    .project-title {
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-family: "IBMPLEX-REG";
        margin-left: 16px;
        color: #141414 !important;
        .project-organization {
            font-weight: 600;
            font-size: 20px;
        }
        .project-name {
            font-size: 14px !important;
        }
    }
    .project-dropdown {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        padding: 10px;
        justify-self: flex-end;
        .down-svg {
            cursor: pointer;
        }
    }
}

  