.analytics-editor-actions-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  .action-buttons {
    width: auto !important;

    > * {
      text-transform: uppercase !important;
    }
  }

  .action-buttons:not(:last-child) {
    margin-right: 8px;
  }
}

.analytics-editor-actions-container {
  .action-navigation-container {
    > * {
      margin: 0 12px;
      padding: 8px;
    }
  }
}

.analytics-grid-container {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: stretch;

  > .react-grid-layout {
    height: 100% !important;
  }

  .react-grid-item.react-grid-placeholder {
    background: #75FACF;
  }
}
