.survey-header-container {
  margin-top: 8px;
  .ant-row {
    margin-bottom: unset !important;
  }
  .survey-action-container {
    display: flex;
    width: 100%;
    .survey-action-row {
      width: 100%;
      justify-content: flex-end;
    }
  }
  .survey-action-layout {
    width: auto;
    .ant-col {
      padding: 13px;
    }
  }
  .survey-header-button {
    width: auto;
    text-transform: uppercase;
    span {
      font-size: 100%;
    }
  }
  .survey-action-search {
    flex: 1 1;
    width: auto;
    max-width: 300px;
    min-width: 150px;
    min-height: 50px;
    height: 100%;
    border-radius: 4px;
  }

  .survey-main-title {
    font-family: IBMPlex-Bold !important;
    font-size: 34px !important;
    font-weight: bold !important;
    color: #141414 !important;
    text-align: left !important;
    margin-bottom: 0 !important;
    text-transform: capitalize;
  }
  .ant-input-affix-wrapper {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .ant-btn {
    height: unset;
    padding: 10px;
  }
  .survey-header-input {
    border-radius: 4px;
  }
}
.divider {
  margin-top: 12px !important;
}
@media only screen and (max-width: 900px) {
  .survey-action-search {
    max-width: unset !important;
  }
}
