.message-title {
  font-weight: bold;
  margin-bottom: 32px;
}

.analytics-button {
  width: auto !important;
  padding: 0 32px !important;
  margin: 32px 0;

  @media only screen and (max-width: 600px) {
    width: 100% !important;
  }
}

