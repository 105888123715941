.client-card {
  display: flex;
  width: 150;
  height: 50px;
  border: 1px solid #fff;
  border-radius: 100% !important;
  margin-top: 20px;
  cursor: pointer !important;
  text-decoration: none !important;
  color: black !important;
}

.client-card-img {
  width: 20%;
  background: #F4F4F4;
  text-align: center;
  padding-left: 10px;
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}

.client-card-main {
  width: 60%;
  background: #F4F4F4;
  padding: 0px 10px;
  font-size: 10px;
}

.client-card-download {
  width: 20%;
  background: #F4F4F4;
  padding-left: 5px;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}
