.avatar-container {
  position: relative;
}
.online-indicator {
  width: 12px;
  height: 12px;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.08), 0px 6px 10px rgba(0, 0, 0, 0.05),
    0px 1px 18px rgba(0, 0, 0, 0.03);
  transform: matrix(1, 0, 0, -1, 0, 0);
  position: absolute;
  border-radius: 50%;
  right: 7.42%;
  top: 75.49%;
}
.user-list-name-container {
  display: flex;
  flex-direction: column;
  .user-list-level {
    font-style: normal;
    font-weight: 600 !important;
    font-size: 12px !important;
    line-height: 16px !important;
    letter-spacing: 0.4px !important;
    color: #00b999 !important;
  }
}
span.ant-typography.user-list-name {
  color: #141414 !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  margin-bottom: unset !important;
}
