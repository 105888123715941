.ellipse-button {
  display: flex;
  padding: 0;
  cursor: pointer;
  box-sizing: content-box;
  justify-content: center;
  align-items: center;
  align-self: center;
  text-align: center;
  font-size: 2em;
}
.user-list-table {
 
  thead > tr > th:first-child {
    border-radius: 10px 0 0 10px !important;
    border-top: 1px solid rgba(185, 185, 185, 0.3);;
    border-left: 1px solid rgba(185, 185, 185, 0.3);;
    border-bottom: 1px solid rgba(185, 185, 185, 0.3);;
  }
  thead > tr > th:last-child {
    border-bottom: 1px solid rgba(185, 185, 185, 0.3);;
    border-top: 1px solid rgba(185, 185, 185, 0.3);;
    border-right: 1px solid rgba(185, 185, 185, 0.3);;
    border-radius: 0 10px 10px 0 !important;
  }
  thead > tr > th {
    border-bottom: 1px solid rgba(185, 185, 185, 0.3);;
    border-top: 1px solid rgba(185, 185, 185, 0.3);;
    font-style: bold !important;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #5c5c5c !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  tbody > tr {
    cursor: pointer !important;
  }
}
