.question-type-dropdown {
  box-shadow: 0px 3px 24px rgba(0, 0, 0, 0.18) !important;
  border-radius: 4px;
  width: 293px;
  .question-type-title {
    font-family: "IBMPlex-Bold" !important;
    font-style: normal !important;
    font-size: 16px !important;
    line-height: 32px !important;
    letter-spacing: 0.15px !important;
    color: #141414 !important;
    margin-bottom: 9px !important;
    margin-top: 9px !important;
    padding: 0 16px;
    text-align: left !important;
  }
  .ant-menu-item {
    display: flex;
    align-items: center;
    &:last-child{
        margin-bottom: 16px;
    }
    .ant-typography {
      font-style: normal;
      font-weight: normal !important;
      font-size: 16px !important;
      line-height: 24px !important;
      letter-spacing: 0.15px !important;
      color: #141414 !important;
      margin-bottom: unset !important;
    }
  }
  .ant-menu-item-active {
    background: #ebebeb;
  }
  .ant-menu-item-selected {
    background-color: #ebebeb !important;
  }
  .icon {
    width: 24px;
    height: 24px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    margin-right: 8px;
    margin-top: unset !important;
    .anticon {
      margin-right: unset;
    }
  }
  .check-icon {
    background-color: #da8be3;
  }
  .multi-check-icon {
    background: #00b999;
  }
  .bool-icon {
    background: #64b6f7;
  }
  .text-icon {
    background: #a080e1;
  }
}
