.survey-card-dropdown {
  z-index: 30;
  position: absolute;
  width: 90%;
  box-shadow: 0px 3px 24px rgba(0, 0, 0, 0.18);
  border-radius: 4px;
  left: 20px;
  margin-top: -10px;
}
.survey-item {
  position: relative !important;
}
