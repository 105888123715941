$lightText: #808080;
$lightBorder: #E0E0E0;

.comments-create-container {
  background-color: white;
  padding: 24px 24px;
  border-radius: 8px;

  .header {
    > * {
      margin-right: 16px;
    }
    margin-bottom: 16px;
  }

  .comment-input {
    padding: 8px 0;

    textarea:placeholder-shown {
      color: $lightText !important;
    }

    border-color: $lightBorder !important;
  }

  .send-btn {
    width: auto;
    padding: 0 24px;
    margin-top: 24px;
  }
}
