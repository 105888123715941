.UPI-item-container {
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 8px;
    margin-bottom: 8px;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 90%;
    height: 110px;
    border-color: #E3E3E3;
    border-width: 1px;
    border-style: solid;
    .UPI-image-container {
        margin-right: 8px;
        margin-top: 10px;
        margin-bottom: 10px;
        margin-left: 8px;
    }
    .UPI-image {
        width: 76px;
        height: 83px;
        border-radius: 5px;
        object-fit: cover;
    }
    .item-content {
        margin-left: 8px;
        margin-right: 8px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .UPI-title {
        color: #141414 !important;
        margin-bottom: 0 !important;
    }
    .UPI-city {
        margin-bottom: 8px !important;
        width: 180px;
        color: #5C5C5C !important;
        font-family: IBMPlex-Light !important;
    }
    .UPI-updatedAt {
        color: #14141455 !important;
        font-size: 10px !important;
        line-height: 12px !important;
        font-family: IBMPlex-Light !important;
        margin-bottom: 0 !important;
    }
}

