.knowledge-categories {
  margin-top: 50px;
  .add-knowledge-category {
    font-style: normal;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 24px !important;
    letter-spacing: 0.4px !important;
    text-transform: uppercase !important;
    color: rgba(20, 20, 20, 0.54) !important;
    cursor: pointer;
  }
}
