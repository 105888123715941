.compose-mail-header {
  display: flex;

  & div:nth-child(1) {
    width: 95%;
  }

  & div:nth-child(2) {
    margin-right: 30px;
  }
}