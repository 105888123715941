.survey-card-dropdown {
  .ant-menu-submenu-title,
  .ant-menu-submenu-arrow {
    &:hover {
      background-color: rgba(0, 0, 0, 0.04) !important;
      color: rgb(0, 0, 0) !important;
    }
  }
  .ant-menu-vertical {
    .ant-menu-item {
      margin-top: 0;
      margin-bottom: 0 !important;
      &:hover {
        background-color: rgba(0, 0, 0, 0.04) !important;
        color: rgb(0, 0, 0) !important;
      }

      .ant-menu {
        .ant-menu-item-selected {
          background-color: rgba(0, 0, 0, 0.04) !important;
          color: #000 !important;
        }
      }
    }
  }
}
