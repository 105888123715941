.survey-question-created-container {
  align-items: center;
}

.survey-icon {
  height: 40px !important;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  color: #fff;
  margin-right: 16px;
  margin-top: unset !important;
}
.check-icon {
  background-color: #da8be3;
}
.multi-check-icon {
  background: #00b999;
}
.bool-icon {
  background: #64b6f7;
}
.text-icon {
  background: #a080e1;
}
.question-title {
  display: flex;
  margin-bottom: unset !important;
  font-style: normal;
  font-weight: normal !important;
  font-size: 20px !important;
  line-height: 32px !important;
  letter-spacing: 0.15px !important;
  color: #141414 !important;
}
.d-flex {
  display: flex;
  margin-left: 50px;
  margin-right: 50px;
  flex-direction: column;
  width: 100%;
  .ant-divider {
    margin: unset !important;
  }
  .question-choices {
    flex-direction: column;
    margin-left: 56px;
    .choice-text {
      font-style: normal;
      font-weight: normal !important;
      font-size: 20px !important;
      line-height: 32px !important;
      letter-spacing: 0.15px !important;
      color: #141414 !important;
    }
    .checkbox-input .ant-checkbox-wrapper {
      margin-bottom: 8px;
    }
  }
}
.question-actions {
  justify-content: flex-end;
  padding-right: 24px;
  align-items: center;
  margin-bottom: 20px;
  &:last-child {
    margin-right: 10px;
  }
  .action-icons {
    margin-left: 28px;
    cursor: pointer;
  }
  .dropdown-icon-container {
    position: relative;

    .dropdown-container {
      position: absolute;
      left: -133px;
      box-shadow: 0px 3px 24px rgba(0, 0, 0, 0.18);
      border-radius: 4px;
      top: 45px;
      z-index: 999;
      .ant-menu {
        border-radius: 4px;
        padding-top: 8px;
        width: 166px;
      }
    }
  }
}
