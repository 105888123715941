.bottom-drawer {
  .ant-drawer-content-wrapper {
    height: 95% !important;
    right: 20px !important;
    left: unset !important;
    width: 35% !important;
    .ant-drawer-content {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;

      .ant-drawer-header {
        background-color: #fafafa;

        .ant-drawer-title {
          text-transform: uppercase;
          font-weight: unset !important;
          font-family: IBMPlex-Bold;
          font-size: 16px;
        }
      }
    }

    @media only screen and (max-width: 800px) {
      right: 0 !important;
      left: 0 !important;

      .ant-drawer-wrapper-body {
        overflow: scroll !important;
        height: 100% !important;
        width: 100% !important;
      }
    }
  }
  @media only screen and (max-width: 800px) {
    right: 0 !important;
    left: 0 !important;

    .ant-drawer-content-wrapper {
      height: 100% !important;
      left: unset !important;
      width: 100% !important;
    }
  }
}

.bottom-altern {
  .ant-drawer-content-wrapper {
    height: 70% !important;
    right: 20px !important;
    left: unset !important;
    width: 35% !important;
    .ant-drawer-content {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;

      .ant-drawer-header {
        background-color: #fafafa;

        .ant-drawer-title {
          text-transform: uppercase;
          font-weight: unset !important;
          font-family: IBMPlex-Bold;
          font-size: 16px;
        }
      }
    }

    @media only screen and (max-width: 800px) {
      right: 0 !important;
      left: 0 !important;

      .ant-drawer-wrapper-body {
        overflow: scroll !important;
        height: 100% !important;
        width: 100% !important;
      }
    }
  }
  @media only screen and (max-width: 800px) {
    right: 0 !important;
    left: 0 !important;

    .ant-drawer-content-wrapper {
      height: 100% !important;
      left: unset !important;
      width: 100% !important;
    }
  }
}
.bottom-smaller {
  .ant-drawer-content-wrapper {
    height: 35% !important;
    right: 20px !important;
    left: unset !important;
    width: 35% !important;
    .ant-drawer-content {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;

      .ant-drawer-header {
        background-color: #fafafa;

        .ant-drawer-title {
          text-transform: uppercase !important;
          font-weight: unset !important;
          font-family: IBMPlex-Bold;
          font-size: 16px;
        }
      }
    }

    @media only screen and (max-width: 800px) {
      right: 0 !important;
      left: 0 !important;

      .ant-drawer-wrapper-body {
        overflow: scroll !important;
        height: 100% !important;
        width: 100% !important;
      }
    }
  }
  @media only screen and (max-width: 800px) {
    right: 0 !important;
    left: 0 !important;

    .ant-drawer-content-wrapper {
      height: 100% !important;
      left: unset !important;
      width: 100% !important;
    }
  }
}

#compose-form-drawer .ant-drawer-content-wrapper {
  width: 50% !important;
}

#compose-form-drawer-max .ant-drawer-content-wrapper {
  width: 95% !important;
}
