.collapse-container {
  padding-bottom: 0.5em;
  padding-top: 0.3em;
  padding-left: 0.4em;
  padding-right: 0.4em;
  font-family: IBMPlex-Reg !important;
}
.collapse-header {
  z-index: 1;
  cursor: pointer;
  border-radius: 10px;
  padding: 0.5em;
  padding-top: 0.7em;
  padding-bottom: 0.5em;
  padding-left: 1em;
  font-size: 0.9em;
}
.collapse-header svg {
  float: right;
  margin-right: 1em;
  margin-top: -1.1em;
}
.collapse-title {
}

.collapse-body-container {
  font-size: 0.8em;
  padding: 0.5em;
  padding-top: 0.8em;
  z-index: 2;
}
