.events-topbar-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding-bottom: 23px;
  padding-top: 32px;
  .back-button{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 5%;
    cursor: pointer;
  }
  .drawer-item {
    display: flex;
    align-items: center;
    width: 10%;
    justify-content: center;
    cursor: pointer;
  }
  .calendar-item {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 80%;
    cursor: pointer;
    .calendar-text {
      margin-bottom: 0 !important;
      margin-left: 12px;
      margin-right: 5.25px;
    }
  }
}