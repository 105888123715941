.general-settings-map-suggest {
  background: unset !important;
  margin-bottom: 19px;
  .coverage-text {
    font-style: normal;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 24px !important;
    display: flex !important;
    align-items: center !important;
    letter-spacing: 0.15px !important;
    color: #141414 !important;
    margin-bottom: 8px !important;
  }
  .ant-input-affix-wrapper {
    border-radius: 4px !important;
    .ant-input {
      padding: 16px 12px !important;
      &::placeholder {
        color: rgba(20, 20, 20, 0.38);
      }
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      letter-spacing: 0.15px;
      color: #141414;
    }
  }
  .location-select {
    position: relative;
    .location-suggestions {
      margin-top: 4px;
      background: #ffffff;
      box-shadow: 0px 3px 24px rgba(0, 0, 0, 0.18);
      border-radius: 4px;
      position: absolute;
      z-index: 9;

      &:last-child {
        border-bottom: unset !important;
      }
    }
  }
  .zip-codes {
    margin-top: 16px;
    display: flex;
    .ant-tag {
      padding: 6px 8px;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.15px;
      color: #141414;
      display: flex;
      align-items: center;
      border: 1px solid #5c5c5c;
      border-radius: 4px;
      margin-right: 16px;
    }
    .anticon {
      color: #5c5c5c;
    }
  }
}
