.site-calendar-customize-header-wrapper-monthly {
  max-width: 436px;
  box-shadow: 0px 3px 24px rgba(0, 0, 0, 0.18);
  border-radius: 4px;
  background: #fff;
  position: relative;
  z-index: 90;
  left: 0px;
  margin-top: -25px;
  .calendar-date-example {
    display: flex;
    flex-direction: column;
    padding-top: 22px;
    padding-left: 16px;
    .ant-typography {
      font-style: normal;
      font-weight: normal !important;
      font-size: 12px !important;
      line-height: 16px !important;
      letter-spacing: 0.4px !important;
      color: #141414 !important;
      margin-bottom: 16px !important;
      cursor: pointer;
    }
  }
  .calendar-container {
    border-left: 1px solid#E0E0E0;
    .calendar-buttons {
      justify-content: flex-end;
      padding-bottom: 20px;
      .primary-button {
        width: unset !important;
        margin-right: 9px;
        text-transform: uppercase;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        height: unset !important;
        padding: 6px 17px !important;
        letter-spacing: 0.4px;
      }
      .tertiary-button {
        width: unset !important;
        margin-right: 9px;
        text-transform: uppercase;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        height: unset !important;
        padding: 6px 17px !important;
        letter-spacing: 0.4px;
      }
    }
  }
  .ant-picker-calendar {
    padding: 0 20px;
    font-family: "IBMPlex-Reg";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.15px;
    color: #141414 !important;
    .ant-picker-panel {
      border-top: unset;
    }
  }
  .ant-picker-content {
    th {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 11px;
      line-height: 13px;
      text-align: center;
      color: rgba(20, 20, 20, 0.38);
    }
    .ant-picker-cell {
      visibility: hidden;
    }
    .ant-picker-cell-in-view {
      visibility: visible;
      color: #141414;
    }
  }
  .ant-picker-cell-selected {
    background: #141414;
    border-radius: 50%;
    color: #fff !important;
  }
  .calendar-header {
    justify-content: space-between;
    align-items: center;
    padding: 13px;
    .ant-typography {
      font-style: normal;
      font-weight: normal !important;
      font-size: 16px !important;
      line-height: 24px !important;
      text-align: center !important;
      letter-spacing: 0.15px !important;
      color: #141414 !important;
      margin-bottom: unset !important;
    }
  }
}
@media only screen and (max-width: 900px) {
  .site-calendar-customize-header-wrapper {
    left: -10px;
    margin-top: 22px;
    .ant-row {
      flex-direction: column;
    }
    .calendar-date-example {
      flex-direction: row;
      padding-right: 16px;
      justify-content: space-between;
    }
    .calendar-header {
      flex-direction: row !important;
    }
    .calendar-buttons {
      flex-direction: row !important;
    }
  }
}
