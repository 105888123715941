.gallery-content {
  flex-direction: column;
}
.gallery-tag {
  font-style: normal;
  font-weight: bold !important;
  font-size: 21px !important;
  line-height: 24px !important;
  text-transform: uppercase;
  color: #000 !important;
  line-height: 12px !important;
  letter-spacing: 0.2px !important;
  margin-top: 40px !important;
  margin-bottom: 32px !important;
}

.modal-input {
  padding: 16px !important;
  color: 141414 !important;
  border-radius: 4px !important;
  border: 1px solid #e0e0e0 !important;
  .ant-typography {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #141414 !important;
  }
}

.create-survey-button {
  display: flex;
  justify-content: flex-end;
  .ant-btn {
    height: unset;
    padding: 8px 32px;
    text-transform: uppercase;
  }
  .primary-button {
    height: unset;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
  }
}
