.center-logo {
  text-align: -webkit-center;
  top: 0;
}

.info-forgot{
  margin-top: 40px;
  display: inline-grid;
}

.box{
  display: contents;
}
