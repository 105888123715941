.card {
  display: flex;
  width: 200px;
  height: 50px;
  border: 1px solid #fff;
  border-radius: 100% !important;
  margin-top: 20px;
  cursor: pointer !important;
  text-decoration: none !important;
  color: black !important;
}

.card-img {
  width: 20%;
  background: #ede7f9;
  text-align: center;
  padding: 10px;
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}

.card-main {
  width: 60%;
  background: #f5f2fc;
  padding: 5px 10px;
  font-size: 11px;
}

.card-download {
  width: 20%;
  background: #f5f2fc;
  padding: 10px;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}
