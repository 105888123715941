.resources-organism-container {
  .ant-typography {
    background-color: white !important;
  }

  .flag-action-layout {
    width: 100% !important;
    height: 100% !important;
  }

  .subtitle {
    font-family: "IBMPlex-Bold" !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 24px !important;
    line-height: 42px !important;
    /* identical to box height, or 175% */
    letter-spacing: 0.25px !important;
    /* primary / main */
    color: #141414;
  }

  .title {
    font-family: IBMPlex-Bold;
    font-size: 34px;
    font-weight: bold;
  }
}
