.location-card-wrapper {
  cursor: pointer;
  padding: 18px 29px;
  border-bottom: 1px solid #e0e0e0;
  .location-marker {
    margin-right: 29px;
  }
  .location-main-text {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    letter-spacing: 0.15px !important;
    color: #141414 !important;
  }
}
