.question-create-layout{
  background: #FAFAFA !important;
  .ant-layout{
  background: #FAFAFA !important;
  }
}
.question-container {
  margin-top: 34px;
  .ant-col {
    display: flex !important;
  }
  .input-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    .question {
      font-family: "IBMPlex-Reg" !important;
      font-style: normal;
      font-weight: normal;
      font-size: 20px !important;
      line-height: 32px;
      display: flex;
      align-items: center;
      letter-spacing: 0.15px !important;
    }
    .ant-form-item {
      margin-bottom: unset !important;
    }
  }
  .question-save {
    display: flex !important;
    justify-content: flex-end;
    padding-right: 24px;
    .primary-button {
      width: 88px !important;
      height: 40px !important;
      text-transform: uppercase !important;
      font-style: normal;
      font-family: "IBMPlex-Bold" !important;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.4px;
      margin-bottom: 30px;
    }
  }
  .icon {
    height: 24px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    padding: 9px 6px;
    color: #fff;
    margin-right: 14px;
    margin-left: 40px;
    margin-top: 8px !important;
    .anticon {
      margin-right: 8px;
    }
  }
  .check-icon {
    background-color: #da8be3;
    margin-right: 7px;
  }
  .multi-check-icon {
    background: #00b999;
  }
  .bool-icon {
    background: #64b6f7;
  }
  .text-icon {
    background: #a080e1;
  }
}
.question-actions {
  justify-content: flex-end;
  padding-right: 24px;
  align-items: center;
  margin-bottom: 20px;
  &:last-child{
    margin-right:10px;
  }
  .action-icons{
    margin-left:28px;
    cursor: pointer;
  }
}
