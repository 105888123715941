.general-settings-container {
  background: #fff;
  height: 100%;
  .map-location-language-container {
    padding: 30px 34px;
    padding-bottom: unset;
    display: flex;
    flex-direction: column;
    .general-settings-title {
      font-style: normal !important;
      font-weight: 600 !important;
      font-size: 24px !important;
      line-height: 32px !important;
      color: #141414 !important;
      text-transform: capitalize !important;
      margin-bottom: unset !important;
    }
    .language-select-wrapper {
      margin-left: 20px;
      display: flex;
      flex-direction: column;
      margin-top: 24px;
      .language-title {
        font-style: normal !important;
        font-weight: normal !important;
        font-size: 16px !important;
        line-height: 24px !important;
        letter-spacing: 0.15px !important;
        color: #141414 !important;
        text-transform: capitalize;
        margin-bottom: 16px !important;
      }
    }
    .ant-divider-horizontal {
      margin: 33px 0;
    }
    .general-settings-map-layout {
      background: unset;
    }
  }
}
.map-search-type-wrapper {
  display: flex;
  align-items: center;
  .ant-typography {
    margin-bottom: unset !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 24px !important;
    letter-spacing: 0.15px !important;
    color: rgba(20, 20, 20, 0.38) !important;
    cursor: pointer;
  }
  .active {
    color: #141414 !important;
  }
  .ant-divider-vertical {
    height: 1.2em;
    border: 1px solid #e0e0e0;
  }
}
.save-location {
  margin-bottom: 50px !important;
  .ant-divider-horizontal {
    margin-top: 44px;
  }
  .save-location-btn-wrapper {
    justify-content: flex-end;
    margin-right: 50px;
    .primary-button {
      box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.08),
        0px 6px 10px rgba(0, 0, 0, 0.05), 0px 1px 18px rgba(0, 0, 0, 0.03);
      width: unset;
      text-transform: uppercase;
      padding: 8px 26px;
      height: unset;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.4px;
    }
  }
}
@media only screen and (max-width: 900px) {
  .general-settings-container {
    .map-location-language-container {
      padding: 30px 17px;
    }
  }
}
