.color-selector {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.color-selector-left {
  width: 100%;
  display: inline-flex !important;
  justify-content: space-between;
  flex-wrap: wrap;
  overflow-x: auto;
  flex-wrap: wrap;
}
.color-selector-item {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.color-input {
  border-radius: 20px;
  border: none;
}
input[type="color"] {
  -webkit-appearance: none;
  border: none;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
input[type="color"]::-webkit-color-swatch-wrapper {
  border-radius: 50%;
  padding: 0;
  border: none;
}
input[type="color"]::-webkit-color-swatch {
  border-radius: 50%;
  border: none;
}
@media screen and (max-width: 500px) {
  .color-selector-left {
    justify-content: flex-start!important;
    margin: auto;
  }
  .color-selector-item {
    margin: 2.5px;
    width: 25px;
    height: 25px;
  }
  input[type="color"] {
    width: 25px;
    height: 25px;
  }
}