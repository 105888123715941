$darkText: #141414;
$lightText: #808080;

.kanban-lane-header-container {
  font-family: IBMPlex-Bold;
  font-size: 16px;
  text-transform: uppercase;
  color: $darkText;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: white;
  border: 1px solid rgba(185, 185, 185, 0.3);
  box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.08), 0px 1px 1px rgba(0, 0, 0, 0.05), 0px 1px 3px rgba(0, 0, 0, 0.03);
  border-radius: 4px;
}
