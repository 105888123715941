.knowledge-post-card-wrapper {
  padding: 24px 50px;
  background-color: #fff;
  border-bottom: 1px solid #e0e0e0;
  cursor: pointer;
  &:last-child {
    border-bottom: unset !important;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  &:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  .ant-typography {
    margin-bottom: unset !important;
  }
  .knowledge-post-text {
    display: flex;
    flex-direction: column;
    .post-title {
      font-style: normal !important;
      font-weight: 600 !important;
      font-size: 20px !important;
      line-height: 32px !important;
      letter-spacing: 0.15px !important;
      color: #141414 !important;
      margin-bottom: 8px !important;
    }
    .post-description {
      font-style: normal !important;
      font-weight: normal !important;
      font-size: 16px !important;
      line-height: 24px !important;
      letter-spacing: 0.15px !important;
      color: #5c5c5c !important;
    }
    .post-last-update {
      font-style: normal !important;
      font-weight: normal !important;
      font-size: 14px !important;
      line-height: 20px !important;
      letter-spacing: 0.15px !important;
      color: rgba(20, 20, 20, 0.54) !important;
      margin-top: 38px;
    }
  }
  .post-card-menu-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
  }
}
.knowledge-post-link {
  cursor: pointer;
}
