.client-onboarding-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 1em;
}
.header {
  text-align: center;
  position: relative;
  padding-top: 0.5em;
  padding-bottom: 1em;
}
.header svg {
  position: absolute;
  left: 1em;
  top: 0.6em;
  width: 1em;
  cursor: pointer;
}

.onboarding-header {
  margin: 0em auto;
  padding-top: 6em;
}

.onboarding-body {
  padding-top: 0.4em;
  padding-bottom: 5em;
  font-size: 1em;
  text-align: center;
  margin: 0em auto;
}

.onboarding-button {
  background-color: #003f4f;
  padding: 0.8em 7em;
  border-radius: 8px;
  margin: 0em auto;
  margin-top: 6em;
  cursor: pointer;
}

.onboarding-button h3 {
  margin: auto;
  color: white;
}

.onboarding-sub-link {
  color: #f38b00;
  text-decoration: underline;
  margin: 0em auto;
  padding-top: 2em;
  cursor: pointer;
}
