.media-cancel-btn {
    position: absolute;
    top: 8px;
    right: 8px;
    cursor: pointer;
    z-index: 10;
    font-size: 24px;
    color: white !important;
}
.modal-cancel-btn {
    position: absolute;
    top: -15px;
    right: -15px;
    cursor: pointer;
    font-size: 34px;
    color: black !important;
    opacity: 1 !important;
    filter: invert(100%) !important;
}

.media-container {
    display: inline-block;
    position: relative;
}

.media-image {
    object-fit: cover;
}
.small {
    max-width: 35px;
}

.media {
    margin-top: 2vh;
    .attached-image-container {
        text-align: center;
        display: inline-block;
        padding: 0.5vw;

        + .attached-image-container {
            margin-left: 0px;
        }

        img {
            border-radius: 6px;
        }
        .copyright {
            font-size: 0.7rem;
            text-transform: uppercase;
            color: rgba(20, 20, 20, 0.54);
            cursor: pointer;
        }
    }
}
