.survey-editor-actions {
  display: flex !important;
}
.survey-editor-header-container {
  margin-top: 15px;
  .title-column {
    display: flex !important;
    .title-icon {
      margin-left: 18px;
    }
  }
}
.survey-title {
  font-family: IBMPlex-Bold !important;
  font-size: 34px !important;
  font-weight: bold !important;
  color: #141414 !important;
  text-align: left !important;
}
.actions-container {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
  > * {
    margin-left: 8px;
  }
  .survey-header-icon {
    color: rgba(20, 20, 20, 0.68) !important;
    width: 14px;
    height: 14px;
    cursor: pointer;
  }
  .survey-header-editor-button {
    flex: 1 1;
    max-width: 172px;
    min-width: 150px;
    max-height: 40px;
    height: 100%;
    width: unset;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    text-transform: uppercase;
    color: #ffffff;
    margin-left: 16px;
  }
  @media only screen and (max-width: 900px) {
    flex-direction: column;
    justify-content: start;
    align-items: stretch;

    > *:not(:last-child) {
      margin-bottom: 8px;
    }

    > * {
      margin: 0;
    }
    .survey-header-button {
      max-width: unset;
      margin-left: unset;
    }
  }
}
.survey-delete-popup-text {
  width: 80%;
  display: flex;
}
.survey-delete-btn {
  display: flex;
  justify-content: flex-end;
  .primary-button {
    width: unset !important;
    text-transform: uppercase;
    background: #da3c5a !important;
    border-color: #da3c5a !important;
    font-style: normal;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 24px !important;
    height: unset !important;
    letter-spacing: 0.4px !important;
    padding: 8px 24px;
  }
}
