.ant-modal-content {
  border-radius: 5px !important;
}
.ant-modal-header {
  background: white !important;
  .ant-modal-title > h2 {
    font-weight: bold !important;
  }
}
.ant-modal-footer {
  button {
    font-weight: bold !important;
  }
}
.user-list-modal {
  margin-top: 35vh !important;
  .ant-modal-title {
    height: fit-content;
  }
  .ant-modal-title > h2 {
    margin-bottom: 0 !important;
  }
  .ant-modal-body {
    padding: 0 24px !important;
  }
}