.question-more-icons {
  .icon {
    width: 18px;
    height: 18px;
  }
  .ant-menu-item-active{
    background: #EBEBEB;
  }
  .ant-menu-item {
    display: flex;
    align-items: center;
    text-transform: capitalize;
    margin-top: 8px !important;
    padding-left: 19px !important;
    .anticon {
      margin-bottom: unset;
    }
    .ant-typography {
      margin-bottom: unset !important;
      font-style: normal;
      font-weight: normal !important;
      font-size: 16px !important;
      line-height: 24px !important;
      letter-spacing: 0.15px !important;
      color: #141414 !important;
    }
    .delete-text {
      color: #f44336 !important;
    }
  }
}
