.irys-simple-inputs-modal {
  .plain-input {
    height: 50px;
  }

  .ant-modal-content {
    .ant-form {
      .ant-form-item:last-child {
        margin-bottom: 0;
      }
    }

    border-radius: 8px;
    .ant-modal-header {
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
      background-color: #FAFAFA;

      .ant-modal-title {
        font-family: IBMPlex-Bold;
      }
    }

    .ant-modal-footer {
      padding: 16px 16px;

      .ant-btn-primary {
        border-radius: 4px;
      }
    }
  }
}
