.survey-result-info {
  margin-top: 28px;
}
.survey-result-cards-row {
  margin-top: 8px !important;
  .icon {
    display: flex !important;
    margin-right: unset !important;
  }
  .ant-row {
    .name {
      text-transform: capitalize;
    }
  }
}
