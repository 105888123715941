.communication-feed-container {
  padding-top: 16px;

  .no-message {
     margin-top: 100px;
      
    width: 100%;
      justify-content: center;
      

    .image {
       
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: center;
        
    }
    .resources-button {
      background-color: rgba(0, 63, 79, 1);
      width: 50%;
    }

    .title-textss {
      font-size: 18px !important;
      line-height: 21px !important;
      color: #a4a4a4 !important;
      margin-left: 30px;
      margin-right: 30px;
      margin-top: 60px;
      text-align: center !important;
      font-family: IBM Plex Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 23px;
      text-align: center;
    }
  }
}
