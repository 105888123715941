.ant-modal-header {
  background: #fafafa !important;
  border-radius: 10px 10px 0 0 !important;
}
.ant-modal-content {
  border-radius: 10px !important;
}
.survey-modal {
  border-radius: 10px;
  
}
.survey-modal {
  .ant-modal-title {
    font-weight: 600 !important;
    text-transform: uppercase;
  }
  .ant-typography {
    font-style: normal;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 24px !important;
    color: #141414 !important;
  }
  .ant-input {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 24px !important;
    color: #141414 !important;
    padding: 16px 12px !important;
    border-radius: 4px;
  }
}
