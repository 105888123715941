.events-welcome-container {
  display: flex;
  flex-direction: column;
  padding-bottom: 24px;
  .welcome-user {
    font-size: 20px !important;
    font-weight: bold !important;
    color: #141414 !important;
    line-height: 32px !important;
  }
  .welcome-message {
    font-size: 18px !important;
    color: #141414 !important;
    font-weight: normal !important;
  }
  .welcome-current-date {
    font-size: 14px !important;
    color: #141414 !important;
    font-weight: normal !important;
  }
}