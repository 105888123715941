.project {
  z-index: 3000 !important;
  //   position: relative !important;

  .image-container {
    border-radius: 10px !important;
    z-index: 5000 !important;
    background-color: white !important;
    height: 55px !important;
    width: 55px !important;
    align-items: center !important;
    vertical-align: middle !important;
    text-align: center !important;
    justify-content: center !important;
    display: flex !important;
    align-self: center;

    box-sizing: border-box;
    align-items: center;
    vertical-align: middle;
  }

  .image-container-inner {
    // border-radius: 12px !important;
    background-color: #e5e5e5 !important;
    z-index: 5000 !important;
    height: 48px !important;
    width: 48px !important;
    align-items: center !important;
    vertical-align: middle !important;
    text-align: center !important;
    justify-content: center !important;
    display: flex !important;
    align-self: center;

    box-sizing: border-box;
    align-items: center;
    vertical-align: middle;
    background-color: #f5f5f5;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1),
      inset 0px 0px 2px rgba(0, 0, 0, 0.15);
    // -webkit-box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.57);
    // box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.57);
    // box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);

    border-radius: 10px;
  }
  .image {
    z-index: 5000 !important;
    // height: 100% !important;
    // width: 100% !important;
  }
  .title-container {
    width: 100% !important;
    position: relative !important;
    height: 35px;
    margin-top: 20px;
    // background-color: red !important;
    // background-color: red !important;
    // display: flex !important;
    .mover-1 {
      animation: 5s linear 1s infinite both running moveSlideshow;
    }

    @keyframes moveSlideshow {
      100% {
        transform: translateX(-80%);
      }
    }
  }
  .title {
    // background-color: blue !important;
    width: 100% !important;
    //for annimation
    // width: 200% !important;
    // margin-left: 100px;
    height: 50%;
    display: inline-block;
    color: white !important;
    font-size: 16px !important;
    font-weight: 600 !important;
  }
  .subtitle {
    bottom: 0 !important;
    width: 100% !important;
    height: 50%;
    display: inline-block;
    color: #ffff !important;
    opacity: 0.5;
    font-size: 12px !important;
    font-weight: 400 !important;
  }
}
.project-list-container {
  position: relative !important;
  border-radius: 5px !important;
  margin-left: 35px !important;
  margin-top: -2px !important;
  color: black !important;
  width: 370px !important;
  min-height: 130px !important;
  max-height: 400px !important;
  //   height: 200px !important;
  background-color: white !important;
  box-shadow: 0px 3px 24px rgba(0, 0, 0, 0.18) !important;
  z-index: 1000000 !important;
}
.project-list-header {
  //   height: 200px !important;
  height: 70px !important;
  background: rgba(20, 20, 20, 0.04);
  border-color: black !important;
}
.project-search-bar {
  margin-left: 5% !important;
  margin-top: 6% !important;
  width: 90% !important;
  border-radius: 5px !important;
  height: 40px !important;
}
.projects-list-elements {
  z-index: 1000000;
  // background-color: red !important;
  min-height: 265px !important;
  max-height: 265px !important;
  overflow: scroll !important;
  .list {
    z-index: 1000000 !important;
    margin-left: 10px !important;
    margin-top: 10px !important;
    margin-bottom: 20px !important;
    .image-container {
      z-index: 5000 !important;
      height: 25px !important;
      width: 25px !important;
    }
    .image {
      z-index: 5000 !important;
      height: 100% !important;
      width: 100% !important;
      border-radius: 7px !important;
    }
    .title {
      width: 100% !important;
      height: 50%;
      display: inline-block;
      color: black !important;
      font-size: 15px !important;
      font-weight: 600 !important;
      white-space: nowrap !important;
      // overflow: hidden !important;
      text-overflow: ellipsis !important;
    }
    .subtitle {
      bottom: 0 !important;
      width: 100% !important;
      height: 50%;
      display: inline-block;
      color: black !important;
      opacity: 0.6;
      font-size: 15px !important;
    }
  }
}

.add-project-button {
  width: 50% !important;
  margin-left: 20px !important;
}
.list-divider {
  opacity: 0.4;
}
