.resources {
  .save-button {
    background-color: black !important;
    margin-top: 10px !important;
    border-radius: 5px !important;
    height: 80% !important;
    width: fit-content !important;
    padding: 6px 30px !important;
  }
  .ant-modal-title {
    font-weight: bolder !important;
  }
  .ant-modal-header {
    background-color: #fafafa !important;
  }
  .ant-modal-content,
  .ant-modal-content .ant-modal-header {
    border-radius: 10px 10px 10px 10px;
  }
}
