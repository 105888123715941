.logo-upload-title {
  font-weight: bold !important;
  margin-top: 20px;
}
.logo-upload-button {
  padding: 30px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: 100%;
}
.logo-upload-button-underlined {
  text-decoration: underline !important;
  margin-left: 5px;
}
.file-upload-image {
  margin-top: 20px;
}
.logo-upload {
  object-fit: cover !important;
  height: 65px !important;
  border-radius: 5px;
}
@media screen and (max-width: 500px) {
  .logo-upload {
    height: 180px !important;
  }
}
