.flag-form {
  .ant-form-item {
    margin-bottom: 12px;
  }
}

.save-btn {
  width: auto !important;
  padding: 0 16px;
}

.attach-image-btn {
  margin: 0 10px;
}

.attach-image-btn, .attach-image-btn:focus, .attach-image-btn:hover {
  border-color: transparent !important;
}

.attached-image-container {
  display: inline-block;

  + .attached-image-container {
    margin-left: 8px;
  }

  img {
    border-radius: 6px;
  }
}

.attached-images-container {
  overflow: scroll;
  display: flex;
}
