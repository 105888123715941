.news-card-container {
    border-radius: 20px;
    .news-card-wrapper {
        width: 300px;
        height: 176px;
    }
    .news-card {
        width: 288px;
        height: 176px;
        border-radius: 5px;
        padding-right: 15px;
    }
    .news-image-background {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 20px;
    }
    .overlap-content {
        position: relative;
        top: -200px;
        height: 100%;
    }
    .news-card-actions {
        display: flex;
        flex-direction: row;
        height: 35%;
        width: 100%;
    }
    .news-card-actions-cats {
        display: flex;
        flex-direction: row;
        height: 100%;
        width: 85%;
    }
    .news-card-actions-buttons {
        display: flex;
        flex-direction: row;
        height: 100%;
        width: 15%;
        align-items: center;
        justify-content: center;
    }
    .news-card-info {
        height: 30%;
        width: 100%;
    }
    .news-card-title{
        font-size: 13px;
        line-height: 18px;
        color: white;
        margin-left: 8px;
        margin-right: 8px;
        font-family: IBMPlex-SemiBold;
    }
    .post-info {
        display: flex;
        flex-direction: row;
        height: 30%;
        width: 100%;
    }
    .post-creator {
        display: flex;
        flex-direction: row;
        height: 100%;
        width: 70%;
        align-items: center;
    }
    .post-date {
        display: flex;
        flex-direction: row;
        height: 100%;
        width: 30%;
        align-items: center;
        justify-content: center;
    }
    .post-date-text {
        color: white !important;
        margin-left: 8px;
        margin-right: 8px;
        font-size: 13px !important;
        line-height: 18px !important;
        margin-bottom: 0 !important;
    }
}
