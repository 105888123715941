.header-container {
  width: 100%;
  display: flex;
  height: 40px;
  .header-actions {
    margin-top: 10px;
    height: 100%;
    width: 25%;
    .button {
      padding-left: 20px;
      padding-top: 10px;
    }
  }
  .header-title {
    display: flex;
    justify-content: center;
    height: 100%;
    width: 50%;
    .text {
      font-size: 16px !important;
      line-height: 16px !important;
      text-align: center !important;
      margin-top: 20px;
      color: #141414 !important;
    }
  }
  .header-actions-main {
    display: flex;
    height: 100%;
    width: 30%;
    margin-top: 10px;
    justify-content: flex-end;
  }
  .bell {
    margin-right: 12px;
  }
  .menu {
    cursor: pointer;
  }
  .message {
    width: 1.2em;
    margin-right: 10px;
  }
  .header-back {
    cursor: pointer;
  }
}
