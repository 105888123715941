.new-business-form-wrapper {
  background-color: #fff !important;
  .new-business-form {
    background-color: #fff !important;
    padding: 32px 50px;
    margin-bottom: 50px;
    .input-layout {
      .ant-typography {
        font-style: normal !important;
        font-weight: normal !important;
        font-size: 16px !important;
        line-height: 24px !important;
        letter-spacing: 0.15px !important;
        color: #141414 !important;
        margin-bottom: 8px !important;
      }
      .ant-input-affix-wrapper {
        border-radius: 4px !important;
        margin-bottom: 36px !important;
        .ant-input-suffix {
          .ant-typography {
            color: unset !important;
          }
          .false {
            color: rgba(20, 20, 20, 0.38) !important;
          }
        }
      }
    }
    .new-business-images {
      margin-bottom: 12px;
      .select-images-label {
        font-style: normal !important;
        font-weight: normal !important;
        font-size: 16px !important;
        line-height: 24px !important;
        letter-spacing: 0.15px !important;
        color: #141414 !important;
      }
      .drag-container {
        margin-top: 8px !important;
        .logo-upload-button {
          span {
            font-style: normal;
            font-weight: normal !important;
            font-size: 16px !important;
            line-height: 19px !important;
            letter-spacing: 0.15px !important;
            color: #5c5c5c !important;
          }
        }
      }
      .uploaded-images {
        margin-top: 16px !important;

        img {
          border-radius: 4px;
        }
      }
      .select-images {
        padding: 18px;
        border: 1px dashed rgba(20, 20, 20, 0.38);
        border-radius: 4px;
        display: flex;
        justify-content: center;
        .ant-typography {
          margin-bottom: unset !important;
          font-style: normal !important;
          font-weight: normal !important;
          font-size: 16px !important;
          line-height: 24px !important;
          letter-spacing: 0.15px !important;
          color: rgba(20, 20, 20, 0.38) !important;
        }
        .choose-file {
          text-decoration: underline;
        }
      }
    }
  }
  .ant-divider-horizontal{
    margin-bottom: unset !important;
  }
  .business-form-save-button {
    padding: 15px 50px 27px 50px;
    display: flex;
    justify-content: flex-end;
    .primary-button {
      padding: 8px 26px !important;
      height: unset !important;
      box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.08),
        0px 6px 10px rgba(0, 0, 0, 0.05), 0px 1px 18px rgba(0, 0, 0, 0.03);
    }
  }
}
