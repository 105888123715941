.atom-switch {
  height: 20px;
  width: 35px;
  align-items: center;
  cursor: pointer;
}
.switch-tail {
  background: lightgreen;
  height: 10px;
  width: 100%;
  border-radius: 5px;
  margin-bottom: -15px;
  transition: all 0.3s ease;
}
.switch-head {
  background: green;
  height: 100%;
  width: 20px;
  border-radius: 50%;
  transition: all 0.3s ease;
}
