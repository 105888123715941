.client-home-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-left: 16px;
  padding-right: 16px;
}
.home-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 24px;
  padding-top: 1em;
}
.home-header {
  display: flex;
  justify-content: flex-end !important;
}
.profile-photo {
  width: 3.5em;
  float: left;
  position: absolute;
  border-radius: 20%;
  margin-top: -0.8em;
}
.home-project {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}
.provide-input {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 10px;
}
.home-newsfeed-container {
  display: flex;
  flex-direction: column;
}
.news-title-text {
  color: #141414 !important;
  font-family: IBMPlex-SemiBold !important;
}

.title-text-launch {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 20px !important;
  line-height: 32px !important;
}
