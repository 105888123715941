.directory-list-header-text {
  font-style: normal;
  font-weight: 600 !important;
  font-size: 24px !important;
  line-height: 42px !important;
  letter-spacing: 0.25px !important;
  color: #141414 !important;
}
.directory-list-header {
  .directory-list-header-text {
    font-style: normal;
    font-weight: 500 !important;
    font-size: 12px !important;
    line-height: 31px !important;
    text-transform: uppercase;
  }
  .directory-list-header-title {
    padding: 0 0 15px 15px;
  }
  .directory-list-header-text-wrapper {
    text-align: center;
  }
}
.directory-list-container {
  display: flex;
  flex-direction: column;
  background: #fff;
  .pagination-container {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }
}
