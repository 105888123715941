.checkbox-group {
  > * {
    padding: 8px 16px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #E0E0E0;
  }
  > :first-child {
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
  }
  > :last-child {
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    border-left-width: 0;
  }
}
