$darkText: #141414;
$lightText: #808080;

.add-chart-item-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px 24px;
  border: 1px solid #E0E0E0;
  box-sizing: border-box;
  border-radius: 4px;
  height: 100%;
  .name {
    margin: 0 !important;
    font-size: 14px !important;
    color: $darkText !important;
    font-family: IBMPlex-Reg !important;
    text-align: center !important;
  }

  .chart-icon-img {
    user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
}
