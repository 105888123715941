.side-drawer {
  width: unset !important;
  .ant-drawer-content-wrapper {
    // left: unset !important;
    // max-width: 100% !important;
    min-width: 28vw;
    width: 100% !important;
    height: 90% !important;
    margin-top: 65px !important;

    @media only screen and (max-width: 400px) {
      width: 46vh !important;

      .ant-drawer-wrapper-body {
        width: 100% !important;
        overflow: scroll !important;
        height: 80vh !important;
      }
    }
  }
}
