.branding-layout {
  .title {
    font-family: IBMPlex-Bold;
    font-size: 34px;
    font-weight: bold;
    padding-bottom: 4%;
  }
}
.branding-layout {
  padding: 4% 24px 12% 24px;
  background-color: white !important;
  border-radius: 4px;
  box-shadow: 0px 2px 23px rgba(233, 233, 233, 0.4);
}
.branding-title {
  align-self: flex-start !important;
}
.branding-divider {
  margin: 0!important;
}
