.client-home-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-left: 16px;
  padding-right: 16px;
}
.home-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 24px;
}
.resources-title {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}

.mobile-header {
  text-align: center;
  position: relative;
  height: 70px;
}

.back-arrow {
  position: absolute;
  left: 5px;
  top: 25px;
}
.delete-button {
  position: absolute;
  right: 5px;
  top: 25px;
}
.archive-button {
  position: absolute;
  right: 50px;
  top: 25px;
}
.news-title-text {
  color: #141414 !important;

  font-family: IBMPlex-SemiBold !important;
}
.news-subtitle-text {
  color: #141414 !important;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 13px !important;
}

.resourcelist-title-container {
  padding-bottom: 1em;
}
