.single-response-wrapper {
  margin-top: 10px !important;
  .ant-col {
    padding-top: unset !important;
  }
  .survey-single-response-data {
    display: flex !important;
    flex-direction: column;
    padding: 50px !important;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0px 2px 23px rgba(233, 233, 233, 0.4);

    .survey-answer {
      // margin-bottom: 30px;
      .survey-question-title {
        font-style: normal;
        font-weight: 600 !important;
        font-size: 24px !important;
        line-height: 32px !important;
        color: #000000 !important;
        // margin-bottom: unset !important;
      }
      .text-answer {
        font-style: normal;
        font-weight: normal !important;
        font-size: 16px !important;
        line-height: 24px !important;
        letter-spacing: 0.15px !important;
        color: rgba(20, 20, 20, 0.54) !important;
      }
      .survey-question-answers {
        margin-top: 10px !important;
        margin-bottom: 30px !important;
      }
      &:nth-last-child(2) {
        margin-bottom: unset !important;
      }
    }
    .ant-divider {
      margin-top: 16px !important;
      border: 1px solid #5c5c5c;
      margin-bottom: 89px !important;
    }
  }
}
