.date-picker-container {
  .ant-drawer-content-wrapper {
    .ant-drawer-content {
      height: 100% !important;
      border-radius: 0 0 15px 15px ;
      .ant-drawer-wrapper-body {
        .ant-drawer-header {
          padding-top: 29px;
          border-bottom: 0 !important;
          display: flex;
          align-items: center;
          justify-content: center;
          padding-bottom: 25px;
          .ant-drawer-title {
            font-size: 14px !important;
            font-family: normal !important;
            color: #141414 !important;
            font-family: 'IBMPlex-Reg' !important;
          }
        }
        .ant-drawer-body {
          .primary-button {
            width: 100%;
            height: 50px;
          }
          .items-container {
            display: flex;
            flex-direction: column;
            .items-text {
              font-weight: normal !important;
              font-size: 16px !important;
              color: #141414 !important;
              padding-bottom: 29px;
              cursor: pointer;
            }
            .calendar-picker{
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              cursor: pointer;
              .items-text-picker {
                font-weight: normal !important;
                font-size: 16px !important;
                color: #A4A4A4 !important;
                margin-bottom: 0 !important;
              }
              
              .item-text-picker-selected {
                color: #00B999 !important;
              }
            }
          }
        }
      }
    }
  }
}