.user-list-layout {
  padding: 4% 24px 12% 24px;
  background-color: white !important;
  border-radius: 4px;
  box-shadow: 0px 2px 23px rgba(233, 233, 233, 0.4);
}
.danger-text {
  color: #f44336 !important;
}
.modal-title {
  text-align: left !important;
}
