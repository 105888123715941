.knowledge-header {
  .knowledge-header-text {
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 34px !important;
    line-height: 32px !important;
    color: #171725 !important;
  }
  .knowledge-header-button {
    display: flex;
    justify-content: flex-end;
    @media only screen and (max-width: 768px) {
      justify-content: flex-start;
      margin-top: 8px;
    }
    .primary-button {
      border-radius: 4px !important;
      font-size: 14px !important;
      height: unset !important;
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }
}
.toolbar-dropdown {
  .option-title {
    width: 100px;
  }
}
