$darkText: #141414;
$lightText: #808080;

.comment-item-container {
  display: flex;
  flex-direction: column;
  background-color: #fafafa;
  border-radius: 8px;
  align-items: stretch;
  padding: 20px 24px 28px 31px;
  .name {
    margin: 0 !important;
    font-size: 14px !important;
    color: $darkText !important;
    font-family: IBMPlex-Bold !important;
  }
  .date {
    font-size: 12px !important;
    color: $darkText !important;
    font-family: IBMPlex-Reg !important;
  }
  .subtitle {
    margin: 0 !important;
    margin-bottom: 4px !important;
    font-size: 12px !important;
    color: $darkText !important;
    font-family: IBMPlex-Reg !important;
  }
  .comment-reply-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: 12px;
    margin: 4px 0;
    color: $darkText;

    .reply-avatar {
      margin-right: 4px;
    }
  }
}

.comment-item-root {
  .profile-image {
    z-index: 3;
    margin-top: 8px;
    border: 3px white solid;
    margin-right: -18px;
  }
}
