.flag-places {
  #address-input {
    font-family: IBMPlex-Reg;
    color: rgb(92, 92, 92);
    font-size: 16px;
    font-weight: 300;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    margin-bottom: 10px;
    padding: 4px 11px;
    height: 48px;
  }
  .autocomplete-dropdown-container {
    position: absolute;
    z-index: 20;
    width: 100%;
    background: rgba(254, 254, 254, 1) !important;
    padding: 10px;
  }
  .suggestion-item {
    padding: 4px 20px;
    cursor: pointer;
  }
  .suggestion-item-active {
    padding: 4px 20px;
    cursor: pointer;
    background: rgb(245, 245, 245);
  }
}
