.icon-picker-container {
  margin-left: 40% !important;
  width: 220px;
  height: fit-content !important;
  background-color: #ffffff;
  z-index: 500000 !important;
  position: absolute;
  filter: drop-shadow(0px 3px 24px rgba(0, 0, 0, 0.18));
  padding: 5px;
  align-items: center !important;
  align-content: center;
  text-align: center;

  .icon-container-boder {
    padding: 8px;
    border: 1px solid #dddddd;
    .icon-picker-searchbar {
      max-width: 150px;
      min-height: 40px !important;
      height: 40px;
      margin-bottom: 10px;
      color: rgba(20, 20, 20, 0.54);
    }

    .icon-container-inner {
      display: flex;
      flex-wrap: wrap;
      .icon-item {
        padding: 1px;
        cursor: pointer;
        // border: solid 0.1mm !important;
        margin: 0.5mm !important;
        background: #ffffff;
        border: 1px solid #dddddd;
        box-sizing: border-box;
        border-radius: 4px;
        max-height: 35px;
        position: relative;
      }

      .icon-item:hover {
        background-color: #dddddd;
      }
    }
  }
}
