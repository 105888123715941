.directory-card-container {
  display: flex;
  flex-direction: column;
  .directory-item-card-wrapper {
    display: flex;
    flex-direction: column;
    background: #ffffff;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.08),
      0px 6px 10px rgba(0, 0, 0, 0.05), 0px 1px 18px rgba(0, 0, 0, 0.03);
    border-radius: 4px;
    .directory-card-item-content {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 33px 23px;
      align-items: center;
      .ant-typography {
        margin-bottom: unset !important;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 20px !important;
        line-height: 32px !important;
        letter-spacing: 0.15px !important;
        color: #141414 !important;
      }
    }
  }
  .directory-card-item-content-dropdown {
    width: 85%;
    z-index: 9;
    margin-top: -10px;
    margin-left: 9px;
    background: #ffffff;
    box-shadow: 0px 3px 24px rgba(0, 0, 0, 0.18);
    border-radius: 4px;
    position: absolute;
    bottom: -110px;
    padding: 16px 16px;
    display: flex;
    flex-direction: column;
    .dropdown-text {
      font-style: normal;
      font-weight: normal !important;
      font-size: 16px !important;
      line-height: 24px !important;
      letter-spacing: 0.15px !important;
      color: #141414 !important;
    }
    .delete-text {
      color: #f44336 !important;
    }
  }
}
