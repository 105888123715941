.options-container-sa {
  height: 123;
  min-width: 1em;
  margin-top: 10px;
  margin-bottom: 20px;
  display: flex;
  //justify-content: center;
  //align-self: center;
  margin: auto;
  overflow-y: hidden;
  scrollbar-width: none;
  flex-direction: row;
  width: 100%;
}
