.email-list-box {
  background-color: #fff;
  // padding: 15px 30px !important;
  max-height: fit-content !important;
  max-width: inherit;
  //   overflow-x: scroll;
  //   overflow-y: scroll;
  .ant-tabs-tab-btn {
    padding: 16px 40px !important;
  }
}

.ant-tabs-nav-wrap {
  padding: 0 25px;
}
