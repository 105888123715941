.client-layout {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  width: 100%;
  .client-container {
    position: relative;
    overflow-x: hidden;
    width: 380px;
    background: #fff !important;
    height: 100%;
    .ant-drawer-content-wrapper {
      height: 100% !important;
    }
    .event-date-picker {
      .ant-drawer-content-wrapper {
        height: 60% !important;
      }
    }

    @media (max-width: 767px) {
      width: 100%;
    }
    .ant-layout {
      background: #fff !important;
    }
  }
  .client-container::-webkit-scrollbar {
    display: none;
  }
}

.cancel .lock {
  margin-bottom: 30px;
}
