.survey-builder-container {
  background: #ffffff;
  box-shadow: 0px 2px 23px rgba(233, 233, 233, 0.4);
  border-radius: 4px;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  .question-error {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px 0;
    .primary-button {
      width: unset !important;
    }
  }
  .ant-layout-content {
    flex: unset;
  }
  .add-survey-action {
    padding: 40px 0 0 50px;
    margin-bottom: 162px;
    position: relative;
    width: unset;
    .add-survey-action-dropdown {
      margin-top: 16px;
      clip: unset;
    }
  }
  .question-row {
    padding: 10px;
  }
  .created-question {
    cursor: pointer;
    margin-bottom: 33px;
    margin-top: 54px;
  }
  .save-all-container {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    .save-all {
      display: flex;
      justify-content: flex-end;
      padding: 32px 50px 34px 0;
      .primary-button {
        width: 88px !important;
        height: 40px !important;
        text-transform: uppercase !important;
        font-style: normal;
        font-family: "IBMPlex-Bold" !important;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.4px;
      }
    }
  }
}
.line {
  border-color: black !important;

  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.08), 0px 6px 10px rgba(0, 0, 0, 0.05),
    0px 1px 18px rgba(0, 0, 0, 0.03) !important;
}
