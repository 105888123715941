.flags-placeholder-row {
  padding-top: 6%;
}

.tables-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.actions-container {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;

  > * {
    margin-left: 8px;
  }

  .flag-action-select {
    flex: 1;
    max-width: 200px;
    min-width: 150px;
    min-height: 50px;
    height: 100%;
  }


  .flag-action-search {
    flex: 1;
    max-width: 300px;
    min-width: 150px;
    min-height: 50px;
    height: 100%;
  }

  .flag-action-layout {
    width: auto;
  }

  @media only screen and (max-width: 900px) {
    flex-direction: column;
    justify-content: start;
    align-items: stretch;

    > *:not(:last-child) {
      margin-bottom: 8px;
    }

    > * {
      margin: 0;
    }

    .flag-action-select, .flag-action-search {
      max-width: unset;
    }
  }
}
