.user-list-switch {
  display: flex;
  flex-direction: row;
}
.select-space {
  margin-bottom: 22px !important;
}
.invite-sent {
  font-family: IBM Plex Sans;
  font-style: normal;

  line-height: 32px;
  letter-spacing: 0.15px;

  /* primary / light */

  color: #5c5c5c;
}
