.client-mobile-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.center-logo {
  text-align: -webkit-center;
  top: 0;
}

 
.mobile-component-container{
 
  .title {
    font-style: normal;
    font-weight: 600 !important;
    font-size: 34px !important;
    line-height: 42px !important;
    letter-spacing: 0.25px !important;
    color: #141414 !important;
    margin-bottom: unset !important;
  }
  .tagline {
    margin-top: 15px;
    font-style: normal;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 24px !important;
    letter-spacing: 0.15px !important;
    color: #141414 !important;
  }
}
.client-login-form {
  margin-bottom: 12px !important;
  .error-message {
    .ant-typography {
      font-style: normal;
      font-weight: normal !important;
      font-size: 12px !important;
      line-height: 16px !important;
      letter-spacing: 0.4px !important;
      color: #da3c5a !important;
    }
    width: 90% !important;
  }
}
 
 

 
.margin-twenty {
  margin-bottom: 20px !important;
}
 
 


.back-image{
  margin-top: 50px;
}

