.user-profile-header-text {
  text-align: left !important;
  align-self: flex-start !important;
}
.user-profile-avatar {
  align-self: center !important;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}
.center-text {
  text-align: center !important;
  align-self: center !important;
  margin: auto !important;
}
.profile-form-name {
  margin-bottom: 0 !important;
}
