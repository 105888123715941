.flag-details-root {
  > * {
    margin-bottom: 16px;
  }
}

.details-actions-container {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  > * {
    margin-left: 8px;
  }

  .flag-action-select {
    flex: 1;
    max-width: 200px;
    min-width: 150px;
  }


  .flag-action-search {
    flex: 1;
    max-width: 300px;
    min-width: 150px;
  }

  .flag-action-layout {
    width: auto;
  }

  .action-buttons {
    width: 90px;
    text-transform: uppercase;
    font-size: 12px;
  }
}
