.empty-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 92px;
}

@media only screen and (min-width: 600px) {
  .survey-container {
    .ant-layout-sider-zero-width-trigger {
      display: none;
    }
  }
}
@media only screen and (max-width: 728px) {
  .survey-container {
    .survey-sider {
      padding: unset !important;
    }
    .ant-layout-sider-zero-width-trigger {
      margin-left: -5px;
    }
  }
}
.survey-container {
  height: 100%;
  .survey-sider {
    box-shadow: 0px 2px 23px rgba(233, 233, 233, 0.4);
    border-radius: 4px;
    max-height: 70vh;
    position: sticky;
    top: 70px;
    @media only screen and (min-height: 1020px) {
      max-height: 65vh;
    }
    @media only screen and (min-height: 1360px) {
      max-height: 80vh;
    }
    .ant-layout-sider-children {
      display: flex;
      flex-direction: column;
    }
    .ant-menu-light sider-menu {
      width: 173px !important;
      max-width: 173px !important;
    }
    @media only screen and (max-width: 600px) {
      position: absolute !important;
      z-index: 300;
      top: unset;
    }
  }
  .survey-sider-tag {
    display: flex;
    width: 100%;
    height: 50px;
    justify-content: space-between;
    padding: 15px;
  }
  .survey-card-container {
    margin-left: 16px;
  }
  .survey-title {
    text-transform: capitalize;
    text-align: left;
    align-self: flex-start !important;
  }
}
.sider-bottom {
  width: 100%;
  height: auto;
  flex-grow: 1;
  display: flex;
  align-items: flex-end;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  .ant-collapse {
    background: #fff;
    border: 0;
    border-top: 1px solid #e0e0e0;
    .ant-collapse-item {
      border-bottom: unset;
    }
  }
}
