.charts-pane-drawer {
  .ant-drawer-body {
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    padding-bottom: 0;

    .items-container {
      overflow: scroll;
      flex: 1;
    }
  }

  .charts-pane-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .charts-header-left {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      .title {
        color: #141414;
        font-size: 18px;
        font-family: IBMPlex-Bold;
      }

      .subtitle {
        color: #5C5C5C;
        font-size: 16px;
      }
    }
    .charts-header-right {
      .back-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        border: 1px solid #E0E0E0;
        padding: 8px 10px;
        height: 40px;
        width: 40px;
      }
    }
  }

  .charts-filter-container {
    padding: 12px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    > *:not(:last-child) {
      margin: 0 12px;
    }

    .charts-filter-search {
      flex: 1;
      height: 100%;
      background-color: #F6F6F6;
      border-radius: 4px;
      .ant-input {
        background-color: inherit;
      }
      border: none;
      padding: 8px 16px;
    }

    .filter {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 8px 8px;
      height: 30px;
      width: 34px;
      cursor: pointer;
    }
  }
}
