.knowledge-search-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  .knowledge-tagline {
    margin-top: 8px;
    text-align: center;
    .ant-typography {
      font-style: normal;
      font-weight: 600 !important;
      font-size: 34px !important;
      line-height: 44px !important;
      letter-spacing: 0.15px !important;
      color: #171725 !important;
      text-align: center !important;
    }
  }
  .knowledge-search {
    width: 603px;
    display: flex;
    justify-content: center;
    margin-top: 32px;
    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  }
}
