.empty-card-container {
  background: #ffffff;
  box-shadow: 0px 2px 23px rgba(233, 233, 233, 0.4);
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding-top: 16px;
  flex-direction: column;
  padding: 32px 0;
  .placeholder-icon {
    margin-bottom: 52px;
    width: 30%;
  }
  .no-user-button {
    width: auto;
    text-transform: "uppercase";
    font-family: IBMPlex-Bold;
  }
  .no-user-text {
    width: 80%;
    text-align: center !important;
    margin-bottom: 37px !important;
    color: #5c5c5c !important;
  }
}
