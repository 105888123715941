.toggle-action-wrapper {
  display: flex;
  flex-direction: column;
  .ant-row {
    text-align: left !important;
    margin-bottom: 25px;
  }
  .ant-typography {
    font-style: normal;
    font-weight: normal !important;
    font-size: 20px !important;
    line-height: 32px !important;
    letter-spacing: 0.15px !important;
    color: #141414 !important;
    text-transform: capitalize;
  }
  .toggle-icon {
    display: flex;
    justify-content: flex-end;
    .ant-switch-checked {
      background-color: #00b999;
    }
  }
}
