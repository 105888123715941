.flags-menu-container {
  min-width: 250px;
  box-shadow: 0px 3px 24px rgba(0, 0, 0, 0.18) !important;
  border-radius: 4px;
  .ant-menu-item-icon {
    width: 20px;
  }
  .ant-menu-item {
    display: flex;
  }
}
