.fab-flex{
  display: flex;
  width: 100%;
  justify-content: flex-end;
  .base-container {
    display: flex;
    border-radius: 25px;
    align-items: center;
    justify-content: center;
    padding: 10px;
    padding-right: 18px;
    padding-left: 18px;
  }
  .container-normal {
    height: 48px;
    width: 50px;
  }
  .conatiner-large {
    height: 48px;
    width: 168px;
  }
  .background-open {
    background-color: #ffffff;
  }
  .background-close {
    background-color: #75FACF;
  }
  .child-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .provide-input-text {
    margin-left: 10px;
    color: #141414 !important;
    margin-bottom: 0 !important;
    font-size: 14px !important;
    align-self: center !important;
  }
}

