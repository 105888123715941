$lightText: #A6A6A6;

.kanban-add-placeholder-container {
  font-family: IBMPlex-Bold;
  font-size: 16px;
  text-transform: uppercase;
  color: $lightText;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  cursor: pointer;
  user-select: none;
  background: #FFFFFF;
  border: 1px solid rgba(185, 185, 185, 0.3);
  border-radius: 4px;
}
