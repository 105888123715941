.empty-placeholder-root {
  padding: 24px 32px;
  .ilustration {
    width: 170px !important;
    height: 170px !important;
    margin: 16px 0;
  }
  .ilustration-long {
    width: 240px !important;
    height: 200px !important;
    margin: 16px 0;
  }

  .primary-button {
    width: auto;
  }
}
