.irys-stat-card {
  height: 100%;
  border-radius: 4px !important;
  box-shadow: 0px 2px 23px rgba(233, 233, 233, 0.4);
  .value {
    font-family: IBMPlex-Bold;
    color: #141414;
    font-size: 30px;
  }

  .name {
    font-family: IBMPlex-Reg;
    color: #5c5c5c;
    font-size: 18px;
    padding: 8px 0;
  }

  .icon-container {
    background: #e6f8f5;
    mix-blend-mode: normal;
    border-radius: 10px;
    height: 58px;
    width: 58px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 18px;
  }
}
