$primaryColor: #4CCEB8;
$subtitle: #5C5C5C;

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.name {
  font-family: IBMPlex-Bold;
  font-size: 24px;
}

.subtitle {
  font-family: IBMPlex-Reg;
  font-size: 14px;
  color: $subtitle;
}

.upload-button {
  padding: 2px 24px !important;
  height: 36px !important;
  background-color: $primaryColor !important;
  border: unset !important;
  margin: 12px 0;
}
