.mobile-component-container {
  display: inline-grid;
}
.mobile-wrapper {
  padding-top: 57px;
  position: relative;
  z-index: 2;
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  justify-content: flex-start;
  padding-bottom: 45px;
  .primary-button {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
    height: unset !important;
    margin-bottom: 25px;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 15px !important;
    line-height: 26px !important;
    letter-spacing: 0.3px !important;
    text-transform: uppercase !important;
  }
}

.center-logo .icon-logo {
  width: min-content;
}

.icon-logo {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  .ant-typography {
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 15px !important;
    line-height: 19px !important;
    color: #141414 !important;
    text-align: center;
    margin-left: 4px;
    margin-bottom: unset !important;
  }
}

.mobile-title {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  .title {
    font-style: normal;
    font-weight: 600 !important;
    font-size: 34px !important;
    line-height: 42px !important;
    letter-spacing: 0.25px !important;
    color: #141414 !important;
    margin-bottom: unset !important;
  }

  .tagline {
    margin-top: 15px;
    font-style: normal;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 24px !important;
    letter-spacing: 0.15px !important;
    color: #141414 !important;
  }
}


.big-title {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold  !important;
  font-size: 34px;
  line-height: 42px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #141414;
  margin: 16px 0px;
}

.mobile-wrapper {
  .error-message {
    .ant-typography {
      font-style: normal;
      font-weight: normal !important;
      font-size: 12px !important;
      line-height: 16px !important;
      letter-spacing: 0.4px !important;
      color: #da3c5a !important;
    }
    width: 90% !important;
  }
}

.center{
  margin: auto;
}

.center-content{
  text-align: center !important;
}