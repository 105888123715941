.message-detail-container {
  .communication-date-text {
    color: #141414 !important;
    font-size: 12px !important;
    line-height: 16px !important;
    text-align: right !important;
    right: 8px;
    margin-top:5px;
    position:relative;
    top:5px;


}
  .client-message-title {
    font-size: 14px !important;
    color: #141414 !important;
    font-weight: bold !important;
    margin-top: 22px;
  }

  .communication-actions {
      width: 100%;
      height: 1px;
      background-color:#E0E0E0;
      margin-top: 26px;
  }
  .communication-image {
      width: 45px;
      height: 45px;

  }
  .communication-horizontal {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      margin-top:22px;

  }
  .communication-title {
      font-size: 14px !important;
      color: #141414 !important;
      font-weight: bold  !important;
      padding-left: 16px;
  }
  .communication-image-placeholder {
      height: 45px;
      width: 45px;
      margin-left: auto;
      margin-right: auto;
      border-radius: 10px;
      object-fit: cover;

  }
  .communication-body{
    font-size: 14px !important;
    line-height: 18px !important;
    color:rgba(20, 20, 20, 0.54) !important;
  }
  .comunication-child span {
    
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    width: 100%;
    margin: 16px auto;
  }
}
