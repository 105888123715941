.knowledge-category-card {
  padding: 40px 46px;
  background-color: #fff;
  border-radius: 4px;
  cursor: pointer;
  .knowledge-category-card-image {
    width: 64px;
    height: 64px;
  }
  .knowledge-category-card-text {
    display: flex;
    flex-direction: column;
    .knowledge-category-title {
      font-style: normal;
      font-weight: 500 !important;
      font-size: 20px !important;
      line-height: 32px !important;
      letter-spacing: 0.15px !important;
      color: #141414 !important;
    }
    .knowledge-category-description {
      font-style: normal !important;
      font-weight: normal !important;
      font-size: 16px !important;
      line-height: 24px !important;
      letter-spacing: 0.15px !important;
      color: #5c5c5c !important;
      margin-bottom: 16px !important;
    }
    .knowledge-category-number-posts {
      font-style: normal !important;
      font-weight: normal !important;
      font-size: 14px !important;
      letter-spacing: 0.15px !important;
      color: rgba(20, 20, 20, 0.54) !important;
    }
  }
}
