$IRYS_GRASS_60: #75FACF;
$IRYS_GRAPHITE_LIGHT: #14141455;
$IRYS_MILITAR_70: #2C7673;

.provide-input-container {
    position: fixed;
    bottom: 0;
}
.fab-container {
    margin-bottom: 20px;
    cursor: pointer;
}
.pi-modal {
    width: 380px;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 0%);
    .ant-drawer-content-wrapper {
        height: fit-content !important;
    }
    .ant-drawer-content {
        background-color: transparent;
    }
    .modal-content {
        border-top-right-radius: 15px;
        border-top-left-radius: 15px;
        padding-bottom: 30px !important;
        padding: 20px;
        background-color: $IRYS_GRASS_60;
        max-height: 550px;
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .pi-modal-header {
        color: $IRYS_GRAPHITE_LIGHT;
        text-align: right;
        margin: 5px;
        margin-bottom: 10px;
        text-align: right !important;
    }
    .pi-items-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-top: 7px;
        padding-bottom: 7px;
    }
    .pi-text-container {
        display: flex;
        flex: 1;
        justify-content: flex-end;
    }
    .pi-text {
        margin-right: 10px;
        color: $IRYS_MILITAR_70 !important;
    }
    .pi-text:hover{
        font-weight: 600 !important;
    }
    .pi-icon-conatiner {
        padding: 5px;
        padding-left: 15px;
    }
    .pi-fab-container {
        display: flex;
        align-self: flex-end;
        padding-top: 10px;
        cursor: pointer;
    }
}
@media screen and (max-width: 992px) {
    .pi-modal {
        width: 380px;
    }
}

@media screen and (max-width: 767px) {
    .pi-modal {
        width: 100%;
    }
}

@media screen and (max-width: 600px) {
    .pi-modal {
        width: 100vw;
    }
}