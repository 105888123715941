.client-mobile-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.center-logo {
  text-align: -webkit-center;
  top: 0;
}

 
.login-title {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  .title {
    font-style: normal;
    font-weight: 600 !important;
    font-size: 34px !important;
    line-height: 42px !important;
    letter-spacing: 0.25px !important;
    color: #141414 !important;
    margin-bottom: unset !important;
  }
  .tagline {
    margin-top: 15px;
    font-style: normal;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 24px !important;
    letter-spacing: 0.15px !important;
    color: #141414 !important;
  }
}
.client-login-form {
  margin-bottom: 12px !important;
  .error-message {
    .ant-typography {
      font-style: normal;
      font-weight: normal !important;
      font-size: 12px !important;
      line-height: 16px !important;
      letter-spacing: 0.4px !important;
      color: #da3c5a !important;
    }
    width: 90% !important;
  }
}
.login-input {
  font-size: 16px !important;
  margin-bottom: 12px;
  .float-label {
    margin-bottom: unset !important;
    .ant-input {
      &:hover {
        border: 2px solid #141414 !important;
      }
      &:focus {
        border: 2px solid #141414 !important;
        border-right-width: 1px !important;
        outline: 0;
        -webkit-box-shadow: unset !important;
        box-shadow: unset !important;
      }
    }
    .ant-input-affix-wrapper {
      .ant-input {
        &:hover {
          border: unset !important;
        }
        &:focus {
          border: unset !important;
        }
      }
      &:hover {
        border: 2px solid #141414;
      }
      &:focus {
        border: 2px solid #141414;
        border-right-width: 1px !important;
        outline: 0;
        -webkit-box-shadow: unset !important;
        box-shadow: unset !important;
      }
    }
    .ant-input-affix-wrapper-focused {
      border: 2px solid #141414;
      border-right-width: 1px !important;
      outline: 0;
      -webkit-box-shadow: unset !important;
      box-shadow: unset !important;
    }
    .form-item {
      border-radius: 4px;
      height: 100% !important;
      padding: 14px;
    }
    .label-float {
      font-style: normal;
      font-weight: normal;
      font-size: 12px !important;
      line-height: 16px;
      display: flex;
      align-items: center;
      letter-spacing: 0.4px;
      color: rgba(20, 20, 20, 0.5);
      margin-left: 6px;
    }
  }
  .ant-input {
    &::placeholder {
      font-style: normal;
      font-weight: normal !important;
      font-size: 16px !important;
      line-height: 28px !important;
      letter-spacing: 0.15px !important;
    }
  }
}

.error {
  .float-label {
    .ant-input {
      border: 1px solid #da3c5a !important;
    }
    .label-float {
      color: #da3c5a !important;
    }
  }
}
.focused {
  .float-label {
    .label-float {
      color: rgba(20, 20, 20, 0.5) !important;
    }
  }
}

.forgot-password {
  font-style: normal;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  color: #539f83 !important;
  cursor: pointer;
}
.margin-twenty {
  margin-bottom: 20px !important;
}
.new-user-sign-up {
  display: flex;
  justify-content: center;
  .ant-typography {
    font-style: normal;
    font-weight: normal !important;
    font-size: 14px !important;
    line-height: 24px !important;
    color: #141414 !important;
  }
}
.walkthrough-image {
  align-self: flex-end;
  width: 100%;
  display: flex;
  justify-content: center;
}


.back-image{
  margin-top: 50px;
}


.center-all-child span {
  text-align: center !important;
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  width: 80%;
  margin: 14px auto;
}

.welcome-tagline{
  font-family: Poppins;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 24px;
display: flex;
align-items: center;
text-align: center;
letter-spacing: 0.15px;
color: #141414;
flex: none;
order: 1;
flex-grow: 0;
margin: 16px 0px;
}


.down-button{
  display: inline-grid;
  position: absolute;
  bottom: 0;
  width: calc( 100% - 32px);
}
