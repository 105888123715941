.submission-user-info-root {
  padding: 24px 16px;
  background: #fff;
  box-shadow: 0px 2px 23px rgba(233, 233, 233, 0.4);
  border-radius: 4px;
  display: flex;
  flex-direction: column;

  .feature-image {
    border-radius: 8px;
  }

  .header-text {
    font-style: normal;
    font-weight: 600 !important;
    font-size: 20px !important;
    line-height: 32px !important;
    display: flex !important;
    align-items: center !important;
    letter-spacing: 0.15px !important;
    color: #141414 !important;
    text-transform: capitalize;
  }
  .header-input{
    margin-bottom: 18px;
  }

  .title-container {
    margin: 16px 0 !important;
    .title {
      font-size: 18px !important;
      color: black !important;
      text-align: start !important;
    }
  }

  .info-table {
    .name {
      font-size: 12px !important;
      font-family: IBMPlex-Bold !important;
      color: black !important;
      min-width: 100px;
    }
    .value {
      font-size: 12px !important;
    }
  }

  .description {
    font-size: 12px !important;
    line-height: 6px !important;
  }

  img {
    height: 300px;
    object-fit: cover;
  }
}
