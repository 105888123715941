.news-container {
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: row;
    .news-info {
        width: 70%;
        height: 100%;
    }
    .news-row {
        display: flex;
        flex-direction: row;
        padding-top: 8px;
    }
    .news-source-info {
        width: 90%;
        height: 100%;
        display: flex;
        flex-direction: row;
    }
    .news-title {
        font-size: 13px !important;
        line-height: 16px !important;
        color: #141414 !important;
    }
    .news-date-text {
        color: #14141455 !important;
        font-size: 12px !important;
        line-height: 16px !important;
    }
    .news-image {
        width: 30%;
        height: 100%;
        
    }
    .news-actions {
        width: 10%;
        height: 100%;
    }
    .news-image-placeholder {
        height: 80%;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        border-radius: 10px;
        object-fit: cover;
    }
}
