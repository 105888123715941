.irys-map {
  .gmnoprint {
    > * {
      height: auto !important;
    }
    > .gm-style-mtc {
      display: flex;
      flex-direction: row;
      justify-content: center;
      background-color: black !important;
      color: white !important;

      > button {
        background-color: transparent !important;
        color: white !important;
        font-size: 14px !important;
        padding: 8px 0 !important;
        height: auto !important;
        width: 80px !important;
      }
    }
    > .gm-style-mtc:not(:last-child) {
      border-right: 1px white solid !important;
    }
    > .gm-style-mtc:first-child {
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }
    > .gm-style-mtc:last-child {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }

    .gm-control-active {
      background-color: black !important;
      font-size: 14px !important;
      padding: 8px 0 !important;

      button {
        color: white !important;
      }
    }


    .gm-control-active:first-child {
      border-top-left-radius: 6px !important;
      border-top-right-radius: 6px !important;
    }
    .gm-control-active:last-child {
      border-bottom-left-radius: 6px !important;
      border-bottom-right-radius: 6px !important;
    }
  }
}
