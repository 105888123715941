
.color-input {
  border-radius: 20px;
  border: none;
  display: none;
}

.color-picker-pseudo{
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: none;
}