.question-settings-dropdown-wrapper {
  margin-top: 16px;
  .ant-select-selection-item {
    text-transform: capitalize;
  }
  .question-settings-dropdown-title {
    .ant-typography {
      font-style: normal;
      font-weight: normal !important;
      font-size: 20px !important;
      line-height: 32px !important;
      letter-spacing: 0.15px !important;
      color: #141414 !important;
    }
  }
  .settings-range {
    margin-top: 16px;
    align-items: center;
    .input {
      width: 83px;
      .ant-input {
        padding-top: 16px !important;
        padding-bottom: 16px !important;
        border-radius: 4px;
        color: #141414;
      }
      .ant-form-item {
        margin-bottom: unset;
      }
    }
    .ant-typography {
      margin: 0 16px !important;
      font-style: normal;
      font-weight: normal !important;
      font-size: 20px !important;
      line-height: 32px !important;
      letter-spacing: 0.15px !important;
      color: #141414 !important;
    }
  }
}
.settings-dropdown {
  text-transform: capitalize !important;
}
