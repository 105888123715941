.survey-setting-message-header {
  color: #141414 !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 24px !important;
  letter-spacing: 0.15px !important;
  text-transform: capitalize;
}
.survey-setting-message-content {
  padding: 12px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  .ant-typography {
    font-style: normal;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 24px !important;
    letter-spacing: 0.15px !important;
    color: #141414 !important;
    margin-bottom: unset !important;
  }
  .ant-tag {
    padding-top: 4px;
    padding-bottom: 4px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: #5c5c5c;
    border: 1px solid #5c5c5c;
    border-radius: 4px;
    .anticon {
      color: #5c5c5c;
    }
    .ant-tag-close-icon {
      margin-left: 34px;
    }
    .anticon-plus {
      margin-right: 4px;
    }
  }
  .survey-right-add-icon {
    color: rgba(20, 20, 20, 0.54);
    font-size: 20px;
  }
}
.message-text-area {
  display: flex;
  align-items: flex-start;
  flex-direction: column !important;
  resize: both;
  overflow: auto;
  .all-responses {
    margin: 16px 0;
  }
  .editable-text {
    font-family: Roboto;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 26px !important;

    /* or 162% */
    display: flex !important;
    align-items: center !important;
    letter-spacing: 0.15px !important;

    /* primary / light */
    color: #5c5c5c !important;
  }
}
