.flag-approve-modal {
  .ant-modal-body {
    padding: 8px 24px;
    font-size: 16px;

    .approve-input {
      height: 50px;
      width: 100%;
    }

    .approve-modal-category-name {
      margin-bottom: 16px;
    }

    .approve-row-container {
      > * {
        margin-bottom: 8px;
      }

      .ant-form-item {
        margin-bottom: unset;
      }
    }
  }
}
