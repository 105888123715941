.search-bar {
  flex: 1;
  max-width: 100%;
  min-width: 150px;
  height: 50px;
  // height: 100%;
}
.soft-icon {
  color: #141414 !important;
}
