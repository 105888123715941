.app-layout {
    overflow: hidden !important;

    ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 8px;
    }
    ::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0.5);
        box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }
}

.app-layout-content {
    position: relative;
    height: 100% !important;

    overflow: hidden !important;
    // margin-top: 30px !important;
    // background-color: red !important;
    // overflow: auto !important;

    // ::-webkit-scrollbar {
    //   -webkit-appearance: none;
    //   width: 8px;
    // }
    // ::-webkit-scrollbar-thumb {
    //   border-radius: 4px;
    //   background-color: rgba(0, 0, 0, 0.5);
    //   box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    // }
}

.app-container {
    // background-color: red !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
    // margin: 0 %;
    // margin-top: 20px;
    padding: 2% 14%;
    // background-color: red !important;
    flex: 1;

    .app-content-child {
        position: relative;
        height: 90%;
    }

    @media only screen and (max-width: 700px) {
        margin: 0 7%;
        padding: 16% 0;
    }
}

.ant-layout-header {
    position: fixed;
    right: 0;
    left: 0;
    top: 0;
    z-index: 10;
}
.signup-illustration {
    width: 450px !important;
    height: 310px !important;
    margin-left: 80px;
    margin-top: 70px;
}
.signup-title {
    font-weight: bold;
}
