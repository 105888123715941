.reportfeed-container {
    display: flex;
    flex-direction: column;
    .no-flags {
        display: flex;
        flex-direction: column;
        height: 300px;
        .image {
            height: 150px;
            width: 150px;
            margin-left: 'auto';
            margin-right: 'auto';
            margin-top: 25px;
            align-self: center;

        }
        .title-text {
            font-size: 18px !important;
            line-height: 21px !important;
            color: #A4A4A4 !important;
            margin-left: 15px;
            margin-right: 15px;
            margin-top: 30px;
            text-align: center !important;
        }

    }
}
