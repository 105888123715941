.navbar-search-box {
  width: 30%;
  @media only screen and (max-width: 550px) {
    width: 0;
    display: none !important;
  }
  position: absolute !important;
  // left: 11% !important;
  .ant-input-affix-wrapper {
    background-color: #f5f5f5 !important;
    padding: 10px 24px;
    border-radius: 4px;
    .ant-input-prefix {
      margin-right: 14px;
      .anticon {
        color: rgba(20, 20, 20, 0.54);
      }
    }
    .ant-input {
      font-style: normal !important;
      font-weight: normal !important;
      font-size: 1.1428571428571428em !important;
      line-height: 24px !important;
      letter-spacing: 0.15px !important;
      color: #141414;
      background-color: #f5f5f5 !important;
    }
  }
  .search-results {
    margin-top: 4px;
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 100%;
    box-shadow: 0px 3px 24px rgba(0, 0, 0, 0.18);
    border-radius: 4px;
    z-index: 12;

    .search-result-card {
      z-index: 10;
      cursor: pointer !important;
      background-color: #fff;
      padding: 16px 24px;
      border-bottom: 1px solid #e0e0e0;
      display: flex;
      align-items: center;
      .ant-typography {
        margin-bottom: unset !important;
      }
      .search-title {
        font-style: normal !important;
        font-weight: normal !important;
        font-size: 1em !important;
        line-height: 24px !important;
        letter-spacing: 0.15px !important;
        margin-right: 16px;
        margin-bottom: unset !important;
        color: #5c5c5c !important;
        .text-bold {
          color: #141414 !important;
        }
      }
      .search-title-highlighted {
        font-style: normal !important;
        font-weight: normal !important;
        font-size: 1em !important;
        // line-height: 24px !important;
        // letter-spacing: 0.1px !important;
        // margin-right: 16px;
        margin-bottom: unset !important;
        font-weight: bold !important;
      }
      .search-category {
        font-style: normal !important;
        font-weight: 500 !important;
        font-size: 0.625em !important;
        line-height: 24px !important;
        text-align: right !important;
        text-transform: uppercase !important;
        color: rgba(20, 20, 20, 0.38) !important;
      }
      &:last-child {
        border-bottom: unset !important;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
      }
      &:first-child {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }
      &:hover {
        background-color: #d3d2d2 !important;
      }
    }
    .search-no-result-card {
      z-index: 10;
      background-color: #fff;
      padding: 16px 24px;
      border-bottom: 1px solid #e0e0e0;
      display: flex;
      align-items: center;
      .ant-typography {
        margin-bottom: unset !important;
      }
      .search-title {
        font-style: normal !important;
        font-weight: normal !important;
        font-size: 1em !important;
        line-height: 24px !important;
        letter-spacing: 0.15px !important;
        margin-right: 16px;
        margin-bottom: unset !important;
        color: #5c5c5c !important;
        .text-bold {
          color: #141414 !important;
        }
      }
      .search-category {
        font-style: normal !important;
        font-weight: 500 !important;
        font-size: 0.625em !important;
        line-height: 24px !important;
        text-align: right !important;
        text-transform: uppercase !important;
        color: rgba(20, 20, 20, 0.38) !important;
      }
      &:last-child {
        border-bottom: unset !important;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
      }
      &:first-child {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }
    }
  }
}
