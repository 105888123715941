.new-business-header-wrapper {
  .ant-row {
    margin-bottom: 16px !important;
  }
  .business-header-divider {
    margin-top: unset !important;
  }
  .title-column {
    display: flex !important;
    .title-icon {
      margin-left: 18px;
    }
    .survey-title {
      font-family: IBMPlex-Bold !important;
      font-size: 34px !important;
      font-weight: bold !important;
      color: #141414 !important;
      text-align: left !important;
      margin-bottom: unset !important;
    }
  }
  .actions-container {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    > * {
      margin-left: 8px;
    }
    .survey-header-icon {
      color: rgba(20, 20, 20, 0.68) !important;
      width: 14px;
      height: 14px;
      cursor: pointer;
    }
    .survey-header-editor-button {
      flex: 1 1;
      max-width: 114px;
      min-width: unset !important;
      max-height: 40px;
      padding: 8px 0 8px 0;
      width: unset;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      text-transform: uppercase;
      color: #ffffff;
      margin-left: 16px;
    }
    @media only screen and (max-width: 900px) {
      flex-direction: column;
      justify-content: start;
      align-items: stretch;

      > *:not(:last-child) {
        margin-bottom: 8px;
      }

      > * {
        margin: 0;
      }
      .survey-header-button {
        max-width: unset;
        margin-left: unset;
      }
    }
  }
}
