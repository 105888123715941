.survey-list-header-text {
  font-style: normal;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 31px !important;
  text-transform: uppercase;
}
.survey-list-header-title {
  padding: 0 0 15px 15px;
}
.survey-list-header-text-wrapper {
  text-align: center;
}
