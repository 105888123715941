.survey-response-body-wrapper {
  margin-top: 24px;
  .survey-response-body-header {
    padding: 6px 0px;
    background: #fafafa;
    border: 1px solid rgba(185, 185, 185, 0.3);
    border-radius: 10px;
    .ant-row{
      align-items: center;
    }
    .response-email-header {
      padding-left: 60px;
    }
    .ant-typography {
      font-style: normal;
      font-weight: 500 !important;
      font-size: 12px !important;
      line-height: 31px !important;
      letter-spacing: 1px !important;
      text-transform: uppercase !important;
      color: #5c5c5c !important;
    }
    .text-center{
        text-align: center;
    }
  }
}
@media only screen and (max-width: 720px) {
  .response-email-header {
      padding-left: 10px !important;
    }
}
