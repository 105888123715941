.option-menu {
  font-family: "IBMPlex-Reg";
  font-weight: 400;
  font-family: "IBMPlex-Reg";
  font-weight: 400;
  &:hover {
    background: rgba(0, 0, 0, 0.04);
    font-family: "IBMPlex-Reg";
    font-weight: 400;
    color: #000000;
    background: rgba(0, 0, 0, 0.04);
    font-family: "IBMPlex-Reg";
    font-weight: 400;
    color: #000000;
  }
}

.option-items {
  margin-left: 16px;
  margin-top: 4px;
  margin-bottom: 4px;
  margin-left: 16px;
  margin-top: 4px;
  margin-bottom: 4px;
  cursor: pointer;
  padding-top: 6px;
  padding-bottom: 6px;
}

.option-items-selected {
  background: #e6f7ff;
  opacity: 60%;
  .option-items {
    .ant-typography {
      color: #5eaaa8 !important;
    }
  }
}

.popover-items {
  overflow: scroll;
  scrollbar-width: none;
  max-height: 250px;
  overflow: scroll;
  scrollbar-width: none;
}
.selected-container-cat {
  padding-right: 20px;
  display: flex;
  flex-wrap: wrap;
  min-height: fit-content;
  // max-height: 95px;
  // overflow: hidden;
  // &::after {
  //   div {
  //     &:nth-child(n + 4) {
  //       content: "More..";
  //     }
  //   }
  // }
}

.popup-avatar {
  font-family: "IBMPlex-Reg";
  font-weight: 400;
  font-size: 16px;
  font-family: "IBMPlex-Reg";
  font-weight: 400;
  font-size: 16px;
}
.users-tab-container {
  display: flex;
  align-items: center;
  .anticon {
    font-size: 16px;
  }
}
// .ant-select-selector {
//   border: none !important;
// }

.selected-container-cat {
  // height: 1000px !important;
  min-height: fit-content;
  .selected-items {
    height: 24px;
    align-items: center;
    .selected-item-text {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.4px;
      color: #141414;
      margin: 6px 0px;
    }
    .selected-items-wrapper {
      margin-left: 8px;
      .cross-icon {
        cursor: pointer;
        .anticon {
          font-size: 8.17px;
          margin: 6px 0px;
        }
      }
    }
  }
}

.overflow-text {
  cursor: pointer;
  font-family: "IBMPlex-Reg";
  font-weight: 400;
  font-size: 12px;
}

.select-when-empty-filler {
  display: flex;
}
.select-when-empty-filler-items {
  margin-right: 5px;
  cursor: pointer;
  .ant-typography {
    color: rgba(20, 20, 20, 0.54) !important;
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.15px;
  }
}

.popover-search-container {
  padding-bottom: 12px;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 8px;
}

.department-list-searchbar {
  min-height: 40px !important;
  height: 40px;
  color: rgba(20, 20, 20, 0.54);
}

.popover-list-container {
  max-height: 250px;
  overflow: scroll;
  scrollbar-width: none;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 8px;
}

.popover-menu-container {
  min-height: 215px;
}

.overflow-wrapper {
  min-height: fit-content !important;
  display: flex !important;
  align-items: flex-start !important;
}

.custom-popover-style {
  background-color: white;
  width: 266px;
  border-radius: 4px;
  max-height: 468px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.12);
}
