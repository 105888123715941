.flag-form {
  .ant-form-item {
    margin-bottom: 12px;
  }
}

.save-btn {
  width: auto !important;
  padding: 0 16px;
}

.attach-image-btn {
  margin: 0 10px;
}

.attach-image-btn,
.attach-image-btn:focus,
.attach-image-btn:hover {
  border-color: transparent !important;
}

.attached-image-container {
  display: inline-block;

  + .attached-image-container {
    margin-left: 8px;
  }

  img {
    border-radius: 6px;
  }
}

.attached-images-container {
  overflow: scroll;
  display: flex;
}

.overview-title {
  // font-family: IBM Plex Sans;
  margin-left: 5px !important;
  margin-bottom: 25px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 20px !important;
  line-height: 32px !important;
  /* identical to box height, or 160% */

  display: flex !important;
  align-items: center !important;
  letter-spacing: 0.15px !important;

  /* primary / main */

  color: #141414 !important;
}
.status-title {
  // font-family: IBM Plex Sans;
  margin-left: 5px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 32px !important;
  /* identical to box height, or 160% */

  display: flex !important;
  align-items: center !important;
  letter-spacing: 0.15px !important;

  /* primary / main */

  color: #141414 !important;
}
