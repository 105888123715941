.editor-toolbar {
  display: flex;
  margin: 10px 0;
  justify-content: space-between;
  align-items: center;
}
.editor-toolbar > span {
  cursor: pointer;
  padding: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.editor-button {
  padding: 3px;
}
.editor-toolbar > span {
  padding: 3px;
}
.editor-toolbar > span:hover,
.editor-button-active {
  background-color: #eee;
  border-radius: 2px;
  padding: 3px;
}

.RichEditor-root {
  background: #fff;
  border: 1px solid #ddd;
  font-family: "Georgia", serif;
  font-size: 14px;
  padding: 15px;
}
.header-one {
  font-size: 40px !important;
}

.RichEditor-editor {
  border-top: 1px solid #ddd;
  cursor: text;
  font-size: 16px;
  margin-top: 10px;
}

.RichEditor-editor .public-DraftEditorPlaceholder-root,
.RichEditor-editor .public-DraftEditor-content {
  margin: 0 -15px -15px;
  padding: 15px;
}
.editing .RichEditor-editor {
  height: 35vh;
  overflow-y: scroll;
}

.RichEditor-editor .public-DraftEditor-content {
  min-height: 100px;
}

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none;
}

.RichEditor-editor .RichEditor-blockquote {
  border-left: 5px solid #eee;
  color: #666;
  font-family: "Hoefler Text", "Georgia", serif;
  font-style: italic;
  margin: 16px 0;
  padding: 10px 20px;
}

.RichEditor-editor .public-DraftStyleDefault-pre {
  background-color: rgba(0, 0, 0, 0.05);
  font-family: "Inconsolata", "Menlo", "Consolas", monospace;
  font-size: 16px;
  padding: 20px;
}

.RichEditor-controls {
  font-family: "Helvetica", sans-serif;
  font-size: 14px;
  margin-bottom: 5px;
  user-select: none;
}

.RichEditor-styleButton {
  color: #999;
  cursor: pointer;
  margin-right: 16px;
  padding: 2px 0;
  display: inline-block;
}

.RichEditor-activeButton {
  color: #5890ff;
}
.justify-styles-right .public-DraftStyleDefault-block,
.justify-styles-right > * {
  text-align: right !important;
}
.justify-styles-left .public-DraftStyleDefault-block,
.justify-styles-left > * {
  text-align: left !important;
}
.justify-styles-center .public-DraftStyleDefault-block,
.justify-styles-center > * {
  text-align: center !important;
}
.justify-styles-justify .public-DraftStyleDefault-block,
.justify-styles-justify > * {
  text-align: justify !important;
  text-align-last: justify !important;
}
.justify-styles-justify-right .public-DraftStyleDefault-block,
.justify-styles-justify-right > * {
  text-align: justify !important;
  text-align-last: right;
}
.justify-styles-justify-left .public-DraftStyleDefault-block,
.justify-styles-justify-left > * {
  text-align: justify !important;
  text-align-last: left;
}
.justify-styles-justify-center .public-DraftStyleDefault-block,
.justify-styles-justify-center > * {
  text-align: justify !important;
  text-align-last: center;
}
.public-DraftEditorPlaceholder-root {
  text-align-last: left !important;
}
.horizontal-spacing-0 {
  letter-spacing: 0;
}
.horizontal-spacing-1 {
  letter-spacing: 1px !important;
}
.horizontal-spacing-2 {
  letter-spacing: 2px !important;
}
.horizontal-spacing-3 {
  letter-spacing: 3px !important;
}
.horizontal-spacing-4 {
  letter-spacing: 4px !important;
}
.color-option {
  width: 20px;
  height: 20px;
  &.black {
    background-color: black;
  }
  &.red {
    background-color: red;
  }
  &.blue {
    background-color: blue;
  }
  &.green {
    background-color: green;
  }
  &.yellow {
    background-color: yellow;
  }
}
.toolbar-dropdown {
  background: "red" !important;
}
.dropdown-input {
  width: 100%;
}
.toolbar-dropdown-container {
  position: relative;
  display: inline-block;
}
.toolbar-dropdown-container-active {
  z-index: 20 !important;
}
.toolbar-dropdown-content {
  position: absolute;
  background: white;
  width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: -20;
  transition: 0.2s all ease;
  padding: 2px;
}
.toolbar-dropdown-children {
  cursor: pointer;
  width: fit-content;
  height: fit-content;
  z-index: 3;
  padding: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.toolbar-dropdown-children:hover {
  background: #eee;
}
.toolbar-dropdown-children > * {
  z-index: inherit;
}
.add-url-form {
  width: 100%;
}
.add-url-form > input {
  width: 100%;
  padding: 5px;
}
.draftImg {
  max-width: 60%;
  background-position: center;
  background-repeat: no-repeat;
}
.editor-link {
  color: #5890ff;
  text-decoration: underline;
}
.upper-divider {
  margin: 0 !important;
}
