.client-surveys {
  .header {
    text-align: center;
    position: relative;
  }

  .header svg {
    position: absolute;
    left: 0;
    top: 3px;
  }

  .survey-title {
    font-family: IBMPlex-Reg !important;
    color: rgb(92, 92, 92) !important;
    font-size: 16px !important;
    font-weight: 300 !important;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
  }

  .survey-content-title {
    font-family: IBMPlex-Reg !important;
    font-style: normal;
    font-weight: 600 !important;
    font-size: 14px;
    line-height: 18px;
    color: #141414;
    text-align: left;
    float: left;
    margin-bottom: 20px;
  }

  .survey-content {
    padding-top: 25px;
  }

  .no-survey {
    display: inline-grid;
    width: -webkit-fill-available;
    padding-top: 30%;
    text-align: center;
  }

  .no-survey-title {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    font-family: IBMPlex-Reg !important;
    line-height: 23px;
    text-align: center;
    color: #a4a4a4;
  }

  .survey-question {
    color: #141414;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 18px 0px;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
  }
  .survey-placeholder {
    color: #141414;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin-top: 30px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 32px;
  }

  .header svg {
    width: 16px;
  }

  .survey-question .ant-radio-group {
    margin-top: 30px;
  }

  .survey-question span.ant-radio + * {
    padding-left: 18x;
  }

  .survey-question-header {
    font-family: IBMPlex-Reg !important;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #676767;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 21px 0px;
  }

  .ant-modal-root {
    position: absolute;
    height: 100vh;
    width: 100%;
    top: 0;
    left: 0;
  }

  .ant-modal-mask {
    position: absolute;
  }

  .ant-modal-wrap {
    position: relative;
    height: 100%;
  }

  .ant-modal-centered .ant-modal {
    width: auto !important;
    margin: 10px;
  }

  .modal-custom .ant-modal-root {
    display: none;
  }

  .survey-button button {
    width: 43%;
    margin: 3%;
  }

  .survey-button {
    position: absolute;
    bottom: 10px;
    width: 100%;
    left: 0;
    padding: 16px;
  }

  .survey-end {
    text-align: center;
  }

  .survey-end-text {
    margin-top: 41px;
  }

  .survey-end-header {
    font-family: "IBMPlex-Reg";
    font-style: normal;
    font-weight: 600 !important;
    font-size: 18px !important;
    line-height: 23px !important;
    text-align: center;
    color: #141414 !important;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 15px 0px;
  }

  .survey-end-text span {
    text-align: -webkit-center;
    display: block;
  }

  .survey-end-mid-text {
    font-family: "IBMPlex-Reg";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.15px;
    color: #141414;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 15px 0px;
  }

  .survey-end-footer {
    font-family: "IBMPlex-Reg";
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.4px;
    color: #676767;
    order: 2;
    flex-grow: 0;
    margin: 15px 0px;
  }

  .survey-social {
    display: flex;
    margin-top: 10px;
  }

  .survey-social svg {
    margin: auto;
  }

  .client-survey-cards-holder {
  }

  .ant-progress-show-info .ant-progress-outer {
    margin-right: 0;
    padding-right: 0;
  }
}
