.survey-responses-wrapper {
  background: #fff;
  padding: 30px;
  box-shadow: 0px 2px 23px rgba(233, 233, 233, 0.4);
  border-radius: 4px;
  margin-top: 15px;
  .response-header {
    align-items: center;
    .response-header-text {
      font-style: normal;
      font-weight: 600 !important;
      font-size: 24px !important;
      line-height: 32px !important;
      display: flex !important;
      align-items: center !important;
      color: #171725 !important;
      text-transform: capitalize;
      margin-bottom: unset !important;
    }
    .response-header-filter {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .calendar-filter-wrapper {
        .anticon {
          width: 18px;
        }
      }
      .calendar-filter {
        position: absolute;
        z-index: 9;
        left: -328px;
        margin-top: 14px;
      }
      .ant-input-affix-wrapper {
        max-width: 383px;
        margin-left: 25px;
        // background: rgba(0, 0, 0, 0.04);
        border-radius: 4px;
        // padding: 16px 14px;
        // border: unset;
        .anticon {
          color: rgba(20, 20, 20, 0.54);
        }
        input {
          background: transparent;
        }
      }
      .anticon {
        cursor: pointer;
      }
    }
  }
  .survey-responses-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
    margin-bottom: 5px;
    .ant-pagination {
      margin-left: 24px;
    }
    .ant-pagination-item-ellipsis {
      color: #141414 !important;
      padding-top: 3px;
    }
    .ant-pagination-prev,
    .ant-pagination-next {
      margin-right: 15px !important;
    }
    .ant-pagination-item {
      margin-right: 15px !important;
      a {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        letter-spacing: 0.15px;
        height: 100%;
      }
    }
    .paginated-button-inactive {
      color: rgba(20, 20, 20, 0.38);
      cursor: not-allowed;
    }
    .paginated-button-active {
      cursor: pointer;
    }
    .ant-pagination-item-active {
      font-weight: 500;
      background: #141414;
      border-color: unset;
      border-radius: 50%;
      width: 26px;
      height: 26px;

      a {
        color: #fff;
      }
    }
  }
}
@media only screen and (max-width: 720px) {
  .survey-responses-wrapper {
    .survey-responses-pagination {
      .ant-pagination-item {
        margin-right: unset !important;
      }
    }
  }
}
