  .pagination-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
    margin-bottom: 5px;
    .ant-pagination {
      margin-left: 24px;
    }
    .ant-pagination-item-ellipsis {
      color: #141414 !important;
      padding-top: 3px;
    }
    .ant-pagination-prev,
    .ant-pagination-next {
      margin-right: 15px !important;
    }
    .ant-pagination-item {
      margin-right: 15px !important;
      a {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        letter-spacing: 0.15px;
        height: 100%;
      }
    }
    .paginated-button-inactive {
      color: rgba(20, 20, 20, 0.38);
      cursor: not-allowed;
    }
    .paginated-button-active {
      cursor: pointer;
    }
    .ant-pagination-item-active {
      font-weight: 500;
      background: #141414;
      border-color: unset;
      border-radius: 50%;
      width: 26px;
      height: 26px;

      a {
        color: #fff;
      }
    }
  }