.show-more-container {
  text-align: center;
  align-items: center;
  align-content: center;
  align-self: center;
  background-image: linear-gradient(to bottom, #eeeeee, #ffffffa9);
  height: 80px;
  width: inherit;
  position: absolute;
  max-width: 98.2%;
  display: grid;
  place-items: center;
  border-radius: 10px;
  margin-top: -2%;
  z-index: 100;
  span {
    color: #929292;
  }
}
.show-more-container:hover {
  cursor: pointer;
}

.conversation-container {
  max-height: 400px;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  width: 100%;
}

.email-message-box {
  background-color: #fff;
  border-radius: 25px;
  padding: 20px !important;
  max-height: 600px;
  max-width: inherit;
  margin-bottom: 3%;
  margin-right: 10px;
  // overflow-x: scroll;
  // overflow-y: scroll;

  .mail-subject {
    font-weight: 500;
    font-size: 17px;
    line-height: 34px;
    color: #141414;
    font-weight: bold;
  }

  .mail-content {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 26px;
    letter-spacing: 0.15px;
    color: #5c5c5c;
    margin-top: 20px;
  }
}

.recipient-stamp-box {
  display: flex;

  div {
    width: 100%;

    &.time-stamp {
      // text-align: right;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.15px;
      color: #5c5c5c;
    }
  }
}

.emoji-picker-react {
  position: absolute !important;
  right: 10%;
  top: -310px;
}
