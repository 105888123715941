.object-card {
  .card-image-container {
    height: 220px !important;
    // max-height: 190px !important;
    // min-height: 190px !important;
    width: 100% !important;
  }
  .card-image-placeholder {
    height: 220px !important;
    object-fit: cover !important;
    width: 100% !important;
  }
  .card-image {
    position: relative !important;
    object-fit: cover !important;
    width: 100% !important;
    height: 100% !important;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  .card-title {
    max-height: 30px !important;
    min-height: 30px !important;
    white-space: nowrap;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: capitalize;
    font-size: medium !important;
  }
  .card-image:hover {
    position: relative;
    // width: 100% !important;
    // height: 180px !important;
    // filter: blur(1px);
    // -webkit-filter: blur(1px);
  }
  .card-image-loading {
    border-radius: 5px;
    position: relative;
    width: 100% !important;
    height: 200px !important;
  }
  .card-container {
    // width: 102%;
    // height: fit-content;
    border-radius: 5px;
    transition: transform 0.2s;
    -webkit-box-shadow: 4px 5px 15px -4px rgba(0, 0, 0, 0.51);
    box-shadow: 4px 5px 15px -4px rgba(0, 0, 0, 0.51);
    // -webkit-box-shadow: 1px 1px 5px -1px rgba(0, 0, 0, 0.2);
    // box-shadow: 1px 1px 5px -1px rgba(0, 0, 0, 0.2);
  }
  .card-container:hover {
    cursor: pointer;
    transform: scale(1.02) !important;
    // -webkit-box-shadow: 4px 5px 15px -4px rgba(0, 0, 0, 0.51);
    // box-shadow: 4px 5px 15px -4px rgba(0, 0, 0, 0.51);
  }
  .card-title-container {
    height: 100% !important;
    border-radius: 5px;
    padding: 18px 8px 14px 8px !important;
    background-color: white;
    font-weight: bold;
  }
}
