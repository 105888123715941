.users-page-header {
  display: flex;
  flex-direction: column;
  .users-page-header-title {
    font-family: IBMPlex-Bold !important;
    font-style: normal;
    font-weight: bold !important;
    font-size: 34px !important;
    line-height: 32px !important;
    color: #171725 !important;
    margin-bottom: unset !important;
  }
}
.user-list-table-container {
  background: #fff;
  padding: 42px 30px;
  box-shadow: 0px 2px 23px rgba(233, 233, 233, 0.4);
  border-radius: 4px;
  .user-list-header {
    align-items: center;
    margin-bottom: 32px !important;
    .ant-typography {
      margin-bottom: unset !important;
    }
    .search-bar {
      border-radius: 4px !important;
    }
  }
  .user-list-table {
    .ant-table-content {
      .ant-table-thead {
        tr {
          th {
            padding-top: 6px !important;
            padding-bottom: 6px !important;
          }
        }
      }
    }
  }
}
