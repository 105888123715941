.slider-container {
  width: 96% !important;
}

.ant-slider {
  // margin-left: 5px !important;

  width: 98% !important;
  margin-bottom: 50px !important;
}
.ant-slider-rail {
  background-color: #ff9800 !important;
  color: #ff9800 !important;
  opacity: 0.1;
}
.ant-slider-track {
  background-color: #ff9800 !important;
  color: #ff9800 !important;
  //   opacity: 0.1;
}
.ant-slider-handle {
  // margin-left: 15px !important;
  background-color: #ff9800 !important;
  color: #ff9800 !important;
  border: none !important;
  // transform: scale(1.1) !important;

  //   width: 25px !important;
  //   height: 25px !important;
  //   opacity: 0.1;
}
// .ant-slider-mark-text,
.ant-slider-mark-text-active {
  // width: 250px !important;
  // margin-left: 137px !important;
  //   font-family: IBM Plex Sans;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 20px !important;
  /* identical to box height, or 143% */
  // display: flex !important;
  // align-items: center !important;
  letter-spacing: 0.15px !important;

  /* primary / light */

  color: #5c5c5c !important;
}

.ant-slider-dot,
.ant-slider-dot-active {
  display: none !important;
}
